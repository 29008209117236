import React from "react";
import { withStyles, createStyles, WithStyles, Theme } from "@material-ui/core/styles";

import Button from "@material-ui/core/Button";
import Check from "@material-ui/icons/Check";
import Close from "@material-ui/icons/Close";
import Input from "@material-ui/core/Input";

import { temperaturecodes } from "../../lib/api/temperaturecodes";
import { Field, Form } from "react-final-form";
import { Checkbox, TextField } from "final-form-material-ui";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(1),
      width: "100%",
    },
    tableRowFieldTitle: {
      paddingTop: theme.spacing(1),
      paddingRight: theme.spacing(1) * 2,
      marginTop: theme.spacing(1),
      textAlign: "right",
      minWidth: "145px",
    },
    tableRowFieldData: {
      paddingTop: theme.spacing(1),
      paddingRight: theme.spacing(1) * 2,
      marginTop: theme.spacing(1),
      textAlign: "left",
    },
    tableRowFieldInput: {
      width: "100%",
    },
  });

const validate = (value) => (value && (value || "").toString().trim() !== "" ? "" : "Required");

type TemperatureCodesFormProps = {
  onSubmit: any;
  onClose: any;
  id: any;
} & WithStyles<typeof styles>;

class TemperatureCodesFormUnstyled extends React.Component<TemperatureCodesFormProps, any> {
  state = {
    classes: undefined,
    item: { active: 1, id: "0", code: "", description: "", temperature: 0 },
    onSubmit: undefined,
    onClose: undefined,
    id: 0,
  };

  constructor(props) {
    super(props);
    this.state.classes = props.classes;
    this.state.onSubmit = props.onSubmit;
    this.state.onClose = props.onClose;
    this.state.id = props.id;
  }

  componentDidMount() {
    if (this.state.id != 0) {
      this.getItem(this.state.id);
    }
  }

  getItem = (id) => {
    temperaturecodes.single(id).then((data) => {
      this.setState({ item: data });
    });
  };

  render() {
    const { classes } = this.state;

    return (
      <div className={classes.root}>
        <Form
          initialValues={{ ...this.state.item }}
          onSubmit={this.state.onSubmit}
          render={({ handleSubmit, form }) => (
            <form onSubmit={handleSubmit}>
              <table>
                <tbody>
                  <tr>
                    <td className={classes.tableRowFieldTitle}>
                      <span>Active:</span>
                    </td>
                    <td className={classes.tableRowFieldData}>
                      <Field
                        fullWidth
                        name="active"
                        type="checkbox"
                        validate={validate}
                        component={Checkbox}
                        style={{ width: "min-content" }}
                        className={classes.tableRowFieldInput}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td className={classes.tableRowFieldTitle}>
                      <span>Code:</span>
                    </td>
                    <td className={classes.tableRowFieldData}>
                      <Field fullWidth name="code" validate={validate} component={TextField} type="text" className={classes.tableRowFieldInput} />
                    </td>
                  </tr>
                  <tr>
                    <td className={classes.tableRowFieldTitle}>
                      <span>Description:</span>
                    </td>
                    <td className={classes.tableRowFieldData}>
                      <Field fullWidth type="text" name="description" component={TextField} className={classes.tableRowFieldInput} validate={validate} />
                    </td>
                  </tr>
                  <tr>
                    <td className={classes.tableRowFieldTitle}>
                      <span>Temperature:</span>
                    </td>
                    <td className={classes.tableRowFieldData}>
                      <Field fullWidth type="text" name="temperature" component={TextField} className={classes.tableRowFieldInput} validate={validate} />
                    </td>
                  </tr>
                </tbody>
              </table>
              <div style={{ display: "flex", justifyContent: "flex-end", margin: "10px 0 -5px 0", paddingTop: "5px", paddingBottom: "5px" }}>
                <Button style={{ marginRight: "10px" }} color="secondary" variant="outlined" onClick={this.state.onClose}>
                  <Close />
                </Button>
                <Button disabled={!form.getState().dirty} style={{ marginRight: "16px" }} type="submit" color="primary" variant="outlined">
                  <Check />
                </Button>
              </div>
            </form>
          )}
        />
      </div>
    );
  }
}

export default withStyles(styles)(TemperatureCodesFormUnstyled);
