import { config } from "../../config";
import { client, apiHeaders, request } from "./_base";

interface ICount {
  id?: number;
  code: string;
  commodity_id: number;
  pack_id: number;
  active: number;
}

interface CountFull extends ICount {
  pack_code: string;
  pack_carton: string;
  pack_full: string;
  comm_code?: string;
  count: string;
}

const endpoint = "count";

export const counts = client<ICount>(endpoint, (item) => ({
  ...item,
  // id: item.count_id
}));

export const countFull = async (): Promise<{ data: CountFull[] }> => {
  const result = await request(`${endpoint}/extend/all`);
  if (result.status == 500) {
    const resp = await result.json();
    throw new Error(resp);
  }
  return result.json();
};

export const countCodeDistinct = async () => {
  const result = await request(`${endpoint}/extend/codedistinct`);
  if (result.status == 500) {
    const resp = await result.json();
    throw new Error(resp);
  }
  return result.json();
};

export const distinctCodeByCommodity = async (commodityId: string) => {
  const result = await request(`${endpoint}/extend/distinctCodesByCommodity/${commodityId}`);
  if (result.status == 500) {
    const resp = await result.json();
    throw new Error(resp);
  }
  return result.json();
};

export const countFullById = async (id: number): Promise<CountFull[]> => {
  const result = await request(`${endpoint}/ext/countFullById/${id}`);
  const resp = await result.json();

  if (result.status == 500) {
    throw new Error(resp);
  }

  return resp.data;
};
