import { client, request, apiHeaders } from "./_base";

const endpoint = "clients";

export type ClientsType = {
  id: number;
  active: 0 | 1;
  code: string;
  name: string;
  physical_address: string;
  postal_address: string;
  client_telephone: string;
  client_mobile: string;
  sales_person: string;
  sales_phone: string;
  sales_email: string;
  logistics_person: string;
  logistics_phone: string;
  logistics_email: string;
  quality_person: string;
  quality_phone: string;
  quality_email: string;
  finance_person: string;
  finance_phone: string;
  finance_email: string;
  created_at: Date;
  updated_at: Date;
  statement_count: number;
};

type UpsertClient = { clients?: { id: string; data: any }; clientsfinance?: { id: string; data: any }; clientsshipping?: { id: string; data: any } };

export const clients = client("clients", (item) => ({
  ...item,
}));

// only returns active clients i.e (active = 1)
export const getAllClients = async () => {
  const result = await request(`${endpoint}/ext/all`, {
    headers: apiHeaders(),
  });
  const resp = await result.json();
  if (result.status == 500) {
    throw resp;
  }
  return resp.data;
};

export const getClientsAllSortedMappedforComboID = async () => {
  const data: ClientsType[] = await getAllClients();
  return data.sort((a, b) => (a.code > b.code ? 1 : a.code < b.code ? -1 : 1)).map((item) => ({ value: item.id, display: `${item.code} - ${item.name}` }));
};

export const getClientsAllSortedMappedforComboCode = async () => {
  const data: ClientsType[] = await getAllClients();
  return data.sort((a, b) => (a.code > b.code ? 1 : a.code < b.code ? -1 : 1)).map((item) => ({ value: item.code, display: item.name }));
};

export const getClientsAllSortedMappedforComboWithSearch = async () => {
  const data: ClientsType[] = await getAllClients();
  return data.sort((a, b) => a.name.localeCompare(b.name)).map((item) => ({ value: item.code, display: `${item.name} (${item.code})`, search: item.name }));
};

export const withConcatenatedConsignees = async () => {
  const result = await request(`${endpoint}/ext/withConcatenatedConsignees`, {
    headers: apiHeaders(),
  });
  if (result.status == 500) {
    const resp = await result.json();
    throw resp;
  }
  return result.json();
};

export const clientsByCode = async (code: string) => {
  const result = await request(`${endpoint}/ext/clientsByCode/${code}`, {
    headers: apiHeaders(),
  });
  if (result.status == 500) {
    const resp = await result.json();
    throw resp;
  }
  return result.json();
};

export const getClientNameByCode = async (code: string) => {
  const result = await request(`${endpoint}/ex/debtorFundClientName/${code}`, {
    headers: apiHeaders(),
  });
  const resp = await result.json();
  if (result.status == 500) {
    throw resp;
  }
  return resp.data;
};

export const upsertClient = async (data: UpsertClient): Promise<void> => {
  const result = await request(`${endpoint}/ext/upsertClient`, {
    method: "POST",
    body: JSON.stringify(data),
  });
  if (result.status == 500) {
    const resp = await result.json();
    throw resp;
  }
};
