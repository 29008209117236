import React, { FC, useEffect, useState } from "react";
import { withStyles, createStyles, WithStyles, Theme } from "@material-ui/core/styles";

import { Field, Form } from "react-final-form";
import { Checkbox, TextField } from "final-form-material-ui";

import { varieties, VarietyType } from "../../lib/api/variety";
import { MaterialSelectComponent } from "../../lib/helpers/materialcomponents";
import { commoditiesAllSortedMappedforCombo } from "../../lib/api/commodity";

import Button from "@material-ui/core/Button";

import Close from "@material-ui/icons/Close";
import Check from "@material-ui/icons/Check";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(1),
      width: "100%",
    },
    formField: {
      display: "grid",
      gridTemplateColumns: "120px 250px",
      gap: `${theme.spacing(2)}px`,
      "& label": {
        textAlign: "right",
        alignSelf: "center",
      },
    },
    formBody: {
      display: "flex",
      flexDirection: "column",
      gap: `${theme.spacing(1)}px`,
    },
    formSelect: {
      textAlign: "left",
      width: "300px",
      height: "50px",
      padding: 0,
      marginTop: "-14px",
    },
    actionButtons: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "flex-end",
      gap: `${theme.spacing(1)}px`,
      marginTop: `${theme.spacing(2)}px`,
    },
  });

type VarietiesEditFormProps = {
  onSubmit: any;
  onClose: any;
  id: any;
} & WithStyles<typeof styles>;

const VarietiesEditFormUnstyled: FC<VarietiesEditFormProps> = ({ classes, id, onSubmit, onClose }) => {
  const [item, setItem] = useState<VarietyType>({ id: 0, code: "", name: "", commodity_id: "", active: 1 });
  const [commodities, setCommodities] = useState([]);

  const loadData = async () => {
    if (id != "0") {
      const data = await varieties.single(id);
      setItem(data);
    }

    const commodities = await commoditiesAllSortedMappedforCombo();
    setCommodities(commodities);
  };

  useEffect(() => {
    loadData();
  }, []);

  return (
    <div className={classes.root}>
      <Form
        initialValues={{ ...item }}
        onSubmit={(values) => onSubmit(values)}
        render={({ handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <div className={classes.formBody}>
              <TableFieldCheckbox classes={classes} field="active" title="Active" disabled={false} />
              <TableFieldCombo classes={classes} field="commodity_id" title="Commodity" data={commodities} disabled={false} />
              <TableFieldText classes={classes} field="code" title="Variety Code" disabled={false} />
              <TableFieldText classes={classes} field="name" title="Variety Name" disabled={false} />
            </div>
            <div className={classes.actionButtons}>
              <Button color="secondary" variant="outlined" onClick={onClose}>
                <Close />
              </Button>
              <Button type="submit" color="primary" variant="outlined">
                <Check />
              </Button>
            </div>
          </form>
        )}
      />
    </div>
  );
};

export default withStyles(styles)(VarietiesEditFormUnstyled);

const TableFieldCheckbox: FC<{ field: string; title: string; disabled: boolean } & WithStyles<typeof styles>> = (props) => {
  const { classes, field, title, disabled } = props;
  return (
    <div className={classes.formField}>
      <label>{title}:</label>
      <div>
        <Field fullWidth name={field} component={Checkbox} type="checkbox" disabled={disabled} />
      </div>
    </div>
  );
};

const TableFieldText: FC<{ field: string; title: string; disabled: boolean } & WithStyles<typeof styles>> = (props) => {
  const { classes, field, title, disabled } = props;
  return (
    <div className={classes.formField}>
      <label>{title}:</label>
      <div>
        <Field fullWidth name={field} component={TextField} type="text" disabled={disabled} />
      </div>
    </div>
  );
};

const TableFieldCombo: FC<{ classes: any; title: string; field: string; data: any; disabled?: boolean } & WithStyles<typeof styles>> = (props) => {
  const { classes, title, field, data, disabled = false } = props;

  return (
    <div className={classes.formField}>
      <label>{title}:</label>
      <div>
        <Field fullWidth name={field} disabled={disabled} childdata={data} component={MaterialSelectComponent} formControlProps={{ className: classes.formSelect }} />
      </div>
    </div>
  );
};
