import React, { FC } from "react";
import { withStyles, createStyles, WithStyles, Theme } from "@material-ui/core/styles";

import Check from "@material-ui/icons/Check";
import Close from "@material-ui/icons/Close";

import Button from "@material-ui/core/Button";

import { pack } from "../../lib/api/pack";
import { Field, Form } from "react-final-form";
import { TextField, Checkbox } from "final-form-material-ui";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(1),
      width: "100%",
    },
    formContainer: {
      display: "grid",
      gridTemplateColumns: "1fr",
      gap: "0.5rem",
    },
    formField: {
      display: "grid",
      gridTemplateColumns: "120px 200px",
      gap: "1rem",
      alignItems: "center",

      "& label": {
        textAlign: "right",
      },
    },
  });

type PackEditFormProps = {
  onSubmit: any;
  onClose: any;
  id: any;
} & WithStyles<typeof styles>;

class PackEditFormUnstyled extends React.Component<PackEditFormProps, any> {
  state = {
    classes: undefined,
    item: {
      active: 1,
      id: "0",
      "Commodity ID": "",
      code: "",
      "No Cartons": 0,
      Description: "",
      "Size Table": "",
      packagingweight: 0,
      "Gross Weight": 0,
      "Nett Weight": 0,
      "Standard Carton": 0,
      Height: 0,
      Width: 0,
      Depth: 0,
    },
    onSubmit: undefined,
    onClose: undefined,
    id: 0,
  };

  constructor(props) {
    super(props);
    this.state.classes = props.classes;
    this.state.onClose = props.onClose;
    this.state.id = props.id;
  }

  componentDidMount() {
    if (this.state.id != 0) {
      this.getItem(this.state.id);
    }
  }

  getItem = async (id: number) => {
    const data = await pack.single(id);
    this.setState({ item: data });
  };

  render() {
    const { classes } = this.state;

    return (
      <div className={classes.root}>
        <Form
          initialValues={{ ...this.state.item }}
          onSubmit={this.props.onSubmit}
          render={({ handleSubmit }) => (
            <form onSubmit={handleSubmit} className={classes.formContainer}>
              <FormCheckbox classes={classes} field="active" title="Active" />
              <FormTextField classes={classes} field="Commodity ID" title="Commodity Code" />
              <FormTextField classes={classes} field="code" title="Pack Code" />
              <FormTextField classes={classes} field="Description" title="Pack Description" />
              <FormTextField classes={classes} field="No Cartons" title="No Cartons" />
              <FormTextField classes={classes} field="packagingweight" title="Packaging Weight" />
              <FormTextField classes={classes} field="Gross Weight" title="Gross Weight" />
              <FormTextField classes={classes} field="Nett Weight" title="Nett Weight" />
              <FormTextField classes={classes} field="Standard Carton" title="Standard Carton" />
              <FormTextField classes={classes} field="Height" title="Height" />
              <FormTextField classes={classes} field="Width" title="Width" />
              <FormTextField classes={classes} field="Depth" title="Depth" />
              <div style={{ display: "flex", justifyContent: "flex-end", marginTop: "22px", marginRight: "15px" }}>
                <Button style={{ marginRight: "10px" }} color="secondary" variant="outlined" type="button" onClick={this.props.onClose}>
                  <Close />
                </Button>
                <Button type="submit" color="primary" variant="outlined">
                  <Check />
                </Button>
              </div>
            </form>
          )}
        />
      </div>
    );
  }
}

export default withStyles(styles)(PackEditFormUnstyled);

type FormCheckboxProps = {
  field: string;
  title: string;
} & WithStyles<typeof styles>;

const FormCheckbox: FC<FormCheckboxProps> = ({ classes, field, title }) => {
  return (
    <div className={classes.formField}>
      <label>{title}:</label>
      <div>
        <Field fullWidth name={field} component={Checkbox} type="checkbox" />
      </div>
    </div>
  );
};

type FormTextFieldProps = {
  field: string;
  title: string;
} & WithStyles<typeof styles>;

const FormTextField: FC<FormTextFieldProps> = ({ classes, field, title }) => {
  return (
    <div className={classes.formField}>
      <label>{title}:</label>
      <Field fullWidth name={field} component={TextField} />
    </div>
  );
};
