import { client, request } from "./_base";

interface TemperatureCodes {
  active: number;
  code: string;
  description: string;
  temperatur: number;
}

const endpoint = "temperaturecodes";

export const temperaturecodes = client<TemperatureCodes>("temperaturecodes", (item) => ({
  ...item,
}));

export const getActiveTemperature = async (): Promise<TemperatureCodes[]> => {
  const result = await request(`${endpoint}/ext/fullActive`);
  const resp = await result.json();
  if (result.status == 500) {
    throw resp;
  }
  return resp.data;
};

export const getTemperatureReadyForCombo = async () => {
  const result = await getActiveTemperature();
  return result
    .sort((a, b) => a.description.localeCompare(b.description))
    .map((tempcode) => ({ id: tempcode.code, value: tempcode.code, display: `${tempcode.code} - ${tempcode.description}`, data: tempcode }));
};
