import React from "react";
import { withStyles, createStyles, WithStyles, Theme } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";

import { arrivaldataByContainerProducerSalesRef } from "../../lib/api/arrivaldata";
import Grid from "../../lib/components/grid";

import CellActions, { GridColumns } from "./arrivalsdetailgridsetup";

import { SnackContext } from "../../lib/context/SnackContext";
import { GenerateErrorMessage } from "../../lib/helpers/string_methods";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      // padding: theme.spacing(1),
      width: "1050px",
      height: "100%",
      marginTop: "-32px",
    },
  });

type ArrivalsDetailGridProps = {
  container: string;
  producerid: string;
  salesref: string;
  arrival_id: Number;
  handleChange: any;
} & WithStyles<typeof styles>;

class ArrivalsDetailGridUnstyled extends React.Component<ArrivalsDetailGridProps, {}> {
  state = {
    classes: undefined,
    data: [],
    producerid: undefined,
    container: undefined,
    salesref: undefined,
    handleChange: undefined,
    newData: [],
    loading: true,
    arrival_id: undefined,
    currentRow: undefined,
    currentCol: undefined,
  };

  constructor(props) {
    super(props);
    this.state.classes = props.classes;
    if (props.producerid) {
      this.state.producerid = props.producerid;
    }
    if (props.salesref) {
      this.state.salesref = props.salesref;
    }
    if (props.container) {
      this.state.container = props.container;
    }
    if (props.handleChange) {
      this.state.handleChange = props.handleChange;
    }
    if (props.arrival_id) {
      this.state.arrival_id = props.arrival_id;
    }
  }

  static contextType = SnackContext;
  context!: React.ContextType<typeof SnackContext>;

  componentDidMount() {
    this.loadData();
  }

  componentWillReceiveProps(props) {
    if (props.arrival_id != this.state.arrival_id) {
      this.setState({ arrival_id: props.arrival_id });
      this.loadData();
    }
  }

  loadData = () => {
    this.setState({ loading: false }, () => {
      arrivaldataByContainerProducerSalesRef(this.state.container ? this.state.container : "0", this.state.producerid, this.state.salesref)
        .then((result) => {
          this.setState({ loading: false });
          const dataSorted = result.sort((a, b) => {
            if (a.intakepalletid > b.intakepalletid) return 1;
            if (a.intakepalletid < b.intakepalletid) return -1;
            return 1;
          });
          this.setState({ data: dataSorted });
          this.state.handleChange("gridload", dataSorted);
        })
        .catch((error) => {
          const err = GenerateErrorMessage(error, "failed to retrieve data");
          this.context.updateSnack({ show: true, color: "red", message: err });
        });
    });
  };

  handleChange = async (property: string, value: any) => {
    this.setState({ data: value });
    this.state.handleChange(property, value);
  };

  handleKeyDown = (event, gridRef) => {
    const enterKey = 13;
    const tabKey = 9;

    if (event.which === enterKey || event.which === tabKey) {
      event.preventDefault();
      const tagName = event.target.tagName && event.target.tagName.toLowerCase();
      gridRef.selectCell({ idx: 10, rowIdx: this.state.currentRow + 1 }, true);
    }
  };

  handleSelectedRowChange = (selectedRow: any, colums: { selectedColumn: number; columnsLength: number }) => {
    this.setState({ currentRow: selectedRow.rowIdx, currentCol: colums.selectedColumn });
  };

  render() {
    const { classes } = this.state;

    return (
      <div className={classes.root}>
        {this.state.loading && <CircularProgress />}
        {!this.state.loading && (
          <Grid
            fill
            loading={this.state.loading}
            data={this.state.data}
            GridColumns={GridColumns}
            forceHeight={420}
            handleChange={this.handleChange}
            rowHeight={30}
            clearFilters={"arrivalsdetailgridsetup"}
            handleGridKeyDown={this.handleKeyDown}
            selectedRowChange={this.handleSelectedRowChange}
          />
        )}
        {<span style={{ paddingLeft: "20px" }}>Record Count - {this.state.data.length}</span>}
      </div>
    );
  }
}

export const ArrivalsDetailGrid = withStyles(styles, { withTheme: true })(ArrivalsDetailGridUnstyled);
