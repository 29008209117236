import React from "react";

import Grid from "../../../lib/components/grid";
import { GridColumns } from "./gridsetup";

type OutstandingSummaryGridProps = {
  data: any[];
  loading: boolean;
};

export class OutstandingSummaryGrid extends React.Component<OutstandingSummaryGridProps, {}> {
  constructor(props) {
    super(props);
  }

  state = {};

  render() {
    return (
      <Grid
        forceHeight={700}
        data={this.props.data}
        GridColumns={GridColumns}
        loading={this.props.loading}
        clearFilters="outstandingsummarygrid"
        totalRowColumns={["sale_total", "total_outstandingwithadjustments"]}
      />
    );
  }
}
