import React from "react";

import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import CheckIcon from "@material-ui/icons/Check";
import CloseIcon from "@material-ui/icons/Close";

import CustomAutoCompleteFilter from "../../lib/components/customdatagridfilter/CustomFilter";

import { DestinationType } from "../../lib/api/destinationtype";
import { CellActionsFormatter } from "../../lib/helpers/CellActionsFormatter";
import { handleColumnsArrangement, handleColumnsWidth } from "../../lib/helpers/grid";

const cellWidthTiny = 40;
const cellWidthXtraSmall = 70;
const cellWidthSmall = 80;
const cellWidthSmallBig = 140;
const cellWidthMedium = 200;
// const cellWidthLarge = 250;
const cellWidthXtrLarge = 350;

const defaultColumnProperties = {
  resizable: true,
  sortable: true,
};

let currentSpace = true;
export const GridColumns = (data, filters, arrangement = undefined, columnsWidth = undefined, handleRemove, handleEdit, noDelete) => {
  let postValue = "";

  if (data) {
    if (currentSpace) {
      postValue = " ";
    }
    currentSpace = !currentSpace;
  }

  const columns = [
    {
      key: "actions",
      name: "Actions",
      width: cellWidthSmall,
      formatter: ({ row }) => {
        const actions = cellActions(row, handleRemove, handleEdit, noDelete);
        return (
          <div style={{ display: "flex", justifyContent: "center", gap: "5px" }}>
            <CellActionsFormatter actions={actions} />
          </div>
        );
      },
    },
    {
      key: "active",
      name: "Active" + postValue,
      width: cellWidthTiny,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"active"} data={data} section={"consignee"} />;
      },
      formatter: ({ row }) => (
        <div style={{ display: "flex", justifyContent: "center", width: "100%" }}>{row.active ? <CheckIcon style={{ color: "green" }} /> : <CloseIcon color="error" />}</div>
      ),
    },
    {
      key: "code",
      name: "Consignee Code" + postValue,
      width: cellWidthMedium,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"code"} data={data} section="consignee" />;
      },
    },
    {
      key: "name",
      name: "Consignee Name" + postValue,
      width: cellWidthMedium,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"name"} data={data} section="consignee" />;
      },
    },
    {
      key: "physical_address",
      name: "Physical Address" + postValue,
      width: cellWidthMedium,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"physical_address"} data={data} section="consignee" />;
      },
    },
    {
      key: "postal_address",
      name: "Postal Address" + postValue,
      width: cellWidthMedium,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"postal_address"} data={data} section="consignee" />;
      },
    },
    {
      key: "telephone",
      name: "Telephone" + postValue,
      width: cellWidthMedium,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"telephone"} data={data} section="consignee" />;
      },
    },
    {
      key: "mobile",
      name: "Mobile" + postValue,
      width: cellWidthMedium,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"mobile"} data={data} section="consignee" />;
      },
    },
    {
      key: "email",
      name: "Email" + postValue,
      width: cellWidthMedium,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"email"} data={data} section="consignee" />;
      },
    },
    {
      key: "region",
      name: "Region" + postValue,
      width: cellWidthMedium,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"region"} data={data} section="consignee" />;
      },
    },
    {
      key: "market",
      name: "Market" + postValue,
      width: cellWidthMedium,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"market"} data={data} section="consignee" />;
      },
    },
    {
      key: "country",
      name: "Country" + postValue,
      width: cellWidthMedium,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"country"} data={data} section="consignee" />;
      },
    },
    {
      key: "destinationtype",
      name: "Destination Type" + postValue,
      width: cellWidthMedium,
      formatter: destinationTypeFormatter,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"destinationtype"} data={data} section="consignee" />;
      },
    },
    {
      key: "clearingAgents",
      name: "Clearing Agents" + postValue,
      width: cellWidthXtrLarge,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"clearingAgents"} data={data} section="consignee" />;
      },
    },
    {
      key: "client_codes",
      name: "Client Codes" + postValue,
      width: cellWidthXtrLarge,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"client_codes"} data={data} section="consignee" />;
      },
    },
    {
      key: "client_names",
      name: "Client Names" + postValue,
      width: cellWidthXtrLarge,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"client_names"} data={data} section="consignee" />;
      },
    },
  ].map((c) => ({ ...c, ...defaultColumnProperties }));

  let columnsArranged = [];
  columnsArranged = handleColumnsArrangement(columnsArranged, arrangement, columns);
  columnsArranged = handleColumnsWidth(columnsArranged, columnsWidth, columns);

  return columnsArranged;
};

const destinationTypeFormatter = ({ row, column }) => {
  const data = row[column.key];

  if (data) {
    return <div>{DestinationType[data]}</div>;
  }
  return <div></div>;
};

const cellActions = (order, handleRemove, handleEdit, noDelete) => {
  return [
    {
      icon: (
        <span title="Edit">
          <EditIcon style={{ cursor: "pointer", color: "orange" }} />
        </span>
      ),
      callback: (e) => {
        handleEdit(order.id);
      },
    },
    !noDelete && {
      icon: (
        <span title="Remove">
          <DeleteIcon style={{ cursor: "pointer", color: "red" }} />
        </span>
      ),
      callback: (e) => {
        handleRemove(order.id);
      },
    },
  ];
};
