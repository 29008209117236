import React from "react";

import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import IconClear from "@material-ui/icons/Clear";
import IconDone from "@material-ui/icons/Done";

import CustomAutoCompleteFilter from "../../lib/components/customdatagridfilter/CustomFilter";
import { CellActionsFormatter } from "../../lib/helpers/CellActionsFormatter";
import { handleColumnsArrangement, handleColumnsWidth } from "../../lib/helpers/grid";

const cellWidthXtraSmall = 70;
const cellWidthSmall = 80;
const cellWidthSmallBig = 100;
const cellWidthMedium = 140;

const defaultColumnProperties = {
  resizable: true,
  sortable: true,
};

const ActiveCheck = ({ row, column }) => {
  const value = row[column.key];
  if (value) {
    return (
      <div style={{ width: "100%", display: "flex", justifyContent: "center" }}>
        <IconDone style={{ color: "green" }} />
      </div>
    );
  }
  return (
    <div style={{ width: "100%", display: "flex", justifyContent: "center" }}>
      <IconClear style={{ color: "red" }} />
    </div>
  );
};

let currentSpace = true;

export const GridColumns = (data, filters, arrangement, columnsWidth, handleRemove, handleEdit) => {
  let postValue = "";

  if (data) {
    if (currentSpace) {
      postValue = " ";
    }
    currentSpace = !currentSpace;
  }

  const columns = [
    {
      key: "actions",
      name: "Actions",
      width: cellWidthSmall,
      formatter: ({ row }) => {
        const actions = cellActions(row, handleRemove, handleEdit);
        return (
          <div style={{ display: "flex", justifyContent: "center", gap: "5px" }}>
            <CellActionsFormatter actions={actions} />
          </div>
        );
      },
    },
    {
      key: "active",
      name: "Active",
      width: cellWidthXtraSmall,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"active"} data={data} section={"countsgridsetup"} />;
      },
      formatter: ActiveCheck,
    },
    {
      key: "count",
      name: "Count" + postValue,
      width: cellWidthSmallBig,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"count"} data={data} section={"countsgridsetup"} />;
      },
    },
    {
      key: "comm_code",
      name: "Commodity" + postValue,
      width: cellWidthSmallBig,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"comm_code"} data={data} section={"countsgridsetup"} />;
      },
    },
    {
      key: "pack_code",
      name: "Pack Code" + postValue,
      width: cellWidthSmallBig,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"pack_code"} data={data} section={"countsgridsetup"} />;
      },
    },
    {
      key: "pack_carton",
      name: "Pack Carton" + postValue,
      width: cellWidthSmallBig,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"pack_carton"} data={data} section={"countsgridsetup"} />;
      },
    },
    {
      key: "pack_full",
      name: "Pallet Spec" + postValue,
      width: cellWidthMedium,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"pack_full"} data={data} section={"countsgridsetup"} />;
      },
    },
  ].map((c) => ({ ...c, ...defaultColumnProperties }));

  let columnsArranged = [];
  columnsArranged = handleColumnsArrangement(columnsArranged, arrangement, columns);
  columnsArranged = handleColumnsWidth(columnsArranged, columnsWidth, columns);

  return columnsArranged;
};

const cellActions = (order, handleRemove, handleEdit) => {
  return [
    {
      icon: (
        <span title="Edit">
          <EditIcon style={{ cursor: "pointer", color: "orange" }} />
        </span>
      ),
      callback: (e) => {
        handleEdit(order.id);
      },
    },
    {
      icon: (
        <span title="Remove">
          <DeleteIcon style={{ cursor: "pointer", color: "red" }} />
        </span>
      ),
      callback: (e) => {
        handleRemove(order.id);
      },
    },
  ];
};
