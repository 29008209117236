import { client, request, useApiGet } from "./_base";
import { getFinancialYearSelected } from "./week";

const endpoint = "salefundsoverpaid";

export type SaleFundsOverpaidType = {
  id?: number;
  salefunds_id: number;
  sale_id: number;
  amount: number;
  created_at?: Date;
};

export const SaleFundsOverpaid = client(endpoint, (item) => ({
  ...item,
}));

export type SaleFundsDetailType = {
  salefundsoverpaid_id: number;
  salefundsoverpaid_amount: number;
  salefundsoverpaid_created_at: Date;
  sale_invoicenumber: string;
  salefunds_amount: number;
  salefunds_makeanote: string;
  salefunds_posteddate: Date;
};

export type SaleFundsOverpaidMappingType = {
  id: number; // sale id
  invoicenumber: string;
  available_to_allocat: number;
  overpaid_amount: number;
  date_allocated: Date;
  currency_code: string;
  salefunds_id: number;
  allocated_shipment: 0 | 1;
  salefundsallocation_id: number;
};

export type SaleFundsOverpaidMappingGroupedType = {
  id: number; // sale id
  invoicenumber: string;
  overpaid_amount: number;
  salefunds_id: number;
  overpaid_id: number;
};

export const getSaleFundsOverpaidById = async (salefundsoverpaid_id: number): Promise<SaleFundsDetailType[]> =>
  useApiGet(`${endpoint}/ex/salefundsoverpaidById/${salefundsoverpaid_id}`);

export const getOverpaidSalesMapping = async (clients_code: string): Promise<SaleFundsOverpaidMappingType[]> =>
  useApiGet(`${endpoint}/ex/overpaidSalesMapping?clients_code=${clients_code}&financial_year=${getFinancialYearSelected()}`);

export const getOverpaidSalesMappingBySaleIds = async (sale_ids: number[]): Promise<SaleFundsOverpaidMappingGroupedType[]> =>
  useApiGet(`${endpoint}/ex/overpaidSalesMappingBySaleIds?sale_ids=${sale_ids.join(",")}`);
