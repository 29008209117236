//endpoints api
import { piorder } from "../lib/api/piorder";
import { getOrdersByGroupNumber } from "../lib/api/piorder";
import { piinstructions } from "../lib/api/piinstruction";
import { getProducerByProducerCode, getProducerByProducerId, producers } from "../lib/api/producer";
import { varieties } from "../lib/api/variety";
import { getPeriodByDate, weeks } from "../lib/api/week";
import { termsBySaleId } from "../lib/api/terms";
import { TermsValueType } from "../lib/api/termsvaluetype";
import { TermsDateType } from "../lib/api/termsdatetype";

import { companyDetails } from "../lib/api/companydetails";
import { Reports } from "../lib/types";
import { CompanyDetailsBankingType, getCompanyBankingDetailById } from "../lib/api/companydetailsbanking";

import { handlePrintPDF } from "../reports/instructionPDF";

import { PrintInvoice } from "./invoicePDF";
import { PrintStatement } from "./statementPDF";

import { saleBarcodesByDispatchID, salePackingListSummaryByDispatchID, saleHeader, saleByDispatchId } from "../lib/api/sale";
import { DebtorsFullType, getUnallocatedFunds } from "../lib/api/debtorsfull";

import format from "date-fns/format";
import parseISO from "date-fns/parseISO";
import subDays from "date-fns/subDays";
import isValidDate from "date-fns/isValid";

import BigNumber from "bignumber.js";
import { getProducersFullByUniqueIdent, ProducersFullGroupedType } from "../lib/api/producersfull";
import { producerAdvanceRemittance } from "./producerAdvanced";
import { ConsolidationFinalDetailType, ConsolidationGroupedProducerType } from "../lib/api/consolidation";
import { producerFinalRemittance } from "./producerFinal";
import { clients, clientsByCode } from "../lib/api/clients";
import { producerAdvancedShipment } from "./producerAdvancedShipment";
import { getSaleAdjustmentBySaleId, getSaleAdjustmentBySaleIdType } from "../lib/api/saleadjustmentdetail";
import { clientsfinanceByClientID } from "../lib/api/clientsfinance";
import { adhocdetailByPaidHeadId } from "../lib/api/producerfinalpaidadhocdetail";
import { adhocdetailByPaidHeadId as advanceAdhocdetailByPaidHeadId } from "../lib/api/produceradvancepaidadhocdetail";
import { getAdhocTransactionsByClient, SaleAdjustmentAdhocFullType } from "../lib/api/saleadjustmentadhoc";
import { printAdhocInvoice } from "./debtorsadjustmentadhoc";
import { printPackingList } from "./packingList";
import { getPalletSpecPackByVariety } from "../lib/api/pack";

type SaleData = {
  barcode_commoditycode: string;
  barcode_commodityname: string;
  barcode_countcode: string;
  barcode_gradecode: string;
  barcode_id: number;
  barcode_inventorycode: string;
  barcode_markcode: string;
  barcode_nocartons: number;
  barcode_packcode: string;
  barcode_palletsize: number;
  barcode_varietycode: string;
  barcode_varietyname: string;
  currency: string;
  sale_type: any;
  stockdetail_sellingprice: number;
  total: number;
};

export type AgeAnalysisType = {
  "30days": number;
  "60days": number;
  "90days": number;
  "120days": number;
  currency: string;
  current: number;
  total_due: number;
};

const dateType = {
  1: "1st Payment",
  2: "2nd Payment",
  3: "3rd Payment",
};

const formatSaleData = (data: getSaleAdjustmentBySaleIdType[]) => {
  return data.map((row) => ({
    barcode_commoditycode: row.commodityCode,
    barcode_commodityname: row.commodityName,
    barcode_countcode: row.countCode,
    barcode_gradecode: row.gradeCode,
    barcode_id: row.barcode_id,
    barcode_inventorycode: row.inventory_code,
    barcode_markcode: row.markCode,
    barcode_nocartons: row.barcode_cartons,
    barcode_packcode: row.packCode,
    barcode_palletsize: row.barcode_palletsize,
    barcode_varietycode: row.varietyCode,
    barcode_varietyname: row.variety_name,
    currency: row.currency_code,
    sale_type: row.sale_type,
    stockdetail_sellingprice: row.saleadjustmentdetail_amount,
    total: row.total,
  }));
};

const compareFruitSpec = (item, row) =>
  item.barcode_commoditycode === row.barcode_commoditycode &&
  item.barcode_countcode === row.barcode_countcode &&
  item.barcode_gradecode == row.barcode_gradecode &&
  item.barcode_inventorycode == row.barcode_inventorycode &&
  item.barcode_markcode === row.barcode_markcode &&
  item.barcode_packcode === row.barcode_packcode &&
  item.barcode_varietycode === row.barcode_varietycode &&
  item.stockdetail_sellingprice === row.stockdetail_sellingprice;

export const groupDataByFruitSpec = (data: SaleData[]) => {
  return data
    .map((item) => ({
      barcode_commoditycode: item.barcode_commoditycode.trim(),
      barcode_commodityname: item.barcode_commodityname.trim(),
      barcode_countcode: item.barcode_countcode.trim(),
      barcode_gradecode: item.barcode_gradecode.trim(),
      barcode_id: item.barcode_id,
      barcode_inventorycode: item.barcode_inventorycode.trim(),
      barcode_markcode: item.barcode_markcode.trim(),
      barcode_nocartons: item.barcode_nocartons,
      barcode_packcode: item.barcode_packcode.trim(),
      barcode_palletsize: item.barcode_palletsize,
      barcode_varietycode: item.barcode_varietycode.trim(),
      barcode_varietyname: item.barcode_varietyname.trim(),
      currency: item.currency.trim(),
      sale_type: item.sale_type,
      stockdetail_sellingprice: item.stockdetail_sellingprice,
      total: item.total,
    }))
    .reduce((arr, row: SaleData) => {
      const index = arr.findIndex((item) => compareFruitSpec(item, row));

      if (index < 0) {
        arr.push(row);
      } else {
        const summedNoCartons = new BigNumber(arr[index].barcode_nocartons).plus(new BigNumber(row.barcode_nocartons)).toNumber();
        const summedPalletSize = new BigNumber(arr[index].barcode_palletsize).plus(new BigNumber(row.barcode_palletsize)).toNumber();
        const summedTotal = new BigNumber(arr[index].total).plus(new BigNumber(row.total)).toNumber();

        arr[index] = { ...arr[index], barcode_nocartons: summedNoCartons, barcode_palletsize: summedPalletSize, total: summedTotal };
      }
      return arr;
    }, []);
};

export const printInstructionPDF = async (piorderid) => {
  const [resultVarieties, resultWeeks, resultPIOrder, resultProducers] = await Promise.all([varieties.all(), weeks.all(), await piorder.single(piorderid), await producers.all()]);

  const resultPacks = await getPalletSpecPackByVariety(resultPIOrder.variety);

  resultPIOrder.exchange = JSON.parse(resultPIOrder.exchange);
  let sequencedOrders;
  if (!resultPIOrder.groupnumber) {
    sequencedOrders = { data: [resultPIOrder] };
  } else {
    await getOrdersByGroupNumber(resultPIOrder.groupnumber).then((result) => {
      result.data.sort((a, b) => {
        if (a > b) return 1;
        if (a < b) return -1;
        return 1;
      });
      sequencedOrders = result;
    });
  }
  Promise.all(
    sequencedOrders.data.map(async (sequencedOrder) => {
      const resultInstructions = [];
      await piinstructions.FindByOrderId(sequencedOrder.id).then((data) => {
        data.map((item, idx) => {
          if (!item.rowKey) {
            item.rowKey = idx + 1;
          }
          if (item.count[0] == "/") {
            item.count = item.count.substring(1);
          }

          Object.keys(item).map((key) => {
            if (item[key] == null) {
              item[key] = "";
            }
          });

          resultInstructions.push(item);
        });
      });

      sequencedOrder.instructions = resultInstructions;
      return sequencedOrder;
    }),
  ).then(() => {
    if (!resultPIOrder.printdate) {
      const updateData = {
        data: { printdate: new Date() },
      };
      piorder.update(resultPIOrder.id, updateData).then(() => {
        return handlePrintPDF({ ...resultPIOrder, printdate: new Date() }, sequencedOrders.data, resultPacks, resultProducers, resultWeeks, resultVarieties);
      });
    } else {
      return handlePrintPDF(resultPIOrder, sequencedOrders, resultPacks, resultProducers, resultWeeks, resultVarieties);
    }
  });
};

export const handlePrintPackingListPDF = async (dispatch_id: string, reportType: Reports = Reports.PackingListPDF, is_dispatch: boolean = false, isProforma: boolean = false) => {
  const [headerFooter, companyDetailsResult, rows, summary] = await Promise.all([
    saleHeader(Number(dispatch_id.split(",")[0])),
    companyDetails.all(),
    saleBarcodesByDispatchID(dispatch_id),
    salePackingListSummaryByDispatchID(dispatch_id),
  ]);

  const company = !companyDetailsResult ? undefined : companyDetailsResult[0];

  return printPackingList(
    reportType || Reports.PackingListPDF,
    {
      details: rows,
      summary: summary,
      companyDetails: { company },
      headerFooter: headerFooter[0] || [],
      is_dispatch,
    },
    isProforma,
  );
};

export const handlePrintInvoice = async (dispatch_id: any, reportType: Reports = Reports.Invoice, isProforma: boolean = false) => {
  const [headerFooter, companyDetailsResult, saleRows] = await Promise.all([saleHeader(dispatch_id.split(",")[0]), companyDetails.all(), saleByDispatchId(dispatch_id)]);

  if (headerFooter.length === 0) {
    throw { data: "No header data attached" };
  }
  if (!headerFooter[0].statement_account_id) {
    throw { data: "No banking detail attached to client." };
  }

  const groupedRows = groupDataByFruitSpec(saleRows);
  const bankResult = await getCompanyBankingDetailById(headerFooter[0].statement_account_id);

  if ((bankResult.data || []).length === 0) {
    throw { data: "Banking detail not found" };
  }

  const bank = (bankResult.data || []).length > 0 ? bankResult.data[0] : {};
  const company = !companyDetailsResult ? undefined : companyDetailsResult[0];

  return handlePrintInvoicing(reportType, { details: groupedRows, companyDetails: { bank, company }, headerFooter: headerFooter[0] || [], is_dispatch: false }, isProforma);
};

export const handlePrintDebitCreditNote = async (selectedRecord: any, reportType: Reports = Reports.Invoice, isProforma: boolean = false) => {
  const headerFooter = await saleHeader(selectedRecord.saleadjustment_dispatch_id);

  if (headerFooter.length === 0) {
    throw { data: "No header data attached" };
  }
  if (!headerFooter[0].statement_account_id) {
    throw { data: "No banking detail attached to client." };
  }
  const [companyDetailsResult, result, bankResult] = await Promise.all([
    companyDetails.all(),
    await getSaleAdjustmentBySaleId(selectedRecord.dispatchdocs_id == -99 ? selectedRecord.sale_dispatch_id : -99),
    getCompanyBankingDetailById(headerFooter[0].statement_account_id),
  ]);

  if ((bankResult.data || []).length === 0) {
    throw { data: "Banking detail not found" };
  }

  const bank = (bankResult.data || []).length > 0 ? bankResult.data[0] : {};
  const rows = formatSaleData(result);
  const groupedRows = groupDataByFruitSpec(rows);
  const company = !companyDetailsResult ? undefined : companyDetailsResult[0];

  return handlePrintInvoicing(reportType, { details: groupedRows, companyDetails: { bank, company }, headerFooter: headerFooter[0] || [], is_dispatch: false }, isProforma);
};

export const handlePrintDebtorsAdhocAdjustment = async (selectedAdhoc: SaleAdjustmentAdhocFullType) => {
  const report = selectedAdhoc.type === "Debit" ? Reports.DebtorsAdjustmentDebitAdhoc : Reports.DebtorsAdjustmentCreditAdhoc;
  const [companyDetailsResult, bankResult] = await Promise.all([companyDetails.all(), getCompanyBankingDetailById(selectedAdhoc.statement_account_id)]);

  if ((bankResult.data || []).length === 0) {
    throw { data: "Banking detail not found" };
  }

  const bank = (bankResult.data || []).length > 0 ? bankResult.data[0] : ({} as CompanyDetailsBankingType);
  const company = !companyDetailsResult ? undefined : companyDetailsResult[0];
  return printAdhocInvoice(company, report, selectedAdhoc, bank);
};

export const handlePrintInvoicing = async (reportType: Reports, data, isProforma: boolean) => {
  const currency = data.details.length > 0 ? data.details[0].currency : "";
  const payments = await getPayments(reportType, currency, data.headerFooter.sale_id, isProforma);
  return PrintInvoice(reportType, data, payments, isProforma);
};

export type SalePaymentsType = {
  amount: number;
  currency: string;
  duedate: string;
  paid: string;
  payment: string;
  paymentPeriod: string;
  detail: string;
};

export const getPayments = async (report: Reports, currency, saleid: number, isProforma: boolean): Promise<SalePaymentsType[]> => {
  let pay = [];
  if ([Reports.Invoice, Reports.ConsigneeInvoice, Reports.ClientAndConsigneeInvoice].includes(report)) {
    const resultAPI = await termsBySaleId(saleid);
    const termData = resultAPI[0];
    const termsDetails = termData.terms_details.split("\n");

    if (isProforma && termData.terms_outstanding1 == 0 && termData.terms_outstanding2 == 0 && termData.terms_outstanding3 == 0) {
      return new Array(3).fill("").map((_, indx) => {
        const index = indx + 1;
        const value = `${termData[`terms_value${index}`]}${TermsValueType[`termData.terms_valuetype${index}`] == "PERCENTAGE" ? "%" : ""}`;
        const detail = termsDetails[0] ? termsDetails[0].toString() : "";
        const parsedDate = parseISO(termData[`due${index}`]);
        const dueDate = `${format(isValidDate(parsedDate) ? parsedDate : new Date(), "dd MMM yyyy")}`;

        const detailValue = value && value != "null" ? value : "";
        const termsDateValue = TermsDateType[`termData.terms_datetype${index}`] ? TermsDateType[`termData.terms_datetype${index}`] : "";
        const termsDaysValue = termData[`terms_days${index}`] ? termData[`terms_days${index}`] : "";

        return {
          amount: termData[`terms_outstanding1${index}`],
          currency: currency,
          duedate: dueDate,
          paid: "PAID",
          payment: detail,
          paymentPeriod: dateType[index],
          detail: `${detailValue} ${termsDateValue} ${termsDaysValue}`,
        };
      });
    }

    let detail;
    let value;
    let dueDate;
    if (termData.terms_outstanding1) {
      value = `${termData.terms_value1}${TermsValueType[termData.terms_valuetype1] == "PERCENTAGE" ? "%" : ""}`;
      detail = termsDetails[0] ? termsDetails[0].toString() : "";
      dueDate = `${format(parseISO(termData.due1), "dd MMM yyyy")}`;

      const detailValue = value && value != "null" ? value : "";
      const termsDateValue = TermsDateType[termData.terms_datetype1] ? TermsDateType[termData.terms_datetype1] : "";
      const termsDaysValue = termData.terms_days1 ? termData.terms_days1 : "";

      pay.push({
        amount: termData.terms_outstanding1,
        currency: currency,
        duedate: dueDate,
        paid: "PAID",
        payment: detail,
        paymentPeriod: "1st Payment",
        detail: `${detailValue} ${termsDateValue} ${termsDaysValue}`,
      });
    }
    if (termData.terms_outstanding2) {
      value = `${termData.terms_value2}${TermsValueType[termData.terms_valuetype2] == "PERCENTAGE" ? "%" : ""}`;
      detail = termsDetails[1] > 1 ? termsDetails[1].toString() : "";
      dueDate = `${format(parseISO(termData.due2), "dd MMM yyyy")}`;

      const detailValue = value && value != "null" ? value : "";
      const termsDateValue = TermsDateType[termData.terms_datetype2] ? TermsDateType[termData.terms_datetype2] : "";
      const termsDaysValue = termData.terms_days2 ? termData.terms_days2 : "";

      pay.push({
        amount: termData.terms_outstanding2,
        currency: currency,
        duedate: dueDate,
        paid: "PAID",
        payment: detail,
        paymentPeriod: "2nd Payment",
        detail: `${detailValue} ${termsDateValue} ${termsDaysValue}`,
      });
    }
    if (termData.terms_outstanding3) {
      value = termData.terms_valuetype3 ? `${termData.terms_value3}${TermsValueType[termData.terms_valuetype3] == "PERCENTAGE" ? "%" : ""}` : "";
      detail = termsDetails[2] ? termsDetails[2].toString() : "";
      dueDate = termData.terms_valuetype3 ? `${format(parseISO(termData.due3), "dd MMM yyyy")}` : "";

      const detailValue = value && value != "null" ? value : "";
      const termsDateValue = TermsDateType[termData.terms_datetype3] ? TermsDateType[termData.terms_datetype3] : "";
      const termsDaysValue = termData.terms_days3 ? termData.terms_days3 : "";

      pay.push({
        amount: termData.terms_outstanding3,
        currency: currency,
        duedate: dueDate,
        paid: "PAID",
        payment: detail,
        paymentPeriod: "3rd Payment",
        detail: `${detailValue} ${termsDateValue} ${termsDaysValue}`,
      });
    }
    return pay;
  }
  return undefined;
};

const getAgeAnalysisData = (currentPeriod: { period_start: string; period_end: string }, reportData: StatementData[], currency_code: string): AgeAnalysisType[] => {
  const thirtyDaysDateStart = subDays(parseISO(currentPeriod.period_start), 30),
    sixtyDaysDateStart = subDays(parseISO(currentPeriod.period_start), 60),
    ninetyDaysDateStart = subDays(parseISO(currentPeriod.period_start), 90);

  const analysis = reportData.reduce(
    (acc, curr) => {
      // skip if total's row
      if (curr["sale_invoicenumber"] == "TOTAL") {
        return acc;
      }

      // get Current Week values
      if (parseISO(curr.sale_paymentdate) >= parseISO(currentPeriod.period_start)) {
        acc["current"] = new BigNumber(acc["current"]).plus(new BigNumber(curr.sale_paymentamount_remain)).toNumber();
        acc["total_due"] = new BigNumber(acc["total_due"]).plus(new BigNumber(curr.sale_paymentamount_remain)).toNumber();
      }

      // get 30 days from current week values
      if (parseISO(curr.sale_paymentdate) >= thirtyDaysDateStart && parseISO(curr.sale_paymentdate) < parseISO(currentPeriod.period_start)) {
        acc["30days"] = new BigNumber(acc["30days"]).plus(new BigNumber(curr.sale_paymentamount_remain)).toNumber();
        acc["total_due"] = new BigNumber(acc["total_due"]).plus(new BigNumber(curr.sale_paymentamount_remain)).toNumber();
      }

      // get 60 days from current week values
      if (parseISO(curr.sale_paymentdate) >= sixtyDaysDateStart && parseISO(curr.sale_paymentdate) < thirtyDaysDateStart) {
        acc["60days"] = new BigNumber(acc["60days"]).plus(new BigNumber(curr.sale_paymentamount_remain)).toNumber();
        acc["total_due"] = new BigNumber(acc["total_due"]).plus(new BigNumber(curr.sale_paymentamount_remain)).toNumber();
      }

      // get 90 days from current week values
      if (parseISO(curr.sale_paymentdate) >= ninetyDaysDateStart && parseISO(curr.sale_paymentdate) < sixtyDaysDateStart) {
        acc["90days"] = new BigNumber(acc["90days"]).plus(new BigNumber(curr.sale_paymentamount_remain)).toNumber();
        acc["total_due"] = new BigNumber(acc["total_due"]).plus(new BigNumber(curr.sale_paymentamount_remain)).toNumber();
      }

      // get 120 days from current week values
      if (parseISO(curr.sale_paymentdate) < ninetyDaysDateStart) {
        acc["120days"] = new BigNumber(acc["120days"]).plus(new BigNumber(curr.sale_paymentamount_remain)).toNumber();
        acc["total_due"] = new BigNumber(acc["total_due"]).plus(new BigNumber(curr.sale_paymentamount_remain)).toNumber();
      }

      return acc;
    },
    {
      current: 0,
      "30days": 0,
      "60days": 0,
      "90days": 0,
      "120days": 0,
      total_due: 0,
    },
  );

  return [{ ...analysis, currency: currency_code }];
};

export const handlePrintStatement = async (data: DebtorsFullType[], dateTo: Date, dateFrom: Date, clientscode: string, printExcludeZero: boolean, returnRaw: boolean = false) => {
  const { reportData, value, outstanding, payments, currencyCode, creditAmount, debitAmount } = data.reduce(
    (acc, curr, currentIndex, array) => {
      const result1 = getStatementObjectFromDebtorsFullItem(curr, 1);
      const result2 = getStatementObjectFromDebtorsFullItem(curr, 2);
      const result3 = getStatementObjectFromDebtorsFullItem(curr, 3);

      if (curr.unallocated_payment || curr.adhoc_payment == 2) {
        acc.reportData.push(result1);
        return acc;
      }

      if (printExcludeZero && result1.sale_paymentamount_remain === 0 && result2.sale_paymentamount_remain === 0 && result3.sale_paymentamount_remain === 0) {
        return acc;
      }

      acc.outstanding = new BigNumber(acc.outstanding)
        .plus(new BigNumber(curr.sale_payment1amount_remain || 0))
        .plus(new BigNumber(curr.sale_payment2amount_remain || 0))
        .plus(new BigNumber(curr.sale_payment3amount_remain || 0))
        .toNumber();

      acc.value = new BigNumber(acc.value)
        .plus(new BigNumber(curr.sale_payment1amount || 0))
        .plus(new BigNumber(curr.sale_payment2amount || 0))
        .plus(new BigNumber(curr.sale_payment3amount || 0))
        .toNumber();

      // sale_paymentamount's will either be postive, negative or 0
      // if sale_paymentamount is 0, it's the same as there is no payment.
      if (result1.sale_paymentamount) {
        acc.payments = new BigNumber(acc.payments).plus(new BigNumber(result1.sale_paymentamountpaid)).toNumber();
        acc.debitAmount = new BigNumber(acc.debitAmount).plus(new BigNumber(result1.amountDebit || 0)).toNumber();
        acc.creditAmount = new BigNumber(acc.creditAmount).plus(new BigNumber(result1.amountCredit || 0)).toNumber();
        // adhoc_payment will only ever be on payment number "1", which is why we only check it here
        acc.reportData.push({ ...result1, appendLine: result1.adhoc_payment || (result3.sale_paymentamount == 0 && result2.sale_paymentamount == 0) ? true : undefined });
      }

      if (result2.sale_paymentamount) {
        acc.payments = new BigNumber(acc.payments).plus(new BigNumber(result2.sale_paymentamountpaid)).toNumber();
        acc.debitAmount = new BigNumber(acc.debitAmount).plus(new BigNumber(result2.amountDebit || 0)).toNumber();
        acc.creditAmount = new BigNumber(acc.creditAmount).plus(new BigNumber(result2.amountCredit || 0)).toNumber();
        acc.reportData.push({ ...result2, appendLine: result3.sale_paymentamount == 0 ? true : undefined });
      }

      if (result3.sale_paymentamount) {
        acc.payments = new BigNumber(acc.payments).plus(new BigNumber(result3.sale_paymentamountpaid)).toNumber();
        acc.debitAmount = new BigNumber(acc.debitAmount).plus(new BigNumber(result3.amountDebit || 0)).toNumber();
        acc.creditAmount = new BigNumber(acc.creditAmount).plus(new BigNumber(result3.amountCredit || 0)).toNumber();
        acc.reportData.push({ ...result3, appendLine: true });
      }

      acc.currencyCode = curr.currency_code;

      return acc;
    },
    {
      value: 0,
      outstanding: 0,
      payments: 0,
      currencyCode: "",
      reportData: [],
      debitAmount: 0,
      creditAmount: 0,
    },
  );

  reportData.push({
    sale_invoicenumber: "TOTAL",
    vessel_description: currencyCode,
    dispatch_containerno: "",
    loadout_etd: "",
    loadout_eta: "",
    sale_paymentdate: "",
    sale_paymentamount: value,
    sale_paymentamountpaid: payments,
    sale_paymentamount_remain: outstanding,
    amountCredit: creditAmount,
    amountDebit: debitAmount,
    appendLine: true,
  });

  const clientsData = await clientsByCode(clientscode);
  const clientData = clientsData.data.length > 0 ? clientsData.data[0] : {};

  const clientsUpdateData = { data: { statement_count: parseInt(clientData.statement_count) + 1 } };
  await clients.update(clientData.id, clientsUpdateData);

  const companyDetailsResult = await companyDetails.all();
  const currency = (data.find((item) => item.currency_code) || { currency_code: "ZAR" }).currency_code;

  const clientsFinance = await clientsfinanceByClientID(clientData.id);
  const finance = (clientsFinance.data || []).length > 0 ? clientsFinance.data[0] : undefined;

  if (!finance || !finance.statement_account_id) {
    throw { data: "No banking detail attached to client" };
  }

  const bankResult = await getCompanyBankingDetailById(finance.statement_account_id);

  if ((bankResult.data || []).length === 0) {
    throw { data: "Banking detail not found" };
  }

  const bank = (bankResult.data || []).length > 0 ? bankResult.data[0] : ({} as CompanyDetailsBankingType);

  const [currentPeriod, unallocatedFunds, adhocTransactions] = await Promise.all([
    getPeriodByDate(new Date().toISOString()),
    getUnallocatedFunds(clientData.id, format(dateFrom, "yyyy-MM-dd"), format(dateTo, "yyyy-MM-dd")),
    getAdhocTransactionsByClient(clientData.id),
  ]);
  const ageAnalysisData = getAgeAnalysisData(currentPeriod[0], reportData, currency);

  return PrintStatement(clientData, companyDetailsResult[0], reportData, dateTo, dateFrom, bank, ageAnalysisData, unallocatedFunds, returnRaw, adhocTransactions);
};

export type StatementData = {
  sale_invoicenumber: string;
  vessel_description: string;
  dispatch_containerno: string;
  loadout_etd: string;
  loadout_eta: string;
  amountCredit: number;
  amountDebit: number;
  sale_paymentdate: string;
  sale_paymentamount: number;
  sale_paymentamountpaid: number;
  sale_paymentamount_remain: number;
  adhoc_payment?: boolean;
  unallocated_payment: number;
  sale_paymentnote: string;
  overpaid_amount: number;
  appendLine: boolean;
  adhocType: number;
};

const getStatementObjectFromDebtorsFullItem = (item: DebtorsFullType, paymentNumber: number): StatementData => {
  if (item.adhoc_payment) {
    return {
      sale_invoicenumber: item.sale_invoicenumber,
      vessel_description: "",
      dispatch_containerno: "",
      loadout_etd: "",
      loadout_eta: "",
      amountCredit: 0,
      amountDebit: 0,
      sale_paymentdate: item[`sale_payment${paymentNumber}date`],
      sale_paymentamount: item[`sale_payment${paymentNumber}amount`],
      sale_paymentamountpaid: item[`sale_payment${paymentNumber}amount`],
      sale_paymentamount_remain: item[`sale_payment${paymentNumber}amount_remain`],
      adhoc_payment: true,
      unallocated_payment: 0,
      sale_paymentnote: "",
      overpaid_amount: item.overpaid_amount,
      appendLine: true,
      adhocType: item.adhoc_payment,
    };
  }
  return {
    sale_invoicenumber: paymentNumber != 1 ? "" : item.sale_invoicenumber,
    vessel_description: paymentNumber != 1 ? "" : item.vessel_description,
    dispatch_containerno: paymentNumber != 1 ? "" : item.dispatch_containerno,
    loadout_etd: paymentNumber != 1 ? "" : item.loadout_etd,
    loadout_eta: paymentNumber != 1 ? "" : item.loadout_updated_eta || item.loadout_eta,
    amountCredit: item[`amount${paymentNumber}Credit`],
    amountDebit: item[`amount${paymentNumber}Debit`],
    sale_paymentdate: item[`sale_payment${paymentNumber}date`],
    sale_paymentamount: item[`sale_payment${paymentNumber}amount`],
    sale_paymentamountpaid: item[`sale_payment_paid${paymentNumber}`],
    sale_paymentamount_remain: item[`sale_payment${paymentNumber}amount_remain`],
    unallocated_payment: item.unallocated_payment,
    sale_paymentnote: item.payment_description,
    overpaid_amount: item.overpaid_amount,
    appendLine: false,
    adhocType: item.adhoc_payment,
  };
};

export const printProducerAdvanceRemittance = async (selectedAdvance: ProducersFullGroupedType) => {
  const { produceradvancepaidhead_id } = selectedAdvance;

  const [companyDetailsResult, producerInfo, producerData, adhocPayments] = await Promise.all([
    await companyDetails.all(),
    await getProducerByProducerId(selectedAdvance.producerId),
    await getProducersFullByUniqueIdent(selectedAdvance.producer_id, selectedAdvance.uniqueIdent),
    await advanceAdhocdetailByPaidHeadId(produceradvancepaidhead_id),
  ]);

  producerAdvanceRemittance({ companyDetails: companyDetailsResult[0], header: producerInfo.data[0], detail: producerData, selectedAdvance, adhocPayments });
};

export const printProducerAdvanceShipmentRemittance = async (selectedAdvance: ProducersFullGroupedType) => {
  const { produceradvancepaidhead_id } = selectedAdvance;

  const [companyDetailsResult, producerInfo, producerData, adhocPayments] = await Promise.all([
    await companyDetails.all(),
    await getProducerByProducerId(selectedAdvance.producerId),
    await getProducersFullByUniqueIdent(selectedAdvance.producer_id, selectedAdvance.uniqueIdent),
    await advanceAdhocdetailByPaidHeadId(produceradvancepaidhead_id),
  ]);

  producerAdvancedShipment({ companyDetails: companyDetailsResult[0], header: producerInfo.data[0], detail: producerData, selectedAdvance, adhocPayments });
};

export const printProducerFinalRemittance = async (selectedFinals: any[], finalDetails: ConsolidationFinalDetailType[]) => {
  const { producerfinalpaidhead_id, producer_id } = finalDetails[0];
  let producerInfo: { data: any[] };

  if (typeof producer_id == "string" || typeof producer_id === typeof undefined) {
    producerInfo = await getProducerByProducerCode(finalDetails[0].producerid);
  } else if (typeof producer_id == "number") {
    producerInfo = await getProducerByProducerId(producer_id);
  }

  const companyDetailsResult = await companyDetails.all();
  const adhocPayments = await adhocdetailByPaidHeadId(producerfinalpaidhead_id);

  producerFinalRemittance({ header: producerInfo.data[0], detail: finalDetails, selected: selectedFinals, companyDetails: companyDetailsResult[0], adhocPayments });
};
