type DefaultFilter = { key: string; forceApply: boolean; filter: string };

const grids = [
  "brandsgridsetup",
  "classesgridsetup",
  "clientsgridsetup",
  "coldroomsgridsetup",
  "commoditiesgridsetup",
  "consignee",
  "maintenancecreditors",
  "inventorygridsetup",
  "portsgridsetup",
  "currencygridsetup",
  "clearingagent",
  "maintproducers",
  "shippinglinegridsetup",
  "temperaturecodesgridsetup",
  "varietiesgridsetup",
  "packgridsetup",
  "countsgridsetup",
];

const defaultFilters: DefaultFilter[] = grids.map((grid) => ({
  key: `stickyfilters_${grid}`,
  forceApply: false,
  filter: JSON.stringify([{ read: 1, field: "active", value: [{ label: "Active", value: 1 }] }]),
}));

export const applyDefaultFilters = () => {
  for (const { key, filter, forceApply } of defaultFilters) {
    // check if localStorage has not already been set for one of the filters
    // forceApply - Force the filters to be applied even if it's already set
    if (!window.localStorage.getItem(key) || forceApply) {
      window.localStorage.setItem(key, filter);
    }
  }
};
