import React, { useEffect, useState, FC, useMemo } from "react";
import { withStyles, createStyles, WithStyles, Theme } from "@material-ui/core/styles";

import { FormApi } from "final-form";
import { Field, Form } from "react-final-form";
import { Checkbox, TextField } from "final-form-material-ui";

import Check from "@material-ui/icons/Check";
import Close from "@material-ui/icons/Close";

import Button from "@material-ui/core/Button";
import LinearProgress from "@material-ui/core/LinearProgress";

import { PackCommodity, getPackWithCommodity } from "../../lib/api/pack";
import { countFull, countFullById } from "../../lib/api/counts";
import { MaterialSelectComponent } from "../../lib/helpers/materialcomponents";
import { commoditiesAllSortedMappedforComboById } from "../../lib/api/commodity";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(1),
    },
    formField: {
      display: "grid",
      gridTemplateColumns: "120px 250px",
      gap: `${theme.spacing(2)}px`,
      "& label": {
        textAlign: "right",
        alignSelf: "center",
      },
    },
    formBody: {
      display: "flex",
      flexDirection: "column",
      gap: `${theme.spacing(1)}px`,
    },
    formSelect: {
      textAlign: "left",
      width: "300px",
      height: "50px",
      padding: 0,
      marginTop: "-14px",
    },
    actionButtons: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "flex-end",
      gap: `${theme.spacing(1)}px`,
      marginTop: `${theme.spacing(2)}px`,
    },
    formError: {
      color: "red",
      margin: 0,
    },
  });

type CountsEditFormProps = {
  onSubmit: any;
  onClose: any;
  id: any;
} & WithStyles<typeof styles>;

const CountsEditFormUnstyled: FC<CountsEditFormProps> = ({ classes, id, onSubmit, onClose }) => {
  const [loading, setLoading] = useState(true);
  const [palletSpecs, setPalletSpecs] = useState<PackCommodity[]>([]);
  const [commodities, setCommodities] = useState<{ value: number; display: string }[]>([]);
  const [item, setItem] = useState<{ active: number; id?: number; count: string; pack_code: string; pack_id: number }>({
    active: 1,
    id: 0,
    count: "1",
    pack_code: "",
    pack_id: 0,
  });
  const [counts, setCounts] = useState([]);

  const loadData = async () => {
    setLoading(true);
    try {
      const [countData, packs, commoditiesResult, countsFullResult] = await Promise.all([
        countFullById(id),
        getPackWithCommodity(),
        commoditiesAllSortedMappedforComboById(),
        countFull(),
      ]);
      setItem(countData[0]);
      setPalletSpecs(packs);
      setCommodities(commoditiesResult);
      setCounts(countsFullResult.data);
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  useEffect(() => {
    loadData();
  }, [id]);

  if (loading) return <LinearProgress />;

  return (
    <div className={classes.root}>
      <Form
        initialValues={{ ...item }}
        validate={(values) => {
          let errors = {};

          if (
            counts.some((cnt) => cnt.count == values.count && cnt.commodity_id == values.commodity_id && cnt.pack_id == values.pack_id && (values.id == "0" || values.id != cnt.id))
          ) {
            errors["duplicate_check"] = true;
          }

          return errors;
        }}
        onSubmit={(values) => onSubmit(values)}
        render={({ handleSubmit, form }) => (
          <form onSubmit={handleSubmit}>
            <FormDetail classes={classes} form={form} palletSpecs={palletSpecs} commodities={commodities} />
            <div className={classes.actionButtons}>
              <Button color="secondary" variant="outlined" onClick={onClose}>
                <Close />
              </Button>
              <Button type="submit" color="primary" variant="outlined">
                <Check />
              </Button>
            </div>
          </form>
        )}
      />
    </div>
  );
};

const validate = (value: any) => (value && (value || "").toString().trim() != "" ? "" : "Required");

type FormDetail = {
  form: FormApi;
  palletSpecs: PackCommodity[];
  commodities: any[];
} & WithStyles<typeof styles>;

const FormDetail: FC<FormDetail> = ({ classes, form, palletSpecs, commodities }) => {
  const palletSpecCodes = useMemo(() => {
    const selectedCommodity = form.getState().values.commodity_id;
    return palletSpecs
      .reduce((arr, row) => {
        if (row.commodity_id == selectedCommodity && !arr.find((a) => a.value === row.code)) {
          arr.push({ value: row.code, display: row.code });
        }
        return arr;
      }, [])
      .sort((a, b) => a.display.localeCompare(b.display));
  }, [palletSpecs, form.getState().values.commodity_id]);

  const palletSpecCartons = useMemo(() => {
    const { pack_code, commodity_id } = form.getState().values;
    return palletSpecs
      .reduce((arr, row) => {
        if (row.code === pack_code && row.commodity_id == commodity_id && !arr.find((a) => a.display === row["No Cartons"])) {
          arr.push({ value: row.id, code: row.code, display: row["No Cartons"] });
        }
        return arr;
      }, [])
      .sort((a, b) => a.display - b.display);
  }, [palletSpecs, form.getState().values.pack_code, form.getState().values.commodity_id]);

  return (
    <div className={classes.formBody}>
      <TableFieldCheckbox classes={classes} field="active" title="Active" />
      <TableFieldText classes={classes} field="count" title="Count" />
      <TableFieldCombo classes={classes} field="commodity_id" title="Commodity" data={commodities} />
      <TableFieldCombo classes={classes} field="pack_code" title="Pack Code" data={palletSpecCodes} />
      <TableFieldCombo classes={classes} field="pack_id" title="Pack Carton" data={palletSpecCartons} />
      {form.getState().errors.duplicate_check && <p className={classes.formError}>Error: Duplicate Count found</p>}
    </div>
  );
};

type FormCheckboxProps = {
  field: string;
  title: string;
} & WithStyles<typeof styles>;

const TableFieldCheckbox: FC<FormCheckboxProps> = ({ classes, field, title }) => {
  return (
    <div className={classes.formField}>
      <label>{title}:</label>
      <div>
        <Field fullWidth name={field} component={Checkbox} type="checkbox" />
      </div>
    </div>
  );
};

const TableFieldText: FC<{ field: string; title: string; disabled?: boolean } & WithStyles<typeof styles>> = (props) => {
  const { classes, field, title, disabled } = props;
  return (
    <div className={classes.formField}>
      <label>{title}:</label>
      <div>
        <Field fullWidth name={field} component={TextField} type="text" disabled={disabled} validate={validate} />
      </div>
    </div>
  );
};

const TableFieldCombo: FC<{ classes: any; title: string; field: string; data: any; disabled?: boolean } & WithStyles<typeof styles>> = (props) => {
  const { classes, title, field, data, disabled = false } = props;

  return (
    <div className={classes.formField}>
      <label>{title}:</label>
      <div>
        <Field
          fullWidth
          name={field}
          childdata={data}
          disabled={disabled}
          validate={validate}
          component={MaterialSelectComponent}
          formControlProps={{ className: classes.formSelect }}
        />
      </div>
    </div>
  );
};

export default withStyles(styles)(CountsEditFormUnstyled);
