import React from "react";

import CustomAutoCompleteFilter from "../../../lib/components/customdatagridfilter/CustomFilter";

import { DateFormatter, FormatterNumber2Decimals } from "../../../lib/components/Formatters";
import { SummaryNumber2Decimal } from "../../../lib/components/SummaryFormatter";
import { handleColumnsArrangement, handleColumnsWidth } from "../../../lib/helpers/grid";
import RightAlignHeaderColumn from "../../../lib/components/gridhelper/RightAlignHeaderColumn";

const cellWidthAction = 50;
const cellWidthSmall = 100;
const cellWidthMedium = 130;
const cellWidthLarge = 200;

const defaultColumnProperties = {
  resizable: true,
  sortable: true,
};

let currentSpace = true;

export const GridColumns = (data, filters, arrangement, columnsWidth) => {
  let postValue = "";

  if (data) {
    if (currentSpace) {
      postValue = " ";
    }
    currentSpace = !currentSpace;
  }

  const columns = [
    {
      key: "sale_invoicenumber",
      name: "Invoice Number" + postValue,
      width: cellWidthSmall,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"sale_invoicenumber"} data={data} section="outstandingsummarygrid" />;
      },
    },
    {
      key: "forwardagentref",
      name: "Forward Agent Ref" + postValue,
      width: cellWidthSmall,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"forwardagentref"} data={data} section="outstandingsummarygrid" />;
      },
    },
    {
      key: "vessel_code",
      name: "Vessel" + postValue,
      width: cellWidthSmall,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"vessel_code"} data={data} section="outstandingsummarygrid" />;
      },
    },
    {
      key: "dispatch_containerno",
      name: "Container" + postValue,
      width: cellWidthSmall,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"dispatch_containerno"} data={data} section="outstandingsummarygrid" />;
      },
    },
    {
      key: "loadout_etd",
      name: "ETD" + postValue,
      width: cellWidthSmall,
      filterable: true,
      formatter: DateFormatter,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"loadout_etd"} data={data} section="outstandingsummarygrid" />;
      },
    },
    {
      key: "loadout_updated_eta",
      name: "Updated ETA" + postValue,
      width: cellWidthSmall,
      filterable: true,
      formatter: DateFormatter,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"loadout_updated_eta"} data={data} section="outstandingsummarygrid" />;
      },
    },
    {
      key: "currency_code",
      name: "Currency" + postValue,
      width: cellWidthSmall,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"currency_code"} data={data} section="outstandingsummarygrid" />;
      },
    },
    {
      key: "sale_total",
      name: "Invoice Total" + postValue,
      width: cellWidthMedium,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"sale_total"} data={data} section="outstandingsummarygrid" />;
      },
      formatter: FormatterNumber2Decimals,
      summaryFormatter: SummaryNumber2Decimal,
      headerRenderer: RightAlignHeaderColumn,
    },
    {
      key: "total_outstandingwithadjustments",
      name: "Total Outstanding" + postValue,
      width: cellWidthMedium,
      filterable: true,
      filterRenderer: (e) => {
        return (
          <CustomAutoCompleteFilter
            {...e}
            multiSelection={true}
            currentFilters={filters}
            fieldname={"total_outstandingwithadjustments"}
            data={data}
            section="outstandingsummarygrid"
          />
        );
      },
      formatter: FormatterNumber2Decimals,
      summaryFormatter: SummaryNumber2Decimal,
      headerRenderer: RightAlignHeaderColumn,
    },
  ].map((c) => ({ ...c, ...defaultColumnProperties }));

  let columnsArranged = [];
  columnsArranged = handleColumnsArrangement(columnsArranged, arrangement, columns);
  columnsArranged = handleColumnsWidth(columnsArranged, columnsWidth, columns);
  return columnsArranged;
};
