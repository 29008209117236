import { client, apiHeaders, request } from "./_base";

const endpoint = "variety";

export interface VarietyType {
  active: number;
  id?: number;
  code: string;
  name: string;
  commodity_id: string;
}

export const varieties = client<VarietyType>(endpoint, (item) => ({
  ...item,
}));

export interface VarietyTypeFull extends VarietyType {
  commodity: string;
  commodity_name: string;
}

export const activeVarieties = async (): Promise<VarietyType[]> => {
  const result = await request(`${endpoint}/ext/activeVarieties`, {
    headers: apiHeaders(),
  });
  const resp = await result.json();
  if (result.status == 500) {
    throw resp;
  }
  return resp.data;
};

export const varietyFull = async (): Promise<{ data: VarietyTypeFull[] }> => {
  const result = await request(`${endpoint}/extend/all`, {
    headers: apiHeaders(),
  });
  if (result.status == 500) {
    const resp = await result.json();
    throw resp;
  }
  return result.json();
};

export const DistinctVarietyCodes = async (): Promise<{ data: { code: string }[] }> => {
  const result = await request(`${endpoint}/count/distinctCodes`, {
    headers: apiHeaders(),
  });
  if (result.status == 500) {
    const resp = await result.json();
    throw resp;
  }
  return result.json();
};

export const DistinctVarietyNames = async (): Promise<{ data: { name: string }[] }> => {
  const result = await request(`${endpoint}/count/distinctNames`, {
    headers: apiHeaders(),
  });
  if (result.status == 500) {
    const resp = await result.json();
    throw resp;
  }
  return result.json();
};

export const varietyAllSortedMappedforCombo = async () => {
  const result = await activeVarieties();
  return result.sort((a, b) => a.name.localeCompare(b.name)).map((v) => ({ value: v.id, display: v.name + " (" + v.code + ")", data: v }));
};

type VarietiesByCommodityType = {
  id: number;
  name: string;
  code: string;
};

export const VarietiesByCommodity = async (commodity: string): Promise<{ data: VarietiesByCommodityType[] }> => {
  const result = await request(`${endpoint}/extend/varietiesByCommodity/${commodity}`, {
    headers: apiHeaders(),
  });
  if (result.status == 500) {
    const resp = await result.json();
    throw resp;
  }
  return result.json();
};

export const VarietiesByCommodityMappedForCombo = async (commodity_code: string) => {
  const result = await VarietiesByCommodity(commodity_code);
  return result.data.sort((a, b) => a.name.localeCompare(b.name)).map((v) => ({ id: v.id, value: v.id, display: v.name + " (" + v.code + ")" }));
};

export const VarietiesByCommodityMappedForComboValue = async (commodity_code: string) => {
  const result = await VarietiesByCommodity(commodity_code);
  return result.data.sort((a, b) => a.code.localeCompare(b.code)).map((v) => ({ id: v.id, value: v.code, display: v.name + " (" + v.code + ")" }));
};
