import React, { FC, useEffect, forwardRef, useState } from "react";
import { withStyles, createStyles, WithStyles, Theme } from "@material-ui/core/styles";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import MenuItem from "@material-ui/core/MenuItem";
import TableHead from "@material-ui/core/TableHead";
import Settings from "@material-ui/icons/Settings";
import AddIcon from "@material-ui/icons/Add";
import MTextField from "@material-ui/core/TextField";

import ReactDatePicker, { registerLocale } from "react-datepicker";
import en from "date-fns/esm/locale/en-GB";
import parseISO from "date-fns/parseISO";
import addDays from "date-fns/addDays";
import toDate from "date-fns/toDate";
registerLocale("en-GB", en);

import { Field } from "react-final-form";
import { TextField, Select } from "final-form-material-ui";

import Confirmation from "../lib/components/confirmation";
import CustomComboSelection from "../lib/components/CustomComboSelection";
import { DispatcheddocsFullType } from "../lib/api/dispatcheddocs";
import { getAgentsReadyForCombo } from "../lib/api/agent";
import { getVesselReadyForCombo } from "../lib/api/vessel";
import { getPortReadyForComboWithSearch } from "../lib/api/port";
import { getClientsAllSortedMappedforComboWithSearch } from "../lib/api/clients";
import { clientsByCode } from "../lib/api/clients";
import { clientsfinanceByClientID } from "../lib/api/clientsfinance";
import { getCurrencyAllSortedMappedforCombo } from "../lib/api/currency";
import { getSellingTermsReadyForCombo } from "../lib/api/sellingterms";
import { getColdroomReadyForCombo } from "../lib/api/coldroom";
import { getDealTypeReadyForCombo } from "../lib/api/dealtype";
import { getConsigneeReadyForCombo } from "../lib/api/consignee";
import { getClearingAgentReadyForCombo } from "../lib/api/clearingagent";
import { getContainerTypesReadyForCombo } from "../lib/api/containertypes";

import { isNullOrUndef } from "../lib/helpers/isNullOrUndef";

import Vessel from "../maintenance/vessel/vessel";
import Location from "../maintenance/coldrooms/coldrooms";

import format from "date-fns/format";
import { getTemperatureReadyForCombo } from "../lib/api/temperaturecodes";
import { FormApi } from "final-form";
import { FormState } from "./dispatch";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(1),
    },
    container: {
      display: "grid",
      gridTemplateColumns: "450px 450px 450px",
      gridGap: "10px",
    },
    containerItem: {
      textAlign: "right",
    },
    tableCellLabel: {
      padding: theme.spacing(1) / 2,
      width: "300px",
      borderBottom: "none",
      height: "50px",
      textAlign: "right",
      paddingRight: "15px",
    },
    tableCellDetail: {
      padding: theme.spacing(1) / 2,
      minWidth: "300px",
      maxWidth: "300px",
      borderBottom: "none",
      height: "50px",
    },
    tableCellsValueSelect: {
      textAlign: "left",
      minWidth: "300px",
      maxWidth: "300px",
      borderBottom: "none",
      height: "50px",
      padding: 0,
      marginTop: "-6px",
    },
    tableCellDetailExtra: {
      display: "flex",
      padding: theme.spacing(1) / 2,
      width: "300px",
      borderBottom: "none",
      height: "50px",
    },
    tableCellLabelHeader: {
      padding: theme.spacing(1) / 2,
      borderBottom: "none",
      height: "50px",
      paddingRight: "15px",
    },
  });

type DispatchAcceptDialogFormProps = {
  values: any;
  form: FormApi;
  handleClose: any;
  loading: boolean;
  dispatchDisabled: boolean;
} & WithStyles<typeof styles>;

const DispatchAcceptDialogFormUnstyled: FC<DispatchAcceptDialogFormProps> = (props) => {
  const { classes, values, form, dispatchDisabled, handleClose, loading } = props;

  const valuesTyped: DispatcheddocsFullType = {
    ...values,
    ["dispatch_invoicedate"]: values["dispatch_invoicedate"] ? parseISO(values["dispatch_invoicedate"]) : addDays(Date.now(), 1),
    ["dispatch_loaddate"]: values["dispatch_loaddate"] ? parseISO(values["dispatch_loaddate"]) : null,
    ["loadout_eta"]: values["loadout_eta"] ? parseISO(values["loadout_eta"]) : null,
    ["loadout_etd"]: values["loadout_etd"] ? parseISO(values["loadout_etd"]) : null,
  };

  const [agents, setAgents] = useState([]);
  const [vessels, setVessels] = useState([]);
  const [ports, setPorts] = useState([]);
  const [portsZA, setPortsZA] = useState([]);
  const [clients, setClients] = useState([]);
  const [currencies, setCurrencies] = useState([]);
  const [sellingterms, setSellingterms] = useState([]);
  const [coldrooms, setColdrooms] = useState([]);
  const [dealtypes, setDealtypes] = useState([]);
  const [consignees, setConsignees] = useState([]);
  const [clearingAgents, setClearingAgents] = useState([]);
  const [containertypes, setContainertypes] = useState([]);
  const [vesselMaintenanceOpen, setVesselMaintenanceOpen] = useState(false);
  const [coldroomMaintenanceOpen, setColdroomMaintenanceOpen] = useState(false);
  const [temperatureCodes, setTemperatureCodes] = useState([]);

  const [dateInvoice, setDateInvoice] = useState(valuesTyped["dispatch_invoicedate"]);
  const [dateLoading, setDateLoading] = useState(valuesTyped["dispatch_loaddate"]);
  const [dateETA, setDateETA] = useState(valuesTyped["loadout_eta"]);
  const [dateETD, setDateETD] = useState(valuesTyped["loadout_etd"]);

  const getVessels = async () => {
    const result = await getVesselReadyForCombo();
    setVessels(result);
  };

  const getColdrooms = async () => {
    const result = await getColdroomReadyForCombo();
    setColdrooms(result);
  };

  const changeInvoiceDate = (value: Date) => {
    setDateInvoice(value);
    form.change("dispatch_invoicedate", format(value, "yyyy-MM-dd'T'HH:mm:ss.SSSxxx"));
  };

  const changeDateLoad = (value: Date) => {
    setDateLoading(value);
    form.change("dispatch_loaddate", format(value, "yyyy-MM-dd'T'HH:mm:ss.SSSxxx"));
  };

  const changeDateETA = (value) => {
    setDateETA(value);
    form.change("loadout_eta", format(value, "yyyy-MM-dd'T'HH:mm:ss.SSSxxx"));
  };

  const changeDateETD = (value) => {
    setDateETD(value);
    form.change("loadout_etd", format(value, "yyyy-MM-dd'T'HH:mm:ss.SSSxxx"));
  };

  const loadList = async () => {
    const [agentsResult, vesselsResult, portResult, zaPortResult, currencyResult, coldroomResult, clientsResult, consigneeResult, temperatureResult] = await Promise.all([
      getAgentsReadyForCombo(),
      getVesselReadyForCombo(),
      getPortReadyForComboWithSearch(),
      getPortReadyForComboWithSearch("ZA"),
      getCurrencyAllSortedMappedforCombo(),
      getColdroomReadyForCombo(),
      getClientsAllSortedMappedforComboWithSearch(),
      getConsigneeReadyForCombo(0, valuesTyped.loadout_consignee),
      getTemperatureReadyForCombo(),
    ]);
    setAgents(agentsResult);
    setVessels(vesselsResult);
    setPorts(portResult);
    setPortsZA(zaPortResult);
    setCurrencies(currencyResult);
    setColdrooms(coldroomResult);
    setClients(clientsResult);
    setConsignees(consigneeResult);
    setTemperatureCodes(temperatureResult.sort((itemA, itemB) => itemA.value.localeCompare(itemB.value)));
    setSellingterms(getSellingTermsReadyForCombo());
    setDealtypes(getDealTypeReadyForCombo());
    setContainertypes(getContainerTypesReadyForCombo());
  };

  useEffect(() => {
    loadList();
  }, [valuesTyped["Dispatch ID"]]);

  const handleVesselEditDone = () => {
    const elements = document.getElementsByClassName("react-grid-Toolbar");
    for (let index = 0; index < elements.length; index++) {
      const element = elements[index] as HTMLElement;
      element.style.display = "initial";
    }

    setVesselMaintenanceOpen(false);
    getVessels();
  };

  const handleColdroomEditDone = () => {
    const elements = document.getElementsByClassName("react-grid-Toolbar");
    for (let index = 0; index < elements.length; index++) {
      const element = elements[index] as HTMLElement;
      element.style.display = "initial";
    }

    setColdroomMaintenanceOpen(false);
    getColdrooms();
  };

  useEffect(() => {
    const consignee = form.getState().values.loadout_consignee;
    getConsigneeReadyForCombo(0, consignee).then((consigneeList) => {
      setConsignees(consigneeList);
      if (!values.dispatch_consignee_id) {
        if (consigneeList.length > 0) {
          form.change("dispatch_consignee_id", consigneeList[0].data.id);
        } else {
          form.change("dispatch_consignee_id", null);
        }
      }
    });
    clientsByCode(consignee).then((resultClientsByCode) => {
      if (resultClientsByCode && resultClientsByCode.data && resultClientsByCode.data.length > 0) {
        clientsfinanceByClientID(resultClientsByCode.data[0].id).then((resultClientsFin) => {
          if (
            isNullOrUndef(form.getState().values.dispatch_currency) &&
            isNullOrUndef(form.getState().values.dispatch_dealtype) &&
            isNullOrUndef(form.getState().values.dispatch_sellingterm)
          ) {
            if (resultClientsFin && resultClientsFin.data && resultClientsFin.data.length > 0) {
              form.change("dispatch_currency", resultClientsFin.data[0].currency_id);
              form.change("dispatch_dealtype", resultClientsFin.data[0].dealtype);
              form.change("dispatch_sellingterm", resultClientsFin.data[0].sellingterms);
            }
          }
        });
      }
    });
  }, [form.getState().values.loadout_consignee]);

  useEffect(() => {
    const consigneeId = form.getState().values.dispatch_consignee_id;
    getClearingAgentReadyForCombo(consigneeId).then((clearingAgentList) => {
      setClearingAgents(clearingAgentList);

      if (consigneeId && clearingAgentList.length > 0) {
        // if clearingagent_id is not set OR clearingagent_id is not found in the list of available Clearing Agents
        if (!values.dispatch_clearingagent_id || !clearingAgentList.find((ca) => ca.value == values.dispatch_clearingagent_id)) {
          form.change("dispatch_clearingagent_id", clearingAgentList[0].data.id);
        }
        // if notifyparty_id is not set OR notifyparty_id is not found in the list of available Clearing Agents
        if (!values.dispatch_notifyparty_id || !clearingAgentList.find((ca) => ca.value == values.dispatch_notifyparty_id)) {
          form.change("dispatch_notifyparty_id", clearingAgentList[0].data.id);
        }
      } else {
        form.change("dispatch_clearingagent_id", null);
        form.change("dispatch_notifyparty_id", null);
      }
    });
  }, [form.getState().values.dispatch_consignee_id]);

  const handleConfirm = async () => {
    form.change("close", FormState.ShowConfirmation); // show confirmation
  };

  const handleSave = () => {
    form.change("close", FormState.Save); // close form
    form.submit();
  };

  const handleCreateInvoiceConfirmation = () => {
    form.change("close", FormState.CreateInvoice); // Create invoice
  };

  return (
    <div className={classes.root}>
      <div className={classes.container}>
        <div>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell className={classes.tableCellLabelHeader} colSpan={2}>
                  <Typography component="div">
                    <Box fontWeight="fontWeightBold" m={1}>
                      DISPATCH DETAILS
                    </Box>
                  </Typography>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableFieldDate classes={classes} dateValue={dateInvoice} field="dispatch_invoicedate" title="Date" changeDate={changeInvoiceDate} required />
              <TableFieldDate classes={classes} dateValue={dateLoading} field="dispatch_loaddate" title="Load Date" changeDate={changeDateLoad} required />
              <TableFieldText classes={classes} field="loadout_reference" title="Load Ref" disabled required />
              <TableFieldText classes={classes} field="dispatch_orderno" title="Location Ref" disabled />
              <TableFieldText classes={classes} field="Dispatch ID" title="Dispatch ID" disabled />
              <TableFieldText classes={classes} field="dispatch_carrier" title="Carrier" disabled={false} />
              <TableFieldText classes={classes} field="dispatch_regno" title="Truck Reg No" disabled={false} />
              <TableFieldComboWithOption
                title="Location"
                classes={classes}
                data={coldrooms}
                icon={"location"}
                field="loadout_coldroom_id"
                setConfirmEditID={setColdroomMaintenanceOpen}
              />
              <TableFieldText classes={classes} field="loadout_exportNotificationReference" title="Export Notification No" disabled={false} />
              <TableFieldCombo classes={classes} field="agent_id" title="Forwarding Agent" data={agents} required />
              <TableFieldText classes={classes} field="dispatch_forwardagentref" title="Forward Agent Ref" disabled={false} required />
              <TableFieldText classes={classes} field="dispatch_invoicenumber" title="Sales Ref" disabled={false} required />
            </TableBody>
          </Table>
        </div>
        <div>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell className={classes.tableCellLabelHeader} colSpan={2}>
                  <Typography component="div">
                    <Box fontWeight="fontWeightBold" m={1}>
                      SHIPMENT DETAILS
                    </Box>
                  </Typography>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableFieldComboWithOption icon="vessel" title="Vessel" data={vessels} classes={classes} field="vessel_id" setConfirmEditID={setVesselMaintenanceOpen} required />
              <TableFieldText classes={classes} field="loadout_voyageNumber" title="Voyage Number" disabled={false} required />
              <TableFieldText classes={classes} field="dispatch_containerno" title="Container No" disabled={false} required />
              <TableFieldCombo classes={classes} field="dispatch_containertype" title="Container Type" data={containertypes} required />
              <TableFieldText classes={classes} field="dispatch_sealnumber" title="Seal No" disabled={false} required />
              <CustomSelect classes={classes} values={valuesTyped} form={form} field="portloading_id" title="POL" data={portsZA} required />
              <CustomSelect classes={classes} values={valuesTyped} form={form} field="portdistcharge_id" title="POD" data={ports} required />
              <CustomSelect classes={classes} values={valuesTyped} form={form} field="portfinal_id" title="Final Destination" data={ports} required />
              <TableFieldDate classes={classes} field="loadout_etd" dateValue={dateETD} title="ETD" changeDate={changeDateETD} required />
              <TableFieldDate classes={classes} field="loadout_eta" dateValue={dateETA} title="ETA" changeDate={changeDateETA} required />
              <TableFieldText classes={classes} field="temptail" title="Temp Recorder" disabled={false} />
              <TableFieldText classes={classes} field="recorder_position" title="Recorder Position" disabled={false} />
            </TableBody>
          </Table>
        </div>
        <div>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell className={classes.tableCellLabelHeader} colSpan={2}>
                  <Typography component="div">
                    <Box fontWeight="fontWeightBold" m={1}>
                      CLIENT DETAILS
                    </Box>
                  </Typography>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <CustomSelect form={form} title="Client" field="loadout_consignee" data={clients} values={valuesTyped} classes={classes} required />
              <TableFieldCombo classes={classes} field="dispatch_consignee_id" title="Consignee" data={consignees} required />
              <TableFieldCombo classes={classes} field="dispatch_clearingagent_id" title="Clearing Agent" data={clearingAgents} required />
              <TableFieldCombo classes={classes} field="dispatch_notifyparty_id" title="Notify Party" data={clearingAgents} required />
              <TableFieldText classes={classes} field="stockdgrouped_po" title="Client PO" disabled={false} />
              <TableFieldCombo classes={classes} field="dispatch_currency" title="Currency" data={currencies} required />
              <TableFieldCombo classes={classes} field="dispatch_sellingterm" title="Selling Terms" data={sellingterms} required />
              <TableFieldCombo classes={classes} field="dispatch_dealtype" title="Deal Type" data={dealtypes} required />
              <TableFieldCombo classes={classes} field="temperaturecode" title="Temperature Code" data={temperatureCodes} required />
              <TableFieldTextArea classes={classes} field="dispatch_remarks" title="Remarks" disabled={false} multiple={6} />
            </TableBody>
          </Table>
        </div>
        <div>
          <Field hidden name="close" field="close" value={0} render={() => <></>} />
        </div>
        <div></div>
        <div className={classes.containerItem}>
          <Button
            type="submit"
            color="primary"
            variant="contained"
            name="create-invoice"
            style={{ margin: "2px" }}
            disabled={dispatchDisabled || loading}
            onClick={handleCreateInvoiceConfirmation}
          >
            create invoice
          </Button>
          <Button type="button" name="save" variant="contained" color="primary" style={{ margin: "2px" }} onClick={handleSave} disabled={dispatchDisabled || loading}>
            save
          </Button>
          <Button type="submit" name="confirm" variant="contained" color="primary" style={{ margin: "2px" }} onClick={handleConfirm} disabled={dispatchDisabled || loading}>
            confirm
          </Button>
          <Button name="close" variant="contained" color="secondary" style={{ margin: "2px" }} onClick={handleClose} disabled={loading}>
            Close
          </Button>
        </div>
        {vesselMaintenanceOpen && (
          <Confirmation fullscreen body={undefined} handleConfirm={() => {}} isOpen={vesselMaintenanceOpen} title={"Loadout - Editing Vessels"} handleClose={handleVesselEditDone}>
            <Vessel handleOnClose={() => handleVesselEditDone()} />
          </Confirmation>
        )}
        {coldroomMaintenanceOpen && (
          <Confirmation
            fullscreen
            body={undefined}
            handleConfirm={() => {}}
            isOpen={coldroomMaintenanceOpen}
            handleClose={handleColdroomEditDone}
            title={"Loadout - Editing Coldrooms"}
          >
            <Location handleOnClose={() => handleColdroomEditDone()} />
          </Confirmation>
        )}
      </div>
    </div>
  );
};

export default withStyles(styles)(DispatchAcceptDialogFormUnstyled);

const TableFieldText: FC<{ field: string; title: string; disabled: boolean; required?: boolean } & WithStyles<typeof styles>> = (props) => {
  const { classes, field, title, disabled, required } = props;
  return (
    <TableRow>
      <TableCell className={classes.tableCellLabel}>
        {required && <FieldRequired />}
        {title}:
      </TableCell>
      <TableCell className={classes.tableCellDetail}>
        <Field
          fullWidth
          type="text"
          name={field}
          required={required}
          disabled={disabled}
          component={TextField}
          parse={(value) => value}
          style={{ background: disabled && "#ececec" }}
        />
      </TableCell>
    </TableRow>
  );
};

const CalenderCustomInput = forwardRef((props: any, ref: any) => {
  return (
    <Button name="CalenderCustomInput" variant="contained" color="primary" onClick={props.onClick} style={{ marginTop: "5px", width: "300px" }}>
      {props.value || "Please select a date"}
    </Button>
  );
});
const TableFieldDate: FC<{ required?: boolean; field: string; dateValue: Date; title: string; changeDate: (value) => void } & WithStyles<typeof styles>> = (props) => {
  const { classes, field, dateValue, title, changeDate, required } = props;
  return (
    <TableRow>
      <TableCell className={classes.tableCellLabel}>
        {required && <FieldRequired />}
        {title}:
      </TableCell>
      <TableCell className={classes.tableCellDetail}>
        <Field
          name={field}
          validate={(value) => (required && !value ? "Date is required" : undefined)}
          render={({ input, meta }) => (
            <>
              <ReactDatePicker
                name={field}
                locale="en-GB"
                showWeekNumbers
                dateFormat="dd-MM-yyyy"
                customInput={<CalenderCustomInput />}
                onChange={(value) => {
                  input.onChange(value);
                  changeDate(value);
                }}
                selected={input.value && input.value != "" ? toDate(new Date(input.value)) : null}
                placeholderText="click here to select a date"
              />
              {meta.error && meta.submitFailed && <p style={{ color: "red", margin: 0, marginTop: "0.5rem" }}>{meta.error}</p>}
            </>
          )}
        />
      </TableCell>
    </TableRow>
  );
};

const TableFieldCombo: FC<{ classes: any; title: string; field: string; data: any; required?: boolean } & WithStyles<typeof styles>> = (props) => {
  const { classes, title, field, data, required } = props;
  return (
    <TableRow>
      <TableCell className={classes.tableCellLabel}>
        {required && <FieldRequired />}
        {title}:
      </TableCell>
      <TableCell className={classes.tableCellDetail}>
        <Field required={required} name={field} component={Select as any} formControlProps={{ className: classes.tableCellsValueSelect }}>
          {(data || []).map((item, index) => (
            <MenuItem key={`${item.value}${index}`} value={item.value}>
              {item.display}
            </MenuItem>
          ))}
        </Field>
      </TableCell>
    </TableRow>
  );
};

const fieldIcon = {
  location: <AddIcon />,
  vessel: <Settings />,
  default: <Settings />,
};

const TableFieldComboWithOption: FC<
  {
    classes: any;
    title: string;
    field: string;
    data: any;
    icon?: string;
    required?: boolean;
    setConfirmEditID(value: boolean): void;
  } & WithStyles<typeof styles>
> = (props) => {
  const { classes, title, field, data, setConfirmEditID, icon = "default", required } = props;
  return (
    <TableRow>
      <TableCell className={classes.tableCellLabel}>
        {required && <FieldRequired />}
        {title}:
      </TableCell>
      <TableCell className={classes.tableCellDetailExtra}>
        <Field required={required} name={field} component={Select as any} formControlProps={{ className: classes.tableCellsValueSelect }}>
          {(data || []).map((item, index) => (
            <MenuItem key={`${item.value}${index}`} value={item.value}>
              {item.display}
            </MenuItem>
          ))}
        </Field>
        <Button size="small" color="primary" onClick={() => setConfirmEditID(true)}>
          {fieldIcon[icon]}
        </Button>
      </TableCell>
    </TableRow>
  );
};

type SelectType = { display: string; value: string; search: string };
type CustomSelectProps = { form: any; classes: any; field: string; title: string; data: SelectType[]; values: any; required?: boolean };

const CustomSelect: FC<CustomSelectProps> = ({ classes, field, data, title, values, form, required }) => {
  const [selected, setSelected] = useState(values[field]);

  const handleItemSelect = (selected: string) => {
    const selectedVal = data.find((item) => item.value === selected);
    form.change(field, selectedVal.value);
    setSelected(selectedVal.value);
  };

  return (
    <TableRow>
      <TableCell className={classes.tableCellLabel}>
        {required && <FieldRequired />}
        {title}:
      </TableCell>
      <TableCell className={classes.tableCellDetail}>
        <CustomComboSelection data={data} field={field} selectedValue={selected} handleSelect={handleItemSelect} required={required} />
      </TableCell>
    </TableRow>
  );
};

const TableFieldTextArea: React.FunctionComponent<{ field: string; title: string; disabled: boolean; multiple: number } & WithStyles<typeof styles>> = (props) => {
  const { classes, field, title, disabled, multiple } = props;
  return (
    <TableRow>
      <TableCell className={classes.tableCellLabel}>{title}:</TableCell>
      <TableCell className={classes.tableCellDetail}>
        <Field
          fullWidth
          type="text"
          name={field}
          disabled={disabled}
          parse={(value) => value}
          render={({ input, meta }) => (
            <MTextField {...input} {...meta} multiline rows={multiple} variant="standard" style={{ backgroundColor: "white", padding: "4px", width: "300px" }} />
          )}
        />
      </TableCell>
    </TableRow>
  );
};

const FieldRequired = () => <span style={{ color: "red", marginRight: "5px" }}>*</span>;
