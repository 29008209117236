import React, { useContext, useEffect, useMemo, useState } from "react";
import { withStyles, createStyles, WithStyles, Theme } from "@material-ui/core/styles";

import Button from "@material-ui/core/Button";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import TextField from "@material-ui/core/TextField";
import IconButton from "@material-ui/core/IconButton";

import IconDelete from "@material-ui/icons/Delete";

import { estimatedcostsFull } from "../../../lib/api/estimatedcost";
import { VarietiesByCommodity } from "../../../lib/api/variety";
import { estimatedcostsetups, estimatedcostsetupsFull } from "../../../lib/api/estimatedcostsetup";
import { estimatedcostitemsAllSortedMappedforCombo } from "../../../lib/api/estimatedcostitem";
import { getPacksByCommodityCodeFull, getPacksByCommodityCodeFullCombo, Pack } from "../../../lib/api/pack";

import Confirmation from "../../../lib/components/confirmation";

import numeral from "numeral";

import { MaterialSelectNativeComponentNonField } from "../../../lib/helpers/materialcomponents";
import { SnackContext } from "../../../lib/context/SnackContext";
import { GenerateErrorMessage } from "../../../lib/helpers/string_methods";
import { duplicateCheck } from "./estimatedcostssetup";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(1),
    },
    varietySelect: {
      width: "100%",
    },
    tableCell: {
      borderWidth: "1px",
      paddingLeft: "2px",
      paddingRight: "2px",
      borderColor: "black",
      borderStyle: "solid",
      width: "200px",
    },
    tableCellValue: {
      borderWidth: "1px",
      paddingLeft: "2px",
      paddingRight: "2px",
      borderColor: "black",
      borderStyle: "solid",
      width: "100px",
      textAlign: "right",
    },
    tableCellTotal: {
      fontWeight: "bold",
      textTransform: "uppercase",
      paddingTop: "3px",
    },
    tableHeader: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "flex-end",
      alignItems: "center",
      marginBottom: "0.25rem",
      gap: "0.5rem",
    },
  });

type EstimatedCostsSetupDetailProps = {
  estimatedcost_id: number;
  packspec_id: number;
  isDuplicate: boolean;
  submitSetupDetail(packSpecId: number | string, detail: any[]): Promise<number | undefined>;
  currentHead: any;
  allPacks: any[];
  setIsDuplicate(duplicate: boolean): void;
} & WithStyles<typeof styles>;

type EstimatedCostsSetupDetailEditingProps = {
  estimatedcostid: number;
  packspec_id: number;
};

const EstimatedCostsSetupDetailUnstyled: React.FunctionComponent<EstimatedCostsSetupDetailProps> = ({
  classes,
  estimatedcost_id,
  packspec_id,
  submitSetupDetail,
  isDuplicate,
  currentHead,
  allPacks,
  setIsDuplicate,
}) => {
  const [currentDetail, setCurrentDetail] = useState({});
  const [editingDetail, setEditingDetail] = useState<EstimatedCostsSetupDetailEditingProps>();
  const [estimatedCostItems, setEstimatedCostItems] = useState([]);

  useEffect(() => {
    loadData(estimatedcost_id, packspec_id);
  }, [estimatedcost_id, packspec_id]);

  const loadData = async (estcost_id: number, pack_id: number) => {
    const result = await estimatedcostsetupsFull(estcost_id, pack_id);
    const formatted = result.reduce((acc, curr) => {
      acc[curr.packspec_id || 0] = [...(acc[curr.packspec_id || 0] || []), curr];
      return acc;
    }, {});
    setCurrentDetail(formatted);
  };

  useEffect(() => {
    estimatedcostitemsAllSortedMappedforCombo().then((result) => {
      setEstimatedCostItems(result);
    });
  }, []);

  const handleEdit = (psid) => {
    setEditingDetail({ estimatedcostid: estimatedcost_id, packspec_id: psid });
  };

  const handleEditClose = () => {
    setEditingDetail(undefined);
  };

  const handleEditConfirm = async (newPackSpecId: number, detail: any[]) => {
    const id = await submitSetupDetail(newPackSpecId, detail);
    if (id) {
      handleEditClose();
      loadData(id, newPackSpecId);
    }
  };

  const totalProducerCost = useMemo(() => {
    return Object.keys(currentDetail).reduce((obj, pskey) => {
      return currentDetail[pskey].reduce((obj, row) => {
        if (row.group_id != 7) {
          obj["eur"] = (obj["eur"] || 0) + row.eur;
          obj["gbp"] = (obj["gbp"] || 0) + row.gbp;
          obj["usd"] = (obj["usd"] || 0) + row.usd;
          obj["cad"] = (obj["cad"] || 0) + row.cad;
          obj["zar"] = (obj["zar"] || 0) + row.value;
        }
        return obj;
      }, obj);
    }, {});
  }, [currentDetail]);

  return (
    <div className={classes.root}>
      {Object.keys(currentDetail).map((pskey) => (
        <div style={{ float: "left", marginRight: "10px" }}>
          <div className={classes.tableHeader}>
            <span>
              PACK: <span style={{ fontWeight: "bold" }}>{currentDetail[pskey][0].pack_code}</span>
            </span>
            <span>|</span>
            <span>
              CARTONS PER PALLET: <span style={{ fontWeight: "bold" }}>{currentDetail[pskey][0].pack_carton}</span>
            </span>
          </div>
          <table>
            <tbody>
              <tr style={{ fontWeight: "bold" }}>
                <td className={classes.tableCell}>Group</td>
                <td className={classes.tableCell}>Description</td>
                <td className={classes.tableCellValue}>USD</td>
                <td className={classes.tableCellValue}>EUR</td>
                <td className={classes.tableCellValue}>GBP</td>
                <td className={classes.tableCellValue}>CAD</td>
                <td className={classes.tableCellValue}>ZAR</td>
                <td className={classes.tableCell}>Varieties</td>
              </tr>
              {currentDetail[pskey].map((di) => {
                if (di.group_id != 7) {
                  return (
                    <tr key={`row${di.estimatedcostitem_type}${di.id}`}>
                      <td className={classes.tableCell}>{di.group_name}</td>
                      <td className={classes.tableCell}>{di.estimatedcostitem_costdescription}</td>
                      <td className={classes.tableCellValue}>{numeral(di.usd).format("0.00")}</td>
                      <td className={classes.tableCellValue}>{numeral(di.eur).format("0.00")}</td>
                      <td className={classes.tableCellValue}>{numeral(di.gbp).format("0.00")}</td>
                      <td className={classes.tableCellValue}>{numeral(di.cad).format("0.00")}</td>
                      <td className={classes.tableCellValue}>{numeral(di.value).format("0.00")}</td>
                      <td className={classes.tableCell}>{di.variety ? di.variety : "ALL"}</td>
                    </tr>
                  );
                }
              })}
              <tr>
                <td colSpan={2} className={classes.tableCellTotal}>
                  Total Producer Estimated Local Costs
                </td>
                <td className={classes.tableCellValue}>{numeral(totalProducerCost.usd).format("0.00")}</td>
                <td className={classes.tableCellValue}>{numeral(totalProducerCost.eur).format("0.00")}</td>
                <td className={classes.tableCellValue}>{numeral(totalProducerCost.gbp).format("0.00")}</td>
                <td className={classes.tableCellValue}>{numeral(totalProducerCost.cad).format("0.00")}</td>
                <td className={classes.tableCellValue}>{numeral(totalProducerCost.zar).format("0.00")}</td>
              </tr>
              <tr>
                <td colSpan={6}>&nbsp;</td>
              </tr>
              {currentDetail[pskey].map((di) => {
                if (di.group_id == 7) {
                  return (
                    <tr key={`row${di.estimatedcostitem_type}${di.id}`}>
                      <td className={classes.tableCell}>{di.group_name}</td>
                      <td className={classes.tableCell}>{di.estimatedcostitem_costdescription}</td>
                      <td className={classes.tableCellValue}>
                        {numeral(di.usd).format("0.00")} {di.estimatedcostitem_type == 2 && "%"}
                      </td>
                      <td className={classes.tableCellValue}>
                        {numeral(di.eur).format("0.00")} {di.estimatedcostitem_type == 2 && "%"}
                      </td>
                      <td className={classes.tableCellValue}>
                        {numeral(di.gbp).format("0.00")} {di.estimatedcostitem_type == 2 && "%"}
                      </td>
                      <td className={classes.tableCellValue}>
                        {numeral(di.cad).format("0.00")} {di.estimatedcostitem_type == 2 && "%"}
                      </td>
                      <td className={classes.tableCellValue}>
                        {numeral(di.value).format("0.00")} {di.estimatedcostitem_type == 2 && "%"}
                      </td>
                      <td className={classes.tableCell}>{di.variety ? di.variety : "ALL"}</td>
                    </tr>
                  );
                }
              })}
            </tbody>
          </table>
          <div style={{ paddingTop: "5px", float: "right", marginRight: "-2px" }}>
            <Button variant="contained" color="primary" style={{ margin: "2px", width: "100px" }} onClick={() => handleEdit(pskey)}>
              EDIT
            </Button>
          </div>
        </div>
      ))}
      {Object.keys(currentDetail).length === 0 && (
        <div style={{ paddingTop: "5px", float: "right", width: "100%" }}>
          <Button variant="contained" color="primary" onClick={() => handleEdit(1)}>
            CREATE DETAIL
          </Button>
        </div>
      )}
      <Confirmation isOpen={!!editingDetail} handleClose={handleEditClose} handleConfirm={handleEditClose} title={"Estimated Costs Details"} body={undefined}>
        <CostEstimationSetup
          editingDetail={editingDetail}
          estimatedCostItems={estimatedCostItems}
          handleSubmit={handleEditConfirm}
          cancel={handleEditClose}
          classes={classes}
          isDuplicate={isDuplicate}
          currentHead={currentHead}
          allPacks={allPacks}
          setIsDuplicate={setIsDuplicate}
        />
      </Confirmation>
    </div>
  );
};

export default withStyles(styles)(EstimatedCostsSetupDetailUnstyled);

const CostEstimationSetup: React.FunctionComponent<any> = ({
  classes,
  editingDetail,
  estimatedCostItems,
  handleSubmit,
  cancel,
  currentHead,
  allPacks,
  setIsDuplicate,
  isDuplicate,
}) => {
  const { updateSnack } = useContext(SnackContext);

  const [comboVarietyOpen, setComboVarietyOpen] = React.useState<number | undefined>(undefined);
  const [detail, setDetail] = useState(editingDetail);
  const [costEstimationDetail, setCostEstimationDetail] = useState([]);
  const [varieties, setVarieties] = useState(undefined);
  const [packSpecs, setPackSpecs] = useState<Pack[]>([]);
  const [estimatedCostHead, setEstimatedCostHead] = useState({
    cartons: undefined,
    commodity: undefined,
    commodity_full: undefined,
    created_at: undefined,
    exchange_usd: undefined,
    exchange_eur: undefined,
    exchange_gbp: undefined,
    exchange_cad: undefined,
    exchange_zar: undefined,
    id: undefined,
    market: undefined,
    pack: undefined,
    season: undefined,
    updated_at: undefined,
    region: undefined,
    country: undefined,
  });

  useEffect(() => {
    loadData();
  }, [editingDetail]);

  const loadData = async () => {
    if (detail) {
      const [estCostSetupFull, [estimatedCostData]] = await Promise.all([
        estimatedcostsetupsFull(detail.estimatedcostid, detail.packspec_id),
        estimatedcostsFull(detail.estimatedcostid),
      ]);

      const [varietiesByComm, packsFull] = await Promise.all([VarietiesByCommodity(estimatedCostData.commodity), getPacksByCommodityCodeFull(estimatedCostData.commodity)]);

      const reMappedEstimations = estCostSetupFull.map((item) => ({
        ...item,
        value: numeral(item.value).format("0.00"),
        usd: calculateRate(item.value, estimatedCostData.exchange_usd),
        cad: calculateRate(item.value, estimatedCostData.exchange_cad),
        gbp: calculateRate(item.value, estimatedCostData.exchange_gbp),
        eur: calculateRate(item.value, estimatedCostData.exchange_eur),
      }));

      setCostEstimationDetail(reMappedEstimations);
      setEstimatedCostHead(estimatedCostData);
      setPackSpecs(packsFull);
      setVarieties(varietiesByComm.data);
    }
  };

  const handleChangedDetail = (idx: number, field: string, value: any) => {
    const detailChange = [...costEstimationDetail];
    detailChange[idx][field] = !value ? "" : field == "variety" ? (value.includes("ALL") ? "ALL" : value.join(",")) : value;

    if (field === "estimatedcostitem_id") {
      detailChange[idx]["estimatedcostitem_type"] = (estimatedCostItems.find((eci) => eci.id == value) || { type: 0 }).type;
    }

    setCostEstimationDetail([...detailChange]);
  };

  const calculateRate = (value, exchange) => {
    return (value / exchange).toFixed(5);
  };

  const currencies = {
    eur: estimatedCostHead.exchange_eur,
    usd: estimatedCostHead.exchange_usd,
    gbp: estimatedCostHead.exchange_gbp,
    cad: estimatedCostHead.exchange_cad,
  };

  const handleUpdateCurrency = (id, field) => {
    const idx = costEstimationDetail.findIndex((item) => {
      return item.id == id;
    });

    const detailChange = [...costEstimationDetail];

    if (field != "ZAR") {
      detailChange[idx].value = (detailChange[idx][field] * currencies[field]).toFixed(2);
    }

    JSON.parse(JSON.stringify(Object.keys(currencies)))
      .filter((currency) => currency != field)
      .map((key) => {
        detailChange[idx][key] = calculateRate(detailChange[idx].value, currencies[key]);
      });
    setCostEstimationDetail([...detailChange]);
  };

  const handleSave = () => {
    handleSubmit(detail.packspec_id, costEstimationDetail);
  };

  const handleAddLine = () => {
    const newDetail = {
      id: null,
      estimatedcost_id: detail.estimatedcostid,
      palletspec_id: detail.palletspecid,
      estimatedcostitem_id: 1,
      value: 1,
    };
    setCostEstimationDetail((prevState) => [...prevState, newDetail]);
  };

  const handleRemoveLine = async (index: number) => {
    setCostEstimationDetail((prevState) => [...prevState.filter((_, i) => i !== index)]);
  };

  const handleChanges = (field: string, value: any) => {
    const newDetail = { ...detail };

    if (field === "code") {
      const carton = (packSpecs.find((ps) => ps.code == value) || { id: 0 }).id;
      newDetail.packspec_id = carton;
    }
    newDetail[field] = value;

    setDetail({ ...newDetail });
  };

  const handleVarietyCombo = (id: number) => {
    setComboVarietyOpen(id);
  };

  const totals = useMemo(
    () =>
      costEstimationDetail.reduce((obj, row) => {
        if (row.group_id != 7) {
          obj["usd"] = (obj["usd"] || 0) + parseFloat(row.usd);
          obj["eur"] = (obj["eur"] || 0) + parseFloat(row.eur);
          obj["gbp"] = (obj["gbp"] || 0) + parseFloat(row.gbp);
          obj["cad"] = (obj["cad"] || 0) + parseFloat(row.cad);
          obj["value"] = (obj["value"] || 0) + parseFloat(row.value);
        }
        return obj;
      }, {}),
    [costEstimationDetail],
  );

  const groupedByPackCode = useMemo(
    () =>
      packSpecs
        .reduce((arr, pack) => {
          if (!arr.some((a) => a.value == pack.code)) {
            arr.push({ id: pack.code, value: pack.code, display: pack.code });
          }
          return arr;
        }, [])
        .sort((a, b) => a.display.localeCompare(b.display)),
    [packSpecs],
  );

  const groupedByPackCartons = useMemo(
    () =>
      packSpecs.reduce((arr, pack) => {
        if (pack.code == detail.code) {
          arr.push({ id: pack.id, value: pack.id, display: pack["No Cartons"] });
        }
        return arr;
      }, []),
    [packSpecs, detail],
  );

  useEffect(() => {
    // set selected Pack Code
    const selectedPack = packSpecs.find((pack) => pack.id == detail.packspec_id);
    if (selectedPack) {
      setDetail({ ...detail, code: selectedPack.code });
    }
  }, [packSpecs]);

  useEffect(() => {
    const duplicate = duplicateCheck(currentHead, allPacks, detail.packspec_id);
    setIsDuplicate(duplicate);
  }, [detail]);

  return (
    <div>
      {detail && (
        <div>
          <div>
            <table>
              <tbody>
                <tr>
                  <td style={{ fontWeight: "bold", width: "150px" }}>Season </td>
                  <td style={{ width: "10px" }}> : </td>
                  <td style={{ width: "200px" }}> {estimatedCostHead.season}</td>
                </tr>
                <tr>
                  <td style={{ fontWeight: "bold", width: "150px" }}>Commodity </td>
                  <td style={{ width: "10px" }}> : </td>
                  <td style={{ width: "200px" }}>{` ${estimatedCostHead.commodity_full} (${estimatedCostHead.commodity})`}</td>
                </tr>
                <tr>
                  <td style={{ fontWeight: "bold", width: "150px" }}>Region </td>
                  <td style={{ width: "10px" }}> : </td>
                  <td style={{ width: "200px" }}> {estimatedCostHead.region}</td>
                </tr>
                <tr>
                  <td style={{ fontWeight: "bold", width: "150px" }}>Market </td>
                  <td style={{ width: "10px" }}> : </td>
                  <td style={{ width: "200px" }}> {estimatedCostHead.market}</td>
                </tr>
                <tr>
                  <td style={{ fontWeight: "bold", width: "150px" }}>Country </td>
                  <td style={{ width: "10px" }}> : </td>
                  <td style={{ width: "200px" }}> {estimatedCostHead.country}</td>
                </tr>
                <tr>
                  <td style={{ fontWeight: "bold", width: "150px" }}>Pack </td>
                  <td style={{ width: "10px" }}> : </td>
                  <td style={{ width: "200px" }}>
                    <MaterialSelectNativeComponentNonField
                      addNone
                      name={"code"}
                      value={detail.code}
                      childdata={groupedByPackCode}
                      onChange={(e) => handleChanges("code", e.target.value)}
                    />
                  </td>
                </tr>
                <tr>
                  <td style={{ fontWeight: "bold", width: "150px" }}>Cartons per Pallet </td>
                  <td style={{ width: "10px" }}> : </td>
                  <td style={{ width: "200px" }}>
                    <MaterialSelectNativeComponentNonField
                      addNone={false}
                      name={"packspec_id"}
                      value={detail.packspec_id}
                      nonedisabled={true}
                      childdata={groupedByPackCartons}
                      onChange={(e) => handleChanges("packspec_id", e.target.value)}
                    />
                  </td>
                </tr>
              </tbody>
            </table>
            <br />
            <br />
            <table>
              <tbody>
                <tr>
                  <td style={{ width: "100px", fontWeight: "bold" }}></td>
                  <td style={{ width: "300px", fontWeight: "bold" }}></td>
                  <td style={{ width: "100px", fontWeight: "bold" }}>USD</td>
                  <td style={{ width: "100px", fontWeight: "bold" }}>EUR</td>
                  <td style={{ width: "100px", fontWeight: "bold" }}>GBP</td>
                  <td style={{ width: "100px", fontWeight: "bold" }}>CAD</td>
                  <td style={{ width: "100px", fontWeight: "bold" }}>ZAR</td>
                  <td></td>
                  <td></td>
                </tr>
                <tr>
                  <td style={{ width: "100px", fontWeight: "bold" }}>Group</td>
                  <td style={{ width: "300px", fontWeight: "bold" }}>Item</td>
                  <td style={{ width: "100px", fontWeight: "bold" }}>{numeral(estimatedCostHead.exchange_usd).format("0.00")}</td>
                  <td style={{ width: "100px", fontWeight: "bold" }}>{numeral(estimatedCostHead.exchange_eur).format("0.00")}</td>
                  <td style={{ width: "100px", fontWeight: "bold" }}>{numeral(estimatedCostHead.exchange_gbp).format("0.00")}</td>
                  <td style={{ width: "100px", fontWeight: "bold" }}>{numeral(estimatedCostHead.exchange_cad).format("0.00")}</td>
                  <td style={{ width: "100px", fontWeight: "bold" }}>{numeral(estimatedCostHead.exchange_zar).format("0.00")}</td>
                  <td style={{ width: "300px", fontWeight: "bold" }}>Varieties</td>
                  <td></td>
                </tr>
                {costEstimationDetail.map((ced, index) => {
                  return (
                    <tr key={`row${index}`}>
                      <td>{ced.group_name}</td>
                      <td>
                        <MaterialSelectNativeComponentNonField
                          name={`item${ced.id}`}
                          onChange={(e) => handleChangedDetail(index, "estimatedcostitem_id", e.target.value)}
                          value={ced.estimatedcostitem_id}
                          addNone={false}
                          childdata={estimatedCostItems}
                          nonedisabled={true}
                        />
                      </td>

                      {/* USD */}
                      <td style={{ width: "100px" }}>
                        {ced.estimatedcostitem_type != 2 && (
                          <TextField
                            name={`USD${ced.id}${index}`}
                            onBlur={(e) => handleUpdateCurrency(ced.id, "usd")}
                            onChange={(e) => handleChangedDetail(index, "usd", e.target.value)}
                            value={ced.usd || 0.0}
                          />
                        )}
                      </td>

                      {/* EUR */}
                      <td style={{ width: "100px" }}>
                        {ced.estimatedcostitem_type != 2 && (
                          <TextField
                            name={`EUR${ced.id}${index}`}
                            onBlur={(e) => handleUpdateCurrency(ced.id, "eur")}
                            onChange={(e) => handleChangedDetail(index, "eur", e.target.value)}
                            value={ced.eur || 0.0}
                          />
                        )}
                      </td>

                      {/* GBP */}
                      <td style={{ width: "100px" }}>
                        {ced.estimatedcostitem_type != 2 && (
                          <TextField
                            name={`GBP${ced.id}${index}`}
                            onBlur={(e) => handleUpdateCurrency(ced.id, "gbp")}
                            onChange={(e) => handleChangedDetail(index, "gbp", e.target.value)}
                            value={ced.gbp || 0.0}
                          />
                        )}
                      </td>

                      {/* CAD */}
                      <td style={{ width: "100px" }}>
                        {ced.estimatedcostitem_type != 2 && (
                          <TextField
                            name={`CAD${ced.id}${index}`}
                            onBlur={(e) => handleUpdateCurrency(ced.id, "cad")}
                            onChange={(e) => handleChangedDetail(index, "cad", e.target.value)}
                            value={ced.cad || 0.0}
                          />
                        )}
                      </td>

                      {/* ZAR */}
                      <td>
                        <TextField
                          name={`ZAR${ced.id}${index}`}
                          onBlur={(e) => handleUpdateCurrency(ced.id, "ZAR")}
                          onChange={(e) => handleChangedDetail(index, "value", e.target.value)}
                          value={ced.value || 0.0}
                          style={{ width: "100%" }}
                        />
                      </td>

                      <td>
                        <Select
                          multiple
                          displayEmpty
                          className={classes.varietySelect}
                          disabled={ced.estimatedcostitem_type == 0}
                          value={(ced.variety && ced.variety.split(",")) || []}
                          open={comboVarietyOpen == index}
                          onOpen={() => handleVarietyCombo(index)}
                          onClose={() => handleVarietyCombo(undefined)}
                          onChange={(e) => handleChangedDetail(index, "variety", e.target.value)}
                          renderValue={(value) => {
                            if (!value) return undefined;
                            const selected = JSON.parse(JSON.stringify(value));
                            if (!selected[0] || selected[0].length === 0) {
                              // Purposely allowing different selected varieties to
                              // show in case there are any(instead of hiding and
                              // polluting the db)
                              return ced.estimatedcostitem_type != 0 ? <em>Please make a selection</em> : <em>ALL</em>;
                            }
                            if (selected) {
                              return <em>{`${selected.join(", ")}`}</em>;
                            }
                          }}
                        >
                          <MenuItem disabled value="">
                            <em>Please make a selection</em>
                          </MenuItem>
                          <MenuItem value={"ALL"}>ALL</MenuItem>
                          {(varieties || []).map((variety) => (
                            <MenuItem key={variety.code} value={variety.code}>
                              {`${variety.code} (${variety.name})`}
                            </MenuItem>
                          ))}
                        </Select>
                      </td>
                      <td>
                        <IconButton color="secondary" onClick={() => handleRemoveLine(index)}>
                          <IconDelete />
                        </IconButton>
                      </td>
                    </tr>
                  );
                })}
                <tr>
                  <td style={{ width: "100px", fontWeight: "bold", fontSize: "16px" }}>TOTALS</td>
                  <td style={{ width: "300px", fontWeight: "bold", fontSize: "16px" }}></td>
                  <td style={{ width: "100px", fontWeight: "bold", fontSize: "16px" }}>{numeral(totals.usd).format("0.00000")}</td>
                  <td style={{ width: "100px", fontWeight: "bold", fontSize: "16px" }}>{numeral(totals.eur).format("0.00000")}</td>
                  <td style={{ width: "100px", fontWeight: "bold", fontSize: "16px" }}>{numeral(totals.gbp).format("0.00000")}</td>
                  <td style={{ width: "100px", fontWeight: "bold", fontSize: "16px" }}>{numeral(totals.cad).format("0.00000")}</td>
                  <td style={{ width: "100px", fontWeight: "bold", fontSize: "16px" }}>{numeral(totals.value).format("0.00000")}</td>
                  <td style={{ width: "300px", fontWeight: "bold", fontSize: "16px" }}></td>
                  <td></td>
                </tr>
              </tbody>
            </table>
            <p style={{ color: "red" }}>{isDuplicate ? "A record with this data already exists. Please make the necessary changes." : ""}</p>
            <div style={{ paddingTop: "5px", float: "left" }}>
              <Button variant="contained" color="primary" style={{ margin: "2px", width: "100px" }} onClick={() => handleAddLine()}>
                add line
              </Button>
              <Button variant="contained" color="secondary" style={{ margin: "2px", width: "100px" }} onClick={() => cancel()}>
                cancel
              </Button>
              <Button variant="contained" color="primary" style={{ margin: "2px", width: "100px" }} onClick={() => handleSave()}>
                save
              </Button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
