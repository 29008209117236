import { image as logoImage } from "./elements/logo";

import { Reports } from "../lib/types";

import { PTSans } from "./elements/font";
import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";
import moment from "moment";
import numeral from "numeral";

import { DealType } from "../lib/api/dealtype";
import { get } from "./helpers";
import BigNumber from "bignumber.js";

let pdf;
let pages;
let page;
let fromTop;
let fromLeft;
let lastLinewritten = 0;
let currentLine;
let startSummaryLine = { startPos: 0, endPos: 0 };
let startBankingLine = { startPos: 0, endPos: 0 };

const initialisePDF = () => {
  pdf = new jsPDF({
    orientation: "portrait",
    unit: "cm",
  });
  fullWidth = pdf.internal.pageSize.getWidth();
  fullHeight = pdf.internal.pageSize.getHeight();
  page = 1;
  pages = [page];
  fromTop = 0;
  fromLeft = 1;
};

let fullWidth;
let fullHeight;

const setPage = (page: number) => {
  pdf.setPage(page);
};

const newPage = () => {
  pdf.addPage();

  page++;
  pages.push(page);
  pdf.setPage(page);
};

const addImage = (imageData: string, format: string, fromLeft: number, fromTop: number, width: number, height: number, alias?, compression?, rotation?) => {
  pdf.addImage(imageData, format, fromLeft, fromTop, width, height, alias, compression, rotation);
};

const newLine = (howFar: number = 0.5) => {
  fromTop = fromTop + howFar;
};

const setFontBold = () => {
  pdf.setFontStyle("bold");
  pdf.setFont("helvetica"); // set font
};

const setFontNormal = () => {
  pdf.setFontStyle("normal");
  pdf.setFont("PTSans"); // set font
};

let reportType: Reports;

const invoiceHeaders = [
  { dataKey: "barcode_commodityname", header: "Commodity", width: 2, alignment: "left" },
  { dataKey: "barcode_varietyname", header: "Variety", width: 3.5, alignment: "left" },
  { dataKey: "barcode_gradecode", header: "Grade", width: 1.2, alignment: "center" },
  { dataKey: "barcode_countcode", header: "Count", width: 1.2, alignment: "center" },
  { dataKey: "barcode_markcode", header: "Mark", width: 1.2, alignment: "center" },
  { dataKey: "barcode_packcode", header: "Pack", width: 1.2, alignment: "center" },
  { dataKey: "barcode_nocartons", header: "Crtns", width: 1.2, alignment: "center" },
  { dataKey: "barcode_palletsize", header: "Plts", width: 1.2, alignment: "center" },
  { dataKey: "currency", header: "Curr", width: 1.2, alignment: "center" },
  { dataKey: "stockdetail_sellingprice", header: "Unit Price", width: 2, alignment: "right" },
  { dataKey: "total", header: "Subtotal", width: 2.5, alignment: "right" },
];

const packingListHeaders = [
  { dataKey: "barcode_commodityname", header: "Commodity", width: 1.8, alignment: "left" },
  { dataKey: "barcode_varietyname", header: "Variety", width: 3, alignment: "left" },
  { dataKey: "barcode_gradecode", header: "Grade", width: 0.9, alignment: "center" },
  { dataKey: "barcode_countcode", header: "Count", width: 0.9, alignment: "center" },
  { dataKey: "barcode_markcode", header: "Mark", width: 1, alignment: "center" },
  { dataKey: "barcode_packcode", header: "Pack", width: 1, alignment: "center" },
  { dataKey: "barcode_inventorycode", header: "Inv", width: 0.6, alignment: "center" },
  { dataKey: "barcode_farm", header: "PUC", width: 1.1, alignment: "center" },
  { dataKey: "barcode_phc", header: "PHC", width: 1.1, alignment: "center" },
  { dataKey: "weight", header: "Nett W", width: 1.3, alignment: "center" },
  { dataKey: "grossWeight", header: "Grs W", width: 1.3, alignment: "center" },
  { dataKey: "barcode_nocartons", header: "Crtns", width: 0.9, alignment: "center" },
  { dataKey: "barcode_palletsize", header: "Plts", width: 1, alignment: "center" },
  { dataKey: "loadoutdetail_barcode", header: "Barcode", width: 1.8, alignment: "left" },
];

const getHeaders = () => {
  if (
    [
      Reports.Invoice,
      Reports.ConsigneeInvoice,
      Reports.ClientAndConsigneeInvoice,
      Reports.CreditNote,
      Reports.DebitNote,
      Reports.CreditNoteConsignee,
      Reports.DebitNoteConsignee,
      Reports.CreditNoteClientAndConsignee,
      Reports.DebitNoteClientAndConsignee,
    ].includes(reportType)
  ) {
    return invoiceHeaders;
  }

  if ([Reports.PackingListPDF, Reports.PackingListConsigneePDF, Reports.PackingListClientAndConsigneePDF].includes(reportType)) {
    return packingListHeaders;
  }
  return undefined;
};

const addressLineArray = (address: string, charLength = 33, arr = [], upperCase?: boolean) => {
  const lineLength = charLength;

  if (!address || address == "") {
    return [];
  }

  address = upperCase ? address.toUpperCase() : address;
  // remove new lines
  address = address.replace(/(\r\n|\n|\r)/gm, " ");

  let words = address.split(" ");

  if (address.length > lineLength) {
    let line = "";
    let rem = address.split(" ");

    for (let i = 0; i < words.length; i++) {
      const currLine = line + " " + words[i];

      if (currLine.length > lineLength) {
        break;
      } else {
        rem.splice(0, 1);
        line = currLine.trim();
      }
    }

    arr.push(line);

    if (rem.length > 0) {
      return addressLineArray(rem.join(" "), lineLength, arr);
    } else {
      return arr;
    }
  } else {
    return [...arr, words.join(" ")];
  }
};

export const PrintInvoice = async (report: Reports, dataSet, payments, isProforma: boolean) => {
  const data = JSON.parse(JSON.stringify(dataSet));
  const details = data.details;
  const summary = data.summary;
  const isUndeclared = isProforma && Reports.ClientAndConsigneeInvoice === report;

  reportType = report;
  initialisePDF();

  pdf.addFileToVFS("PTSans.ttf", PTSans);
  pdf.addFont("PTSans.ttf", "PTSans", "normal");
  pdf.setFont("PTSans");
  pdf.setLineWidth(0.01);

  const isDebitCreditNote = reportType.startsWith("CREDIT_NOTE") || reportType.startsWith("DEBIT_NOTE");
  const headers = getHeaders();

  printHeader(data.companyDetails.company, data.headerFooter, data.companyDetails.bank, reportType, data.is_dispatch, isProforma);

  if (
    [
      Reports.Invoice,
      Reports.PackingListPDF,
      Reports.ConsigneeInvoice,
      Reports.PackingListConsigneePDF,
      Reports.ClientAndConsigneeInvoice,
      Reports.PackingListClientAndConsigneePDF,
    ].includes(reportType) ||
    isDebitCreditNote
  ) {
    printDetail(details, headers, isUndeclared);
  }

  if ([Reports.PackingListPDF, Reports.PackingListConsigneePDF, Reports.PackingListClientAndConsigneePDF].includes(reportType)) {
    printPackingListSummary(summary, headers);
  }

  if (
    [
      Reports.Invoice,
      Reports.PackingListPDF,
      Reports.ConsigneeInvoice,
      Reports.PackingListConsigneePDF,
      Reports.ClientAndConsigneeInvoice,
      Reports.PackingListClientAndConsigneePDF,
    ].includes(reportType) ||
    isDebitCreditNote
  ) {
    printRemarks(data.headerFooter);
  }

  if ([Reports.Invoice, Reports.ConsigneeInvoice, Reports.ClientAndConsigneeInvoice].includes(reportType)) {
    printInvoicingSummary(details, payments, data.headerFooter, isUndeclared);
  }

  if (
    [Reports.DebitNote, Reports.DebitNoteConsignee, Reports.DebitNoteClientAndConsignee, Reports.Invoice, Reports.ConsigneeInvoice, Reports.ClientAndConsigneeInvoice].includes(
      reportType,
    )
  ) {
    printBankDetails(data.companyDetails.bank);
  }

  if ([Reports.Invoice, Reports.ConsigneeInvoice, Reports.ClientAndConsigneeInvoice].includes(reportType)) {
    drawSummaryBox();
  }

  if (![Reports.PackingListPDF, Reports.PackingListConsigneePDF, Reports.PackingListClientAndConsigneePDF].includes(reportType)) {
    printTermsConditions();
  }

  printFooter();

  let reportName = reportType.replaceAll("_", " ").replaceAll(isProforma ? "COMMERCIAL" : "PROFORMA", "PROFORMA");

  try {
    const { invoicenumber, forwardagentref } = data.headerFooter;
    reportName = `${invoicenumber} ${forwardagentref ? `${forwardagentref} ` : ""}${reportName}.pdf`;
  } catch (error) {
    throw error;
  }

  pdf.save(reportName);
};

const drawSummaryBox = () => {
  pdf.setLineWidth(0.01);

  if (startSummaryLine.startPos > startBankingLine.startPos) {
    pdf.rect(1, startSummaryLine.startPos, fullWidth - 2, startSummaryLine.endPos + 0.5, "S");
    pdf.rect(1, startBankingLine.startPos + 1, fullWidth - 2, startBankingLine.endPos - 0.5, "S");
  } else {
    pdf.rect(1, startSummaryLine.startPos, fullWidth - 2, startSummaryLine.endPos + startBankingLine.endPos + 0.4, "S");
  }
};

const printRemarks = (headerData) => {
  const dispatch_remarks = headerData.dispatch_remarks ? headerData.dispatch_remarks.split("\n").join(" ") : "N/A";
  fromLeft = 3;
  newLine(0.75);
  pdf.setFontSize(9);

  setFontBold();
  pdf.text("REMARKS: ", fromLeft, fromTop + 0.5, "right");

  setFontNormal();
  dispatch_remarks.split("\n").map((remark) => {
    if (remark.toString().length < 140) {
      const text = remark.toString();
      pdf.text(fromLeft + 0.2, fromTop + 0.5, text, null, null, "left");
      fromTop = fromTop + 0.44;
    } else {
      let wrapped = pdf.splitTextToSize(remark.toString(), 28 - 12);
      wrapped.map((text) => {
        pdf.text(fromLeft + 0.2, fromTop + 0.5, text, null, null, "left");
        fromTop = fromTop + 0.44;
      });
    }
  });

  pdf.setFontSize(8);
  lastLinewritten = fromTop + 1;
};

const printFooter = () => {
  const pageCount = pdf.internal.getNumberOfPages();

  for (let index = 0; index < pageCount; index++) {
    pdf.setPage(index + 1);
    pdf.text(`Page ${index + 1} of ${pageCount}`, fullWidth - 1, fullHeight - 1, "right");
  }
};

const bankDetailNewLine = () => {
  fromLeft = 5.5;
  newLine();
};

const bankDetailPrintText = (text, position, fromLeftAdd) => {
  if (position === "right") {
    bankDetailNewLine();
    setFontBold();
    pdf.text(text, fromLeft + fromLeftAdd, fromTop, position);
  } else {
    setFontNormal();
    pdf.text(text, fromLeft + fromLeftAdd + 0.2, fromTop, position);
  }
};

const printBankDetails = (bankDetails) => {
  fromLeft = 1;
  if (lastLinewritten >= 24) {
    newPage();
    fromTop = 2;
  } else {
    fromTop = lastLinewritten;
  }

  startBankingLine = { startPos: lastLinewritten, endPos: 0 };

  fromTop += 0.5;

  pdf.setFontSize(10);
  newLine();
  setFontBold();
  pdf.text("BANKING DETAILS ", fromLeft + 0.25, fromTop, "left");
  pdf.rect(fromLeft + 0.25, fromTop + 0.1, pdf.getTextDimensions("BANKING DETAILS").w, 0.01, "S");

  fromTop += 0.25;
  const fromTopStart = fromTop;

  pdf.setFontSize(8);

  bankDetailPrintText("ACCOUNT HOLDER: ", "right", 0);
  bankDetailPrintText(!bankDetails || !bankDetails.accountholder ? "" : bankDetails.accountholder.toString(), "left", 0);

  bankDetailPrintText("ACCOUNT HOLDER ADDRESS: ", "right", 0);
  bankDetailPrintText(!bankDetails || !bankDetails.accountholderaddress ? "" : bankDetails.accountholderaddress.toString(), "left", 0);

  bankDetailPrintText("BANK: ", "right", 0);
  bankDetailPrintText(!bankDetails || !bankDetails.bank ? "" : bankDetails.bank.toString(), "left", 0);

  bankDetailPrintText("BANK ADDRESS: ", "right", 0);
  bankDetailPrintText(!bankDetails || !bankDetails.bankaddress ? "" : bankDetails.bankaddress.toString(), "left", 0);

  fromTop = fromTopStart;
  bankDetailPrintText("BRANCH CODE: ", "right", 11);
  bankDetailPrintText(!bankDetails || !bankDetails.branchcode ? "" : bankDetails.branchcode.toString(), "left", 11);

  bankDetailPrintText("ACCOUNT TYPE: ", "right", 11);
  bankDetailPrintText(!bankDetails || !bankDetails.accounttype ? "" : bankDetails.accounttype.toString(), "left", 11);

  bankDetailPrintText("ACCOUNT NUMBER: ", "right", 11);
  bankDetailPrintText(!bankDetails || !bankDetails.accountnumber ? "" : bankDetails.accountnumber.toString(), "left", 11);

  bankDetailPrintText("SWIFT CODE: ", "right", 11);
  bankDetailPrintText(!bankDetails || !bankDetails.swiftcode ? "" : bankDetails.swiftcode.toString(), "left", 11);

  fromLeft = 1;
  lastLinewritten = fromTop + 1.25;
  startBankingLine = { ...startBankingLine, endPos: fromTop - startBankingLine.startPos };

  if (reportType.startsWith("DEBIT_NOTE")) {
    pdf.rect(1, startBankingLine.startPos + 0.25, fullWidth - 2, startBankingLine.endPos + 0.25, "S");
  }
};

const printPackingListSummary = (data, headers) => {
  lastLinewritten += 1;

  const filteredHeaders = headers.reduce((arr, head) => {
    if (head.dataKey == "loadoutdetail_barcode") return arr;

    if (head.dataKey == "barcode_nocartons") {
      arr.push({ ...head, dataKey: "new_nocartons" });
    } else if (head.dataKey == "barcode_palletsize") {
      arr.push({ ...head, dataKey: "new_palletsize" });
    } else {
      arr.push(head);
    }

    return arr;
  }, []);

  const grouped = data.reduce((obj, curr) => {
    const key = `${curr["barcode_commoditycode"]}_${curr["barcode_varietycode"]}_${curr["barcode_packcode"]}_${curr["barcode_markcode"]}_${curr["barcode_gradecode"]}_${curr["barcode_countcode"]}_${curr["barcode_inventorycode"]}_${curr["barcode_farm"]}`;
    obj[key] = {
      ...curr,
      new_nocartons: new BigNumber((obj[key] || { new_nocartons: 0 }).new_nocartons).plus(new BigNumber(curr.barcode_nocartons)).toNumber(),
      new_palletsize: new BigNumber((obj[key] || { new_palletsize: 0 }).new_palletsize).plus(new BigNumber(curr.barcode_palletsize)).toNumber(),
      grossWeight: new BigNumber((obj[key] || { grossWeight: 0 }).grossWeight).plus(new BigNumber(curr.grossWeight)).toNumber(),
      weight: new BigNumber((obj[key] || { weight: 0 }).weight).plus(new BigNumber(curr.weight)).toNumber(),
    };
    return obj;
  }, {});

  const mapArrNew = Object.keys(grouped).map((key) => grouped[key]);

  pdf.setFontSize(11);
  setFontBold();
  pdf.text("SUMMARY", 1, lastLinewritten, "left");

  lastLinewritten += 0.5;

  const { totalPallets, totalCartons, totalWeight, totalGrossWeight } = mapArrNew.reduce(
    (obj, item) => {
      obj["totalPallets"] = parseFloat(obj["totalPallets"]) + parseFloat(item.new_palletsize);
      obj["totalCartons"] = parseFloat(obj["totalCartons"]) + parseFloat(item.new_nocartons);
      obj["totalWeight"] = parseFloat(obj["totalWeight"]) + parseFloat(item.weight);
      obj["totalGrossWeight"] = parseFloat(obj["totalGrossWeight"]) + parseFloat(item.grossWeight);
      return obj;
    },
    {
      totalPallets: 0,
      totalCartons: 0,
      totalWeight: 0,
      totalGrossWeight: 0,
    },
  );

  autoTable(pdf, {
    theme: "plain",
    margin: [1, 1, 1, 1],
    tableLineColor: [0, 0, 0],
    tableLineWidth: 0.01,
    startY: lastLinewritten,
    columns: filteredHeaders,
    body: mapArrNew.map((item) => ({
      ...item,
      grossWeight: numeral(item.grossWeight).format("0,0.00"),
      weight: numeral(item.weight).format("0,0.00"),
      new_nocartons: numeral(item.new_nocartons).format("0,0"),
      new_palletsize: numeral(item.new_palletsize).format("0,0.00"),
    })),
    showFoot: "lastPage",
    styles: { fontSize: 8, fillColor: [255, 255, 255], textColor: [0, 0, 0], cellPadding: [0.15, 0.025, 0.15, 0.025] },
    columnStyles: {
      barcode_commodityname: { halign: "center", cellWidth: "auto" },
      barcode_varietyname: { halign: "center", cellWidth: "auto", overflow: "ellipsize" },
      barcode_gradecode: { halign: "center" },
      barcode_countcode: { halign: "center" },
      barcode_markcode: { halign: "center" },
      barcode_packcode: { halign: "center" },
      barcode_inventorycode: { halign: "center" },
      barcode_farm: { halign: "center" },
      barcode_phc: { halign: "center" },
      weight: { halign: "center" },
      grossWeight: { halign: "center" },
      new_nocartons: { halign: "center" },
      new_palletsize: { halign: "center" },
    },
    headStyles: { fontStyle: "bold", cellPadding: [0.15, 0.025, 0.15, 0.025], halign: "center" },
    bodyStyles: { fontSize: 8 },
    alternateRowStyles: { fillColor: [255, 255, 255] },
    footStyles: { textColor: [0, 0, 0], fontStyle: "bold", halign: "center", cellPadding: [0.15, 0.025, 0.15, 0.025] },
    foot: [
      {
        barcode_commodityname: "TOTAL",
        barcode_varietyname: "",
        barcode_gradecode: "",
        barcode_countcode: "",
        barcode_markcode: "",
        barcode_packcode: "",
        barcode_inventorycode: "",
        barcode_farm: "",
        barcode_phc: "",
        weight: numeral(totalWeight).format("0,0.00"),
        grossWeight: numeral(totalGrossWeight).format("0,0.00"),
        new_nocartons: numeral(totalCartons).format("0,0"),
        new_palletsize: numeral(totalPallets).format("0,0.00"),
      },
    ],
    didDrawPage: (data) => {
      lastLinewritten = data.cursor.y;
      fromTop = data.cursor.y;
    },
    willDrawCell: (data) => {
      data.row.height = 0.5;
    },
  });

  pdf.setFillColor("#000");
  pdf.setLineWidth(0.001);
  pdf.rect(1, lastLinewritten - 0.45, fullWidth - 2, 0.001, "S");
};

const printInvoicingSummary = (data, payments, headerDetail, isUndeclared: boolean) => {
  if (lastLinewritten >= fullHeight - 4) {
    newPage();
    fromTop = 1;
  } else {
    fromTop = lastLinewritten;
  }

  startSummaryLine = { startPos: fromTop, endPos: 0 };

  let paymentTermsPrintPoint = 1.35;
  let incoTermPrintPoint = 6;

  const isDebitCreditNote = reportType.startsWith("CREDIT_NOTE") || reportType.startsWith("DEBIT_NOTE");

  let dueDatePrintPoint = isDebitCreditNote ? 12 : 13;
  let amountPrintPoint = isDebitCreditNote ? 17.8 : 19.8;
  let paidPrintPoint = 19.8;
  let currencyPrintPoint = amountPrintPoint - 1 - pdf.getTextDimensions("AMOUNT").w;

  lastLinewritten = fromTop;

  if (lastLinewritten >= 24) {
    newPage();
    pdf.setLineWidth(0.01);
    fromTop = 2;
  } else {
    fromTop += 0.8;
  }

  setFontBold();

  pdf.text("PAYMENT TERMS:", paymentTermsPrintPoint, fromTop, "left");
  pdf.rect(paymentTermsPrintPoint, fromTop + 0.1, pdf.getTextDimensions("PAYMENT TERMS: ").w, 0.01, "S");
  pdf.text("", 0.5 + pdf.getTextDimensions("PAYMENT TERMS: ").w, fromTop, "left");

  pdf.text("SHIPPING TERM:", incoTermPrintPoint, fromTop, "left");
  pdf.rect(incoTermPrintPoint, fromTop + 0.1, pdf.getTextDimensions("SHIPPING TERM: ").w, 0.01, "S");

  pdf.text(DealType[headerDetail.client_dealtype] || "", incoTermPrintPoint + 0.3 + pdf.getTextDimensions("SHIPPING TERM: ").w, fromTop, "left");

  pdf.text("DUE DATE", dueDatePrintPoint, fromTop, "left");
  pdf.rect(dueDatePrintPoint, fromTop + 0.1, pdf.getTextDimensions("DUE DATE").w, 0.01, "S");

  pdf.text("CURRENCY", currencyPrintPoint, fromTop, "right");
  pdf.rect(currencyPrintPoint - pdf.getTextDimensions("CURRENCY").w, fromTop + 0.1, pdf.getTextDimensions("CURRENCY").w, 0.01, "S");

  if (isDebitCreditNote) {
    pdf.text("PAID", paidPrintPoint, fromTop, "right");
    pdf.rect(paidPrintPoint - pdf.getTextDimensions("PAID").w, fromTop + 0.1, pdf.getTextDimensions("PAID").w, 0.01, "S");
  }

  pdf.text("AMOUNT", amountPrintPoint, fromTop, "right");
  pdf.rect(amountPrintPoint - pdf.getTextDimensions("AMOUNT").w, fromTop + 0.1, pdf.getTextDimensions("AMOUNT").w, 0.01, "S");

  setFontNormal();

  // PAYMENT TERM DESCRIPTION
  const firstPayment = payments[0] || { payment: "" };
  pdf.text(get("payment", firstPayment, ""), paymentTermsPrintPoint, fromTop + 0.55, "left");

  if ([Reports.Invoice, Reports.ConsigneeInvoice, Reports.ClientAndConsigneeInvoice].includes(reportType) || isDebitCreditNote) {
    fromTop += 0.8;
    let boxLine = fromTop;

    [0, 1, 2].map((i) => {
      if (!payments || !payments[i]) return false;

      if (i % 2 == 0) {
        pdf.setFillColor(210);
        pdf.rect(1, boxLine, fullWidth - 2, 0.5, "F");
      }
      boxLine += 0.5;

      const payment = payments[i];

      setFontBold();
      pdf.text(get("paymentPeriod", payment, ""), paymentTermsPrintPoint, fromTop + 0.35, "left");
      setFontNormal();

      if (isDebitCreditNote) {
        pdf.text(get("paid", payment, ""), paidPrintPoint, fromTop + 0.35, "right");
      }
      pdf.text(get("duedate", payment, ""), dueDatePrintPoint + pdf.getTextDimensions("DUE DATE").w / 2, fromTop + 0.35, "center");
      pdf.text(get("currency", payment, ""), currencyPrintPoint, fromTop + 0.35, "right");

      const amount = numeral(get("amount", payment, "")).format("0,0.00").toString();
      const amountText = isUndeclared ? "Undeclared" : amount;
      pdf.text(amountText, amountPrintPoint, fromTop + 0.35, "right");

      fromTop += 0.5;
    });

    fromTop += 0.5;

    pdf.setLineWidth(0.01);
    pdf.rect(1, fromTop + 0.15, fullWidth - 2, 0, "S");

    fromTop += 0.5;
    setFontBold();
    pdf.text("TOTAL", paymentTermsPrintPoint, fromTop, "left");

    const sumTotal = sumInt(payments, "amount");
    const totalText = isUndeclared ? "Undeclared" : sumTotal.toString();
    pdf.text(totalText, 19.8, fromTop, "right");

    pdf.rect(1, fromTop + 0.2, fullWidth - 2, 0, "S");

    lastLinewritten = fromTop;
    startSummaryLine = { ...startSummaryLine, endPos: lastLinewritten - startSummaryLine.startPos };
  }
};

const printDetail = (data, headers, isUndeclared: boolean) => {
  fromLeft = 1;

  const startRect = fromTop + 0.25;

  const formattedData = data.map((item) => ({
    ...item,
    barcode_palletsize: numeral(item.barcode_palletsize).format("0,0.00"),
    grossWeight: numeral(item.grossWeight).format("0,0.00"),
    weight: numeral(item.weight).format("0,0.00"),
    barcode_nocartons: numeral(item.barcode_nocartons).format("0,0"),
    stockdetail_sellingprice: isUndeclared ? "Undeclared" : numeral(item.stockdetail_sellingprice).format("0,0.00"),
    total: isUndeclared ? "Undeclared" : numeral(item.total).format("0,0.00"),
  }));

  const { totalPallets, totalCartons, totalWeight, totalGrossWeight, total } = data.reduce(
    (obj, item) => {
      obj["totalPallets"] = parseFloat(obj["totalPallets"]) + parseFloat(item.barcode_palletsize);
      obj["totalCartons"] = parseFloat(obj["totalCartons"]) + parseFloat(item.barcode_nocartons);
      obj["totalWeight"] = parseFloat(obj["totalWeight"]) + parseFloat(item.weight);
      obj["totalGrossWeight"] = parseFloat(obj["totalGrossWeight"]) + parseFloat(item.grossWeight);
      obj["total"] = parseFloat(obj["total"]) + parseFloat(item.total);
      return obj;
    },
    {
      totalPallets: 0,
      totalCartons: 0,
      totalWeight: 0,
      totalGrossWeight: 0,
      total: 0,
    },
  );

  autoTable(pdf, {
    theme: "plain",
    margin: [1, 1, 1, 1],
    tableLineColor: [0, 0, 0],
    tableLineWidth: 0.01,
    startY: startRect,
    columns: headers,
    body: formattedData,
    showFoot: "lastPage",
    styles: { fontSize: 8, fillColor: [255, 255, 255], textColor: [0, 0, 0], cellPadding: [0.15, 0.025, 0.15, 0.025] },
    columnStyles: {
      barcode_commodityname: { halign: "center", cellWidth: "auto" },
      barcode_varietyname: { halign: "center", cellWidth: "auto", overflow: "ellipsize" },
      barcode_nocartons: { halign: "center", cellWidth: "auto" },
      barcode_palletsize: { halign: "center", cellWidth: "auto" },
      barcode_gradecode: { halign: "center", cellWidth: "auto" },
      barcode_countcode: { halign: "center", cellWidth: "auto" },
      barcode_markcode: { halign: "center", cellWidth: "auto" },
      barcode_packcode: { halign: "center", cellWidth: "auto" },
      currency: { halign: "center", cellWidth: "auto" },
      barcode_inventorycode: { halign: "center", cellWidth: "auto" },
      barcode_farm: { halign: "center", cellWidth: "auto" },
      barcode_phc: { halign: "center", cellWidth: "auto" },
      weight: { halign: "center", cellWidth: "auto" },
      grossWeight: { halign: "center", cellWidth: "auto" },
      loadoutdetail_barcode: { halign: "center", cellWidth: "auto" },
      stockdetail_sellingprice: { halign: "center", cellWidth: "auto" },
      total: { halign: "center", cellWidth: "auto" },
    },
    headStyles: { fontStyle: "bold", cellPadding: [0.15, 0.025, 0.15, 0.025], halign: "center" },
    bodyStyles: { fontSize: 8 },
    alternateRowStyles: { fillColor: [255, 255, 255] },
    footStyles: { textColor: [0, 0, 0], fontStyle: "bold", halign: "center", cellPadding: [0.15, 0.025, 0.15, 0.025] },
    foot: [
      {
        barcode_commodityname: "TOTAL",
        barcode_varietyname: "",
        barcode_gradecode: "",
        barcode_countcode: "",
        barcode_markcode: "",
        barcode_packcode: "",
        barcode_inventorycode: "",
        barcode_farm: "",
        barcode_phc: "",
        stockdetail_sellingprice: "",
        weight: numeral(totalWeight).format("0,0.00"),
        grossWeight: numeral(totalGrossWeight).format("0,0.00"),
        barcode_nocartons: numeral(totalCartons).format("0,0"),
        barcode_palletsize: numeral(totalPallets).format("0,0.00"),
        loadoutdetail_barcode: "",
        total: isUndeclared ? "Undeclared" : numeral(total).format("0,0.00"),
      },
    ],
    didDrawPage: (data) => {
      lastLinewritten = data.cursor.y;
      fromTop = data.cursor.y;
    },
    willDrawCell: (data) => {
      data.row.height = 0.5;
    },
  });

  pdf.setFillColor("#000");
  pdf.setLineWidth(0.001);
  pdf.rect(1, lastLinewritten - 0.45, fullWidth - 2, 0.001, "S");
};

const sumInt = (items, prop, decimalValueReturn = true) => {
  const result = items.reduce((acc, curr) => acc + curr[prop], 0);
  return numeral(result).format(decimalValueReturn ? "0,0.00" : "0,0");
};

const headerColumn1 = () => {
  return 3.4;
};
const headerColumn2 = () => {
  return fullWidth / 2 - 1.5;
};
const headerColumn3 = () => {
  return fullWidth - 3.6;
};

const printLine = (left: number, top: number, label: string, value: string, boldValue = false) => {
  setFontBold();
  pdf.text(label, left, top, "right");
  if (!boldValue) {
    setFontNormal();
  }
  pdf.text(value, left + 0.2, top, "left");
};

const printHeader = (companyDetails, header, bankDetails, reportType, is_dispatch: boolean = false, isProforma: boolean) => {
  // fromTop = 1.5;
  fromTop = 1;
  fromLeft = 1;

  const isConsigneeReport = reportType.includes("CONSIGNEE") && !reportType.includes("CLIENT");
  const isClientAndConsigneeReport = reportType.includes("CLIENT_AND_CONSIGNEE");
  const isDebitCreditNoteReport = reportType.startsWith("CREDIT_NOTE") || reportType.startsWith("DEBIT_NOTE");
  const isPackingListReport = reportType.startsWith("PACKING_LIST");

  const clientAddress = isConsigneeReport ? get("consignee_address", header, "").replace(/[\r\n]/g, " ") : get("clientaddress", header, "").replace(/[\r\n]/g, " ");
  const clientAddressArr = addressLineArray(clientAddress);

  const shippedToAddress = get("consignee_address", header, "").replace(/[\r\n]/g, " ");
  const shippedToAddressArr = addressLineArray(shippedToAddress);

  const imageWidth = 5;
  const imageHeight = 1.5;
  // let fromRight = fullWidth - 0.7 - pdf.getTextDimensions(`Customs Code: ${!companyDetails ? "" : companyDetails.customscode ? companyDetails.customscode : ""}`).w / (96 / 38);

  addImage(logoImage, "PNG", (fullWidth - imageWidth) / 2 - 1, fromTop, imageWidth, imageHeight);
  pdf.setFontSize(11);
  setFontBold();
  pdf.text(get("name", companyDetails, ""), fromLeft, fromTop, "left");

  pdf.setFontSize(12);
  setFontBold();
  pdf.text(
    `${isProforma ? "PROFORMA INVOICE" : reportType.toString().replace("CLIENT_AND_CONSIGNEE", "").replaceAll("_", " ").replace("PDF", "").replace("CONSIGNEE", "")}`,
    fullWidth / 2 - 1,
    fromTop + 2.5,
    "center",
  );

  setFontNormal();
  pdf.setFontSize(8);

  newLine(0.4);
  pdf.text(get("address1", companyDetails, ""), fromLeft, fromTop, "left");

  newLine(0.4);
  pdf.text(get("address2", companyDetails, ""), fromLeft, fromTop, "left");

  newLine(0.4);
  pdf.text(get("address3", companyDetails, ""), fromLeft, fromTop, "left");

  newLine(0.4);
  pdf.text(get("address4", companyDetails, ""), fromLeft, fromTop, "left");

  newLine(0.4);
  pdf.text(get("address5", companyDetails, ""), fromLeft, fromTop, "left");

  fromLeft = headerColumn3() - 1;

  fromTop = 1;

  pdf.text("Tel No:", fromLeft, fromTop, "right");
  pdf.text(get("telephoneNumber", companyDetails, ""), fromLeft + 0.2, fromTop, "left");

  newLine(0.4);
  pdf.text("Fax No:", fromLeft, fromTop, "right");
  pdf.text(get("faxNumber", companyDetails, ""), fromLeft + 0.2, fromTop, "left");

  newLine(0.4);
  pdf.text("E-mail:", fromLeft, fromTop, "right");
  pdf.text(get("email", companyDetails, ""), fromLeft + 0.2, fromTop, "left");

  newLine(0.4);
  pdf.text("Vat No:", fromLeft, fromTop, "right");
  pdf.text(get("vatno", companyDetails, ""), fromLeft + 0.2, fromTop, "left");

  newLine(0.4);
  pdf.text("Reg No:", fromLeft, fromTop, "right");
  pdf.text(get("regno", companyDetails, ""), fromLeft + 0.2, fromTop, "left");

  newLine(0.4);
  pdf.text("Customs Code:", fromLeft, fromTop, "right");
  pdf.text(get("customscode", companyDetails, ""), fromLeft + 0.2, fromTop, "left");

  newLine(0.4);
  pdf.text("GGN / GLN No:", fromLeft, fromTop, "right");
  pdf.text(get("ggn_gln_no", companyDetails, ""), fromLeft + 0.2, fromTop, "left");

  const startRect = fromTop + 0.5;
  newLine(0.7);
  pdf.setFontSize(8);

  const shippedTo = get("consignee_name", header, "");
  const issuedTo = isConsigneeReport ? get("consignee_name", header, "") : get("clientname", header, "");

  //1
  newLine();
  printLine(headerColumn1(), fromTop, "Date:", moment(is_dispatch ? get("dispatch_date", header, "") : get("sale_date", header, "")).format("DD/MM/YYYY"));
  if (!isProforma) {
    printLine(headerColumn2(), fromTop, "UCR:", get("ucrno", header, ""));
  }
  printLine(headerColumn3() - 1, fromTop, "Agent Ref:", get("forwardagentref", header, ""));

  //2
  newLine(0.4);
  printLine(headerColumn1(), fromTop, "Load Ref:", get("loadout_reference", header, ""));
  if (!isProforma) {
    printLine(headerColumn2(), fromTop, "Temp Recorder:", get("temptail", header, ""));
  }
  printLine(headerColumn3() - 1, fromTop, "Vessel:", get("vessel_description", header, ""));

  //3
  newLine(0.4);
  printLine(
    headerColumn1(),
    fromTop,
    `${isDebitCreditNoteReport ? "Orig " : ""}Invoice No:`,
    `${isProforma ? moment(get("sale_date", header, "")).format("DD/MM/YYYY") : ""}${get("invoicenumber", header, "")}`,
  );
  if (!isProforma) {
    printLine(headerColumn2(), fromTop, "Recorder Position:", get("recorder_position", header, ""));
  }
  printLine(
    headerColumn3() - 1,
    fromTop,
    `${
      [
        Reports.Invoice,
        Reports.ConsigneeInvoice,
        Reports.PackingListConsigneePDF,
        Reports.PackingListPDF,
        Reports.PackingListClientAndConsigneePDF,
        Reports.ClientAndConsigneeInvoice,
      ].includes(reportType) || isDebitCreditNoteReport
        ? "Voyage Number"
        : "Shipping Ref"
    }:`,
    get("shippingRef", header, ""),
  );

  let issuedToAddressLineTop = fromTop + 0.8;
  let shippedToAddressLineTop = fromTop + 0.8;

  const maxNameLength = isClientAndConsigneeReport ? 20 : 80;

  const issuedToArr = addressLineArray(issuedTo, maxNameLength, undefined, true).map((item, i) => ({ bold: true, label: i > 0 ? "" : "Issued To:", value: item }));
  const shippedToArr = addressLineArray(shippedTo, maxNameLength, undefined, true).map((item, i) => ({ bold: true, label: i > 0 ? "" : "Shipped To:", value: item }));

  const issueToAddress = (clientAddressArr || []).map((str) => ({ bold: false, label: "", value: str }));
  const middleContentAddress = (shippedToAddressArr || []).map((str) => ({ bold: false, label: "", value: str }));

  /* ISSUED TO */
  const issuedToExtraCnt = isClientAndConsigneeReport ? addressLineArray(shippedTo, maxNameLength).length - addressLineArray(issuedTo, maxNameLength).length : 0;
  const issuedToExtraArr = issuedToExtraCnt > 0 ? new Array(issuedToExtraCnt).fill(1).map(() => ({ bold: false, label: "", value: "" })) : [];

  const issuedToExtraAddCnt = isClientAndConsigneeReport ? middleContentAddress.length - issueToAddress.length : 0;
  const issuedToExtraAddArr = issuedToExtraAddCnt > 0 ? new Array(issuedToExtraAddCnt).fill(1).map(() => ({ bold: false, label: "", value: "" })) : [];

  issuedToArr.push(
    ...issuedToExtraArr,
    ...issueToAddress,
    ...issuedToExtraAddArr,
    { bold: false, label: "Tel No:", value: get("clientstelephone", header, "") },
    { bold: false, label: "Email:", value: get("clientsemail", header, "") },
  );

  for (let i = 0; i < issuedToArr.length; i++) {
    const row = issuedToArr[i];
    printLine(headerColumn1() - 0.05, issuedToAddressLineTop, row.label, row.value, row.bold);
    issuedToAddressLineTop += 0.4;
  }

  /* SHIPPED TO */
  if (isClientAndConsigneeReport) {
    const shippedToExtraCnt = addressLineArray(issuedTo, maxNameLength).length - addressLineArray(shippedTo, maxNameLength).length;
    const shippedToExtraArr = shippedToExtraCnt > 0 ? new Array(shippedToExtraCnt).fill(1).map(() => ({ bold: false, label: "", value: "" })) : [];

    const shippedToExtraAddCnt = issueToAddress.length - middleContentAddress.length;
    const shippedToExtraAddArr = shippedToExtraAddCnt > 0 ? new Array(shippedToExtraAddCnt).fill(1).map(() => ({ bold: false, label: "", value: "" })) : [];

    shippedToArr.push(
      ...shippedToExtraArr,
      ...middleContentAddress,
      ...shippedToExtraAddArr,
      { bold: false, label: "Tel No:", value: get("consignee_telephone", header, "") },
      { bold: false, label: "Email:", value: get("consignee_email", header, "") },
    );

    for (let i = 0; i < shippedToArr.length; i++) {
      const row = shippedToArr[i];
      printLine(headerColumn2() - 0.05, shippedToAddressLineTop, row.label, row.value, row.bold);
      shippedToAddressLineTop += 0.4;
    }
  }

  // 4
  newLine(0.4);
  if (isDebitCreditNoteReport) {
    if (reportType.startsWith("DEBIT_NOTE")) {
      printLine(headerColumn1(), fromTop, "Debit Note No:", get("saleadjustment_ident", header, ""));
    } else {
      printLine(headerColumn1(), fromTop, "Credit Note No:", get("saleadjustment_ident", header, ""));
    }
  } else {
    printLine(headerColumn1(), fromTop, "Client Order No:", get("orderno", header, ""));
  }
  printLine(headerColumn3() - 1, fromTop, "Container Number:", get("containerno", header, ""));

  // 5
  newLine(0.4);
  printLine(headerColumn3() - 1, fromTop, "Seal Number:", get("dispatch_sealnumber", header, ""));

  // 6
  newLine(0.4);
  printLine(headerColumn3() - 1, fromTop, "POL:", get("portloading", header, ""));

  // 7
  newLine(0.4);
  printLine(headerColumn3() - 1, fromTop, "POD:", get("portdischarge", header, ""));

  // 8
  newLine(0.4);
  printLine(headerColumn3() - 1, fromTop, "Final Destination:", get("portfinal", header, ""));

  // 9
  newLine(0.4);
  printLine(headerColumn3() - 1, fromTop, "ETD:", moment(get("etd", header, "")).format("DD/MM/YYYY"));

  // 10
  newLine(0.4);
  printLine(headerColumn3() - 1, fromTop, "ETA:", moment(get("eta", header, "")).format("DD/MM/YYYY"));

  // 11
  if (isPackingListReport) {
    newLine(0.4);
    const grossWeightFormatted = numeral(header.grossweight || "").format("0,0.00");
    printLine(headerColumn3() - 1, fromTop, "Gross Cargo Weight:", `${grossWeightFormatted} kg`);
    newLine(0.4);
    const nettWeightFormatted = numeral(header.nettweight || "").format("0,0.00");
    printLine(headerColumn3() - 1, fromTop, "Nett Cargo Weight:", `${nettWeightFormatted} kg`);
  }

  fromLeft = 1;
  fromTop =
    issuedToAddressLineTop > shippedToAddressLineTop
      ? issuedToAddressLineTop > fromTop
        ? issuedToAddressLineTop - 0.2
        : fromTop
      : shippedToAddressLineTop > fromTop
      ? shippedToAddressLineTop - 0.2
      : fromTop;

  pdf.setLineWidth(0.01);
  pdf.rect(fromLeft, startRect + 0.15, fullWidth - 2, fromTop - startRect, "S");

  fromTop += 0.5;
};

const printTermsConditions = () => {
  const heightOfPage = pdf.internal.pageSize.getHeight();
  fromLeft = 1.2;
  fromTop = lastLinewritten + 0.5;

  if (fromTop > heightOfPage - 3) {
    newPage();
    fromTop = 2;
  }

  // The rectangle around the text
  pdf.setLineWidth(0.01);
  pdf.rect(1, fromTop - 0.5, fullWidth - 2, 3.15, "S");

  pdf.setFontSize(9);

  pdf.text("The exporter of the products covered by this document [Customs Authorisation no: 01860115] declares that, except where otherwise", fromLeft, fromTop, "left");
  newLine(0.4);
  pdf.text("clearly indicated, these products are of South African preferential origin.", fromLeft, fromTop, "left");

  newLine(0.75);
  pdf.text("* Any quality deviation must be reported by e-mail within 48 hours of collecting the container.", fromLeft, fromTop, "left");
  newLine(0.4);
  pdf.text("* Claims will not be entertained if notice is given after 48 hours.", fromLeft, fromTop, "left");
  newLine(0.4);
  pdf.text("* Claims will only be entertained if the Impala Citrus claims procedure is adhered to, as set out in Trade Agreement.", fromLeft, fromTop, "left");
  newLine(0.4);
  pdf.text("* Interest at a rate of 2% per month will be charged on overdue invoices.", fromLeft, fromTop, "left");
};
