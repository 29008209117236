import { image as logoImage } from "./elements/logo";

import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";
import moment from "moment";
import numeral from "numeral";
import BigNumber from "bignumber.js";

import { PTSans } from "./elements/font";
import { get } from "./helpers";
import { Reports } from "../lib/types";
import { weightFormatter } from "./formatter";

const PAGE_BOTTOM_MARGIN = 2.5;

let pdf: jsPDF,
  pages: number[],
  page: number,
  fromTop: number,
  fromLeft: number,
  lastLinewritten: number = 0,
  fullWidth,
  fullHeight;

const initialisePDF = () => {
  pdf = new jsPDF({
    orientation: "landscape",
    unit: "cm",
  });
  fullWidth = pdf.internal.pageSize.getWidth();
  fullHeight = pdf.internal.pageSize.getHeight();
  page = 1;
  pages = [page];
  fromTop = 0;
  fromLeft = 1;
};

const newPage = () => {
  pdf.addPage();

  page++;
  pages.push(page);
  pdf.setPage(page);
};

const addImage = (imageData: string, format: string, fromLeft: number, fromTop: number, width: number, height: number, alias?, compression?, rotation?) => {
  pdf.addImage(imageData, format, fromLeft, fromTop, width, height, alias, compression, rotation);
};

const newLine = (howFar: number = 0.5) => {
  fromTop += howFar;
};

const setFontBold = () => {
  pdf.setFontStyle("bold");
  pdf.setFont("helvetica"); // set font
};

const setFontNormal = () => {
  pdf.setFontStyle("normal");
  pdf.setFont("PTSans"); // set font
};

let reportType: Reports;

const packingListHeaders = [
  { dataKey: "barcode_commodityname", header: "COMMODITY", width: 1.8, alignment: "left" },
  { dataKey: "barcode_varietyname", header: "VARIETY", width: 3, alignment: "left" },
  { dataKey: "barcode_gradecode", header: "GRADE", width: 0.9, alignment: "center" },
  { dataKey: "barcode_countcode", header: "COUNT", width: 0.9, alignment: "center" },
  { dataKey: "barcode_markcode", header: "MARK", width: 1, alignment: "center" },
  { dataKey: "barcode_packcode", header: "PACK", width: 1, alignment: "center" },
  { dataKey: "barcode_inventorycode", header: "INV", width: 0.6, alignment: "center" },
  { dataKey: "barcode_orchard", header: "ORCHARD", width: 0.6, alignment: "center" },
  { dataKey: "barcode_farm", header: "PUC", width: 1.1, alignment: "center" },
  { dataKey: "barcode_phc", header: "PHC", width: 1.1, alignment: "center" },
  { dataKey: "weight", header: "NETT WEIGHT", width: 1.3, alignment: "center" },
  { dataKey: "grossWeight", header: "GROSS WEIGHT", width: 1.3, alignment: "center" },
  { dataKey: "barcode_nocartons", header: "CARTONS", width: 0.9, alignment: "center" },
  { dataKey: "barcode_palletsize", header: "PALLETS", width: 1, alignment: "center" },
  { dataKey: "loadoutdetail_barcode", header: "BARCODE", width: 1.8, alignment: "left" },
];

const addressLineArray = (address: string, charLength = 33, arr = [], upperCase?: boolean) => {
  const lineLength = charLength;

  if (!address || address == "") {
    return [];
  }

  address = upperCase ? address.toUpperCase() : address;
  // remove new lines
  address = address.replace(/(\r\n|\n|\r)/gm, " ").trim();

  let words = address.split(" ");

  if (address.length > lineLength) {
    let line = "";
    let rem = address.split(" ");

    for (let i = 0; i < words.length; i++) {
      const currLine = line + " " + words[i];

      if (currLine.length > lineLength) {
        break;
      } else {
        rem.splice(0, 1);
        line = currLine.trim();
      }
    }

    arr.push(line);

    if (rem.length > 0) {
      return addressLineArray(rem.join(" "), lineLength, arr);
    } else {
      return arr;
    }
  } else {
    return [...arr, words.join(" ")];
  }
};

export const printPackingList = async (report: Reports, dataSet, isProforma: boolean) => {
  const data = dataSet;
  const details = data.details;
  const summary = data.summary;

  reportType = report;
  initialisePDF();

  pdf.addFileToVFS("PTSans.ttf", PTSans);
  pdf.addFont("PTSans.ttf", "PTSans", "normal");
  pdf.setFont("PTSans");
  pdf.setLineWidth(0.01);

  const headers = packingListHeaders;

  printHeader(data.companyDetails.company, reportType, isProforma);
  newLine();
  printDetailSummary(data.headerFooter, data.is_dispatch, isProforma);
  newLine();
  printDetail(details, headers, isProforma, data.companyDetails.company, reportType);
  newLine();
  printPackingListSummary(summary, headers, data.companyDetails.company, reportType, isProforma);
  newLine();
  printRemarks(data.headerFooter);
  newLine();
  printFooter();

  let filename = "PACKING_LIST.pdf";
  try {
    const { invoicenumber, forwardagentref } = data.headerFooter;
    filename = `${invoicenumber}_${forwardagentref ? `${forwardagentref}_` : ""}${isProforma ? `PROFORMA_${reportType}` : reportType}.pdf`;
  } catch (error) {
    throw error;
  }
  pdf.save(filename);
  // return pdf.output("datauristring");
};

const printRemarks = (headerData) => {
  const startRect = fromTop;
  const dispatch_remarks = headerData.dispatch_remarks ? headerData.dispatch_remarks.split("\n").join(" ") : "N/A";

  newLine();
  setFontBold();

  fromLeft = 3;

  pdf.setFontSize(9);
  pdf.text("REMARKS: ", fromLeft, fromTop, "right");
  pdf.line(1.25, fromTop + 0.05, 3, fromTop + 0.05, "S");

  setFontNormal();

  dispatch_remarks.split("\n").map((remark) => {
    if (remark.toString().length < 140) {
      const text = remark.toString();
      pdf.text(fromLeft + 0.2, fromTop, text, null, null, "left");
      fromTop = fromTop + 0.44;
    } else {
      let wrapped = pdf.splitTextToSize(remark.toString(), 28 - 12);
      wrapped.map((text) => {
        pdf.text(fromLeft + 0.2, fromTop, text, null, null, "left");
        fromTop = fromTop + 0.44;
      });
    }
  });

  pdf.setLineWidth(0.01);
  pdf.setDrawColor(0, 0, 0);
  pdf.rect(1, startRect, fullWidth - 2, fromTop - startRect, "S");
};

const printFooter = () => {
  const pageCount = pdf.internal.getNumberOfPages();

  for (let index = 0; index < pageCount; index++) {
    pdf.setPage(index + 1);
    pdf.text(`Page ${index + 1} of ${pageCount}`, fullWidth - 1, fullHeight - 1, "right");
  }
};

const printPackingListSummary = (data, headers, companyDetails, reportType, isProforma) => {
  const filteredHeaders = headers.reduce((arr, head) => {
    if (["loadoutdetail_barcode"].includes(head.dataKey)) return arr;

    if (head.dataKey == "barcode_nocartons") {
      arr.push({ ...head, dataKey: "new_nocartons" });
    } else if (head.dataKey == "barcode_palletsize") {
      arr.push({ ...head, dataKey: "new_palletsize" });
    } else {
      arr.push(head);
    }

    return arr;
  }, []);

  const grouped = data.reduce((obj, curr) => {
    const key = `${curr["barcode_commoditycode"]}_${curr["barcode_varietycode"]}_${curr["barcode_packcode"]}_${curr["barcode_markcode"]}_${curr["barcode_gradecode"]}_${curr["barcode_countcode"]}_${curr["barcode_inventorycode"]}_${curr["barcode_farm"]}_${curr["barcode_orchard"]}`;
    obj[key] = {
      ...curr,
      new_nocartons: new BigNumber((obj[key] || { new_nocartons: 0 }).new_nocartons).plus(new BigNumber(curr.barcode_nocartons)).toNumber(),
      new_palletsize: new BigNumber((obj[key] || { new_palletsize: 0 }).new_palletsize).plus(new BigNumber(curr.barcode_palletsize)).toNumber(),
      grossWeight: new BigNumber((obj[key] || { grossWeight: 0 }).grossWeight).plus(new BigNumber(curr.grossWeight)).toNumber(),
      weight: new BigNumber((obj[key] || { weight: 0 }).weight).plus(new BigNumber(curr.weight)).toNumber(),
    };
    return obj;
  }, {});

  const mapArrNew = Object.keys(grouped).map((key) => grouped[key]);
  let startRect = fromTop;

  newLine(0.75);

  // go to line 270
  const sectionHeaderTop = fromTop;

  newLine(0.25);

  const { totalPallets, totalCartons, totalWeight, totalGrossWeight } = mapArrNew.reduce(
    (obj, item) => {
      obj["totalPallets"] = parseFloat(obj["totalPallets"]) + parseFloat(item.new_palletsize);
      obj["totalCartons"] = parseFloat(obj["totalCartons"]) + parseFloat(item.new_nocartons);
      obj["totalWeight"] = parseFloat(obj["totalWeight"]) + parseFloat(item.weight);
      obj["totalGrossWeight"] = parseFloat(obj["totalGrossWeight"]) + parseFloat(item.grossWeight);
      return obj;
    },
    {
      totalPallets: 0,
      totalCartons: 0,
      totalWeight: 0,
      totalGrossWeight: 0,
    },
  );

  let remainingData = mapArrNew;

  while (remainingData.length > 0) {
    if (fromTop > fullHeight - PAGE_BOTTOM_MARGIN) {
      newPage();
      printHeader(companyDetails, reportType, isProforma);
      newLine();

      startRect = fromTop;
      newLine(0.75);

      pdf.setFontSize(11);
      setFontBold();
      pdf.text("SUMMARY:", 1.25, fromTop, "left");
      pdf.line(1.25, fromTop + 0.05, 3.5, fromTop + 0.05, "S");

      newLine(0.25);
    } else {
      // this is needed because the SUMMARY header is duplicated if the data on the detail fits on the first PDF page only
      pdf.setFontSize(11);
      setFontBold();
      pdf.text("SUMMARY:", 1.25, sectionHeaderTop, "left");
      pdf.line(1.25, sectionHeaderTop + 0.05, 3.5, sectionHeaderTop + 0.05, "S");

      pdf.setFontSize(8);
    }

    const remaining = pdf.internal.pageSize.getHeight() - fromTop - PAGE_BOTTOM_MARGIN;
    const chunk = remainingData.splice(0, Math.floor((remaining < 1 ? 1 : remaining) / 0.45));

    autoTable(pdf, {
      theme: "plain",
      margin: [1, 1, 1, 1],
      startY: fromTop,
      columns: filteredHeaders,
      body: chunk.map((item) => ({
        ...item,
        grossWeight: weightFormatter(item.grossWeight),
        weight: weightFormatter(item.weight),
        new_nocartons: numeral(item.new_nocartons).format("0,0"),
        new_palletsize: numeral(item.new_palletsize).format("0,0.00"),
      })),
      showFoot: remainingData.length == 0 ? "lastPage" : "never",
      styles: { fontSize: 8, textColor: [0, 0, 0], cellPadding: [0.15, 0.025, 0.15, 0.025] },
      columnStyles: {
        barcode_commodityname: { halign: "center", cellWidth: "auto" },
        barcode_varietyname: { halign: "center", cellWidth: "auto", overflow: "ellipsize" },
        barcode_gradecode: { halign: "center" },
        barcode_countcode: { halign: "center" },
        barcode_markcode: { halign: "center" },
        barcode_packcode: { halign: "center" },
        barcode_inventorycode: { halign: "center" },
        barcode_orchard: { halign: "center" },
        barcode_farm: { halign: "center" },
        barcode_phc: { halign: "center" },
        weight: { halign: "center" },
        grossWeight: { halign: "center" },
        new_nocartons: { halign: "center" },
        new_palletsize: { halign: "center" },
      },
      headStyles: { fontStyle: "bold", cellPadding: [0.15, 0.025, 0.15, 0.025], halign: "center" },
      bodyStyles: { fontSize: 8 },
      footStyles: { textColor: [0, 0, 0], fontStyle: "bold", halign: "center", cellPadding: [0.15, 0.025, 0.15, 0.025] },
      foot: [
        {
          barcode_commodityname: "TOTAL",
          barcode_varietyname: "",
          barcode_gradecode: "",
          barcode_countcode: "",
          barcode_markcode: "",
          barcode_packcode: "",
          barcode_inventorycode: "",
          barcode_orchard: "",
          barcode_farm: "",
          barcode_phc: "",
          weight: numeral(totalWeight).format("0,0.00"),
          grossWeight: numeral(totalGrossWeight).format("0,0.00"),
          new_nocartons: numeral(totalCartons).format("0,0"),
          new_palletsize: numeral(totalPallets).format("0,0.00"),
        },
      ],
      didDrawPage: (data) => {
        fromTop = data.cursor.y;

        // border around data
        pdf.setLineWidth(0.01);
        pdf.setDrawColor(0, 0, 0);
        pdf.rect(1, startRect, fullWidth - 2, fromTop - startRect, "S");
      },
      willDrawCell: (data) => {
        data.row.height = 0.5;

        // underline column name
        if (data.section === "head") {
          const { x, y, raw, width, height } = data.cell;

          pdf.setLineWidth(0.01);
          pdf.setDrawColor(0, 0, 0);
          pdf.line(
            x + (width / 2 - pdf.getTextDimensions(raw).w / 2),
            y + (height - 0.15),
            x + (width / 2 - pdf.getTextDimensions(raw).w / 2) + pdf.getTextDimensions(raw).w,
            y + (height - 0.15),
            "S",
          );
        }
      },
    });
  }

  // Line above total line
  pdf.setFillColor("#000");
  pdf.setLineWidth(0.001);
  pdf.rect(1, fromTop - 0.45, fullWidth - 2, 0.001, "S");
};

const printDetail = (data, headers, isProforma, companyDetails, reportType) => {
  fromLeft = 1;
  let startRect = fromTop;

  newLine(0.75);

  pdf.setFontSize(11);
  setFontBold();
  pdf.text("PALLET DETAILS:", 1.2, fromTop, "left");
  pdf.line(1.25, fromTop + 0.05, 4.65, fromTop + 0.05, "S");

  newLine(0.25);

  const formattedData = data.map((item) => ({
    ...item,
    barcode_palletsize: numeral(item.barcode_palletsize).format("0,0.00"),
    grossWeight: weightFormatter(item.grossWeight),
    weight: weightFormatter(item.weight),
    barcode_nocartons: numeral(item.barcode_nocartons).format("0,0"),
    stockdetail_sellingprice: isProforma && item.stockdetail_sellingprice == 0 ? "Undeclared" : numeral(item.stockdetail_sellingprice).format("0,0.00"),
    total: isProforma && item.total == 0 ? "Undeclared" : numeral(item.total).format("0,0.00"),
  }));

  const { totalPallets, totalCartons, totalWeight, totalGrossWeight, total } = data.reduce(
    (obj, item) => {
      obj["totalPallets"] = parseFloat(obj["totalPallets"]) + parseFloat(item.barcode_palletsize);
      obj["totalCartons"] = parseFloat(obj["totalCartons"]) + parseFloat(item.barcode_nocartons);
      obj["totalWeight"] = parseFloat(obj["totalWeight"]) + parseFloat(item.weight);
      obj["totalGrossWeight"] = parseFloat(obj["totalGrossWeight"]) + parseFloat(item.grossWeight);
      obj["total"] = parseFloat(obj["total"]) + parseFloat(item.total);
      return obj;
    },
    {
      totalPallets: 0,
      totalCartons: 0,
      totalWeight: 0,
      totalGrossWeight: 0,
      total: 0,
    },
  );

  let remainingData = formattedData;

  while (remainingData.length > 0) {
    if (fromTop > fullHeight - PAGE_BOTTOM_MARGIN) {
      newPage();
      printHeader(companyDetails, reportType, isProforma);
      newLine();

      startRect = fromTop;
      newLine(0.75);

      pdf.setFontSize(11);
      setFontBold();
      pdf.text("PALLET DETAILS:", 1.2, fromTop, "left");
      pdf.line(1.25, fromTop + 0.05, 4.65, fromTop + 0.05, "S");

      newLine(0.25);
    }

    const remaining = pdf.internal.pageSize.getHeight() - fromTop - PAGE_BOTTOM_MARGIN;
    const chunk = remainingData.splice(0, Math.floor((remaining < 1 ? 1 : remaining) / 0.5));

    autoTable(pdf, {
      theme: "plain",
      margin: [1, 1, 1, 1],
      pageBreak: "auto",
      startY: fromTop,
      columns: headers,
      body: chunk,
      showFoot: remainingData.length == 0 ? "lastPage" : "never",
      styles: { fontSize: 8, textColor: [0, 0, 0], cellPadding: [0.15, 0.025, 0.15, 0.025] },
      columnStyles: {
        barcode_commodityname: { halign: "center", cellWidth: "auto" },
        barcode_varietyname: { halign: "center", cellWidth: "auto", overflow: "ellipsize" },
        barcode_nocartons: { halign: "center", cellWidth: "auto" },
        barcode_palletsize: { halign: "center", cellWidth: "auto" },
        barcode_gradecode: { halign: "center", cellWidth: "auto" },
        barcode_countcode: { halign: "center", cellWidth: "auto" },
        barcode_markcode: { halign: "center", cellWidth: "auto" },
        barcode_packcode: { halign: "center", cellWidth: "auto" },
        currency: { halign: "center", cellWidth: "auto" },
        barcode_inventorycode: { halign: "center", cellWidth: "auto" },
        barcode_orchard: { halign: "center", cellWidth: "auto" },
        barcode_farm: { halign: "center", cellWidth: "auto" },
        barcode_phc: { halign: "center", cellWidth: "auto" },
        weight: { halign: "center", cellWidth: "auto" },
        grossWeight: { halign: "center", cellWidth: "auto" },
        loadoutdetail_barcode: { halign: "center", cellWidth: "auto" },
        stockdetail_sellingprice: { halign: "center", cellWidth: "auto" },
        total: { halign: "center", cellWidth: "auto" },
      },
      headStyles: { fontStyle: "bold", cellPadding: [0.15, 0.025, 0.15, 0.025], halign: "center" },
      bodyStyles: { fontSize: 8 },
      footStyles: { textColor: [0, 0, 0], fontStyle: "bold", halign: "center", cellPadding: [0.15, 0.025, 0.15, 0.025] },
      foot: [
        {
          barcode_commodityname: "TOTAL",
          barcode_varietyname: "",
          barcode_gradecode: "",
          barcode_countcode: "",
          barcode_markcode: "",
          barcode_packcode: "",
          barcode_inventorycode: "",
          barcode_orchard: "",
          barcode_farm: "",
          barcode_phc: "",
          stockdetail_sellingprice: "",
          weight: weightFormatter(totalWeight),
          grossWeight: weightFormatter(totalGrossWeight),
          barcode_nocartons: numeral(totalCartons).format("0,0"),
          barcode_palletsize: numeral(totalPallets).format("0,0.00"),
          loadoutdetail_barcode: "",
          total: isProforma && total == 0 ? "Undeclared" : numeral(total).format("0,0.00"),
        },
      ],
      didDrawPage: (data) => {
        fromTop = data.cursor.y;

        // border around data
        pdf.setLineWidth(0.01);
        pdf.setDrawColor(0, 0, 0);
        pdf.rect(1, startRect, fullWidth - 2, fromTop - startRect, "S");
      },
      willDrawCell: (data) => {
        data.row.height = 0.5;

        // underline column name
        if (data.section === "head") {
          const { x, y, raw, width, height } = data.cell;

          pdf.setLineWidth(0.01);
          pdf.setDrawColor(0, 0, 0);
          pdf.line(
            x + (width / 2 - pdf.getTextDimensions(raw).w / 2),
            y + (height - 0.15),
            x + (width / 2 - pdf.getTextDimensions(raw).w / 2) + pdf.getTextDimensions(raw).w,
            y + (height - 0.15),
            "S",
          );
        }
      },
    });
  }
  // Line above total line
  pdf.setFillColor("#000");
  pdf.setLineWidth(0.001);
  pdf.rect(1, fromTop - 0.45, fullWidth - 2, 0.001, "S");
};

const headerColumn1 = () => {
  return 5;
};
const headerColumn2 = () => {
  return fullWidth / 2 - 1.5;
};
const headerColumn3 = () => {
  return fullWidth - 5;
};

const printLine = (left: number, top: number, label: string, value: string, boldValue = false) => {
  setFontBold();
  pdf.text(label, left, top, "right");
  if (!boldValue) {
    setFontNormal();
  }
  pdf.text(value, left + 0.2, top, "left");
};

const printHeader = (companyDetails, reportType, isProforma: boolean) => {
  fromTop = 1;
  fromLeft = 1;

  const startRect = 0.5;
  const imageWidth = 5;
  const imageHeight = 1.5;

  addImage(logoImage, "PNG", (fullWidth - imageWidth) / 2 - 1, fromTop, imageWidth, imageHeight);

  setFontBold();
  pdf.setFontSize(12);
  pdf.text(
    `${isProforma ? "PACKING LIST" : reportType.toString().replace("CLIENT_AND_CONSIGNEE", "").replaceAll("_", " ").replace("PDF", "").replace("CONSIGNEE", "")}`,
    fullWidth / 2 - 1,
    fromTop + 2.5,
    "center",
  );

  setFontNormal();
  pdf.setFontSize(8);

  pdf.text(get("name", companyDetails, ""), fromLeft, fromTop, "left");
  newLine(0.4);

  pdf.text(get("address1", companyDetails, ""), fromLeft, fromTop, "left");
  newLine(0.4);

  pdf.text(get("address2", companyDetails, ""), fromLeft, fromTop, "left");
  newLine(0.4);

  pdf.text(get("address3", companyDetails, ""), fromLeft, fromTop, "left");
  newLine(0.4);

  pdf.text(get("address4", companyDetails, ""), fromLeft, fromTop, "left");
  newLine(0.4);

  pdf.text(get("address5", companyDetails, ""), fromLeft, fromTop, "left");

  fromLeft = headerColumn3() - 1;
  fromTop = 1;

  pdf.text("Tel No:", fromLeft, fromTop, "right");
  pdf.text(get("telephoneNumber", companyDetails, ""), fromLeft + 0.2, fromTop, "left");

  newLine(0.4);
  pdf.text("Fax No:", fromLeft, fromTop, "right");
  pdf.text(get("faxNumber", companyDetails, ""), fromLeft + 0.2, fromTop, "left");

  newLine(0.4);
  pdf.text("E-mail:", fromLeft, fromTop, "right");
  pdf.text(get("email", companyDetails, ""), fromLeft + 0.2, fromTop, "left");

  newLine(0.4);
  pdf.text("Vat No:", fromLeft, fromTop, "right");
  pdf.text(get("vatno", companyDetails, ""), fromLeft + 0.2, fromTop, "left");

  newLine(0.4);
  pdf.text("Reg No:", fromLeft, fromTop, "right");
  pdf.text(get("regno", companyDetails, ""), fromLeft + 0.2, fromTop, "left");

  newLine(0.4);
  pdf.text("Customs Code:", fromLeft, fromTop, "right");
  pdf.text(get("customscode", companyDetails, ""), fromLeft + 0.2, fromTop, "left");

  newLine(0.4);
  pdf.text("GGN / GLN No:", fromLeft, fromTop, "right");
  pdf.text(get("ggn_gln_no", companyDetails, ""), fromLeft + 0.2, fromTop, "left");

  newLine(0.4);

  pdf.setLineWidth(0.02);
  pdf.rect(0.75, startRect, fullWidth - 1.5, fromTop - startRect, "S");
};

const printDetailSummary = (header, is_dispatch: boolean, isProforma: boolean) => {
  const isConsigneeReport = reportType.includes("CONSIGNEE") && !reportType.includes("CLIENT");
  const isClientAndConsigneeReport = reportType.includes("CLIENT_AND_CONSIGNEE");

  const clientAddress = isConsigneeReport ? get("consignee_address", header, "").replace(/[\r\n]/g, " ") : get("clientaddress", header, "").replace(/[\r\n]/g, " ");
  const clientAddressArr = addressLineArray(clientAddress);

  const shippedToAddress = get("consignee_address", header, "").replace(/[\r\n]/g, " ");
  const shippedToAddressArr = addressLineArray(shippedToAddress);

  const shippedTo = get("consignee_name", header, "");
  const issuedTo = isConsigneeReport ? get("consignee_name", header, "") : get("clientname", header, "");

  const startRect = fromTop;

  newLine(0.75);

  pdf.setFontSize(11);
  setFontBold();
  pdf.text("SHIPMENT DETAILS:", 1.25, fromTop, "left");
  pdf.line(1.25, fromTop + 0.05, 5.25, fromTop + 0.05, "S");

  pdf.setFontSize(8);
  //1
  newLine();
  printLine(headerColumn1(), fromTop, "DATE:", moment(is_dispatch ? get("dispatch_invoicedate", header, "") : get("sale_date", header, "")).format("DD/MM/YYYY"));
  if (!isProforma) {
    printLine(headerColumn2(), fromTop, "UCR:", get("ucrno", header, ""));
  }
  printLine(headerColumn3() - 1, fromTop, "AGENT REF:", get("forwardagentref", header, ""));

  //2
  newLine(0.4);
  printLine(headerColumn1(), fromTop, "LOAD REF:", get("loadout_reference", header, ""));
  if (!isProforma) {
    printLine(headerColumn2(), fromTop, "TEMP RECORDER:", get("temptail", header, ""));
  }
  printLine(headerColumn3() - 1, fromTop, "VESSEL:", get("vessel_description", header, ""));

  //3
  newLine(0.4);
  printLine(headerColumn1(), fromTop, "INVOICE NO:", `${isProforma ? moment(get("sale_date", header, "")).format("DD/MM/YYYY") : ""}${get("invoicenumber", header, "")}`);

  if (!isProforma) {
    printLine(headerColumn2(), fromTop, "POSITION:", get("recorder_position", header, ""));
  }

  printLine(headerColumn3() - 1, fromTop, "SHIPPING REF:", get("shippingRef", header, ""));

  let issuedToAddressLineTop = fromTop + 0.8;
  let shippedToAddressLineTop = fromTop + 0.8;

  const maxNameLength = isClientAndConsigneeReport ? 35 : 80;
  const maxAddressLines = 8;
  const issuedToLength = issuedTo.toString().trim().length;
  const issuedToAddressLinesLength = issuedToLength > maxNameLength ? Math.round(issuedToLength / maxNameLength) : 1;

  const issuedToArr = addressLineArray(issuedTo, maxNameLength, undefined, true).map((item, i) => ({ bold: true, label: i > 0 ? "" : "ISSUED TO:", value: item }));
  const shippedToArr = addressLineArray(shippedTo, maxNameLength, undefined, true).map((item, i) => ({ bold: true, label: i > 0 ? "" : "SHIPPED TO:", value: item }));

  const issueToAddress = (clientAddressArr || []).map((str) => ({ bold: false, label: "", value: str }));
  const middleContentAddress = (shippedToAddressArr || []).map((str) => ({ bold: false, label: "", value: str }));

  const totalAddressLinesAssigned = issueToAddress.length + issuedToAddressLinesLength + 2; // The hardcoded 2 is for Tel no & email address lines
  const addressLinesOverlap = totalAddressLinesAssigned > maxAddressLines ? totalAddressLinesAssigned - maxAddressLines : 0;

  /* ISSUED TO */
  const issuedToExtraCnt = isClientAndConsigneeReport ? addressLineArray(shippedTo, maxNameLength).length - addressLineArray(issuedTo, maxNameLength).length : 0;
  const issuedToExtraArr = issuedToExtraCnt > 0 ? new Array(issuedToExtraCnt).fill(1).map(() => ({ bold: false, label: "", value: "" })) : [];

  const issuedToExtraAddCnt = isClientAndConsigneeReport ? middleContentAddress.length - issueToAddress.length : 0;
  const issuedToExtraAddArr =
    issuedToExtraAddCnt > 0
      ? new Array(issuedToExtraAddCnt).fill(1).map(() => ({ bold: false, label: "", value: "" }))
      : new Array(maxAddressLines - totalAddressLinesAssigned + addressLinesOverlap).fill({ bold: false, label: "", value: "" });

  issuedToArr.push(
    ...issuedToExtraArr,
    ...issueToAddress,
    ...issuedToExtraAddArr,
    { bold: false, label: "TEL NO:", value: get("clientstelephone", header, "") },
    { bold: false, label: "E-MAIL ADDRESS:", value: get("clientsemail", header, "") },
  );

  for (let i = 0; i < issuedToArr.length; i++) {
    const row = issuedToArr[i];
    printLine(headerColumn1() - 0.05, issuedToAddressLineTop, row.label, row.value, row.bold);
    issuedToAddressLineTop += 0.4;
  }

  /* SHIPPED TO */
  if (isClientAndConsigneeReport) {
    const shippedToExtraCnt = addressLineArray(issuedTo, maxNameLength).length - addressLineArray(shippedTo, maxNameLength).length;
    const shippedToExtraArr = shippedToExtraCnt > 0 ? new Array(shippedToExtraCnt).fill(1).map(() => ({ bold: false, label: "", value: "" })) : [];

    const shippedToExtraAddCnt = issueToAddress.length - middleContentAddress.length;
    const shippedToExtraAddArr =
      shippedToExtraAddCnt > 0
        ? new Array(shippedToExtraAddCnt).fill(1).map(() => ({ bold: false, label: "", value: "" }))
        : new Array(maxAddressLines - totalAddressLinesAssigned).fill({ bold: false, label: "", value: "" });

    shippedToArr.push(
      ...shippedToExtraArr,
      ...middleContentAddress,
      ...shippedToExtraAddArr,
      { bold: false, label: "TEL NO:", value: get("consignee_telephone", header, "") },
      { bold: false, label: "E-MAIL ADDRESS:", value: get("consignee_email", header, "") },
    );

    for (let i = 0; i < shippedToArr.length; i++) {
      const row = shippedToArr[i];
      printLine(headerColumn2() - 0.05, shippedToAddressLineTop, row.label, row.value, row.bold);
      shippedToAddressLineTop += 0.4;
    }
  }

  // 4
  newLine(0.4);
  printLine(headerColumn1(), fromTop, "CLIENT ORDER NUMBER:", get("orderno", header, ""));
  printLine(headerColumn3() - 1, fromTop, "CONTAINER NUMBER:", get("containerno", header, ""));

  // 5
  newLine(0.4);
  printLine(headerColumn3() - 1, fromTop, "SEAL NO:", get("dispatch_sealnumber", header, ""));

  // 6
  newLine(0.4);
  printLine(headerColumn3() - 1, fromTop, "PORT OF LOADING:", get("portloading", header, "").toUpperCase());

  // 7
  newLine(0.4);
  printLine(headerColumn3() - 1, fromTop, "PORT OF DISCHARGE:", get("portdischarge", header, "").toUpperCase());

  // 8
  newLine(0.4);
  printLine(headerColumn3() - 1, fromTop, "FINAL DESTINATION:", get("portfinal", header, "").toUpperCase());

  // 9
  newLine(0.4);
  printLine(headerColumn3() - 1, fromTop, "ETD:", moment(get("etd", header, "")).format("DD/MM/YYYY"));

  // 10
  newLine(0.4);
  printLine(headerColumn3() - 1, fromTop, "ETA:", moment(get("eta", header, "")).format("DD/MM/YYYY"));

  // 11
  newLine(0.4);
  const nettWeightFormatted = weightFormatter(header.nettweight || "");
  printLine(headerColumn3() - 1, fromTop, "NET CARGO WEIGHT:", `${nettWeightFormatted} KG`);

  // 12
  newLine(0.4);
  const grossWeightFormatted = weightFormatter(header.grossweight || "");
  printLine(headerColumn3() - 1, fromTop, "GROSS CARGO WEIGHT:", `${grossWeightFormatted} KG`);

  fromLeft = 1;
  fromTop =
    issuedToAddressLineTop > shippedToAddressLineTop
      ? issuedToAddressLineTop > fromTop
        ? issuedToAddressLineTop - 0.2
        : fromTop
      : shippedToAddressLineTop > fromTop
      ? shippedToAddressLineTop - 0.2
      : fromTop;

  // newLine();

  pdf.setLineWidth(0.01);
  pdf.rect(fromLeft, startRect, fullWidth - 2, fromTop - startRect, "S");
};
