import React, { useState, useEffect, FC } from "react";
import { withStyles, createStyles, WithStyles, Theme } from "@material-ui/core/styles";

import Collapse from "@material-ui/core/Collapse";
import IconListItem from "@material-ui/core/ListItemIcon";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Tooltip from "@material-ui/core/Tooltip";

import IconExpandMore from "@material-ui/icons/ExpandMore";
import IconExpandLess from "@material-ui/icons/ExpandLess";

import { history } from "../lib/history";
import * as str from "../lib/helpers/string_methods";
// import { isNullOrUndef } from "../lib/helpers/isNullOrUndef";

import { MaintenanceList, NavigationList, SubNavigationList } from "./MaintenanceContent";

import { WSSContext } from "../lib/context/WSSContext";

import Badge from "@material-ui/core/Badge";
import { getEdiFilesImported } from "../lib/api/FileImport";
import Typography from "@material-ui/core/Typography";

const styles = (theme: Theme) =>
  createStyles({
    root: {},
    icon: {
      marginLeft: `${theme.spacing(1)}px`,
    },
    iconBadge: {
      marginLeft: `${theme.spacing(1) / 2}px`,
    },
  });

type listItemsPropsType = {
  handleDrawerOpen: any;
  handleDrawerClose: any;
  drawerState: boolean;
  isAdmin: boolean;
  filterValue: string;
} & WithStyles<typeof styles>;

export const MainListItemsUnstyled: React.SFC<listItemsPropsType> = (props) => {
  const { classes, handleDrawerOpen, handleDrawerClose, drawerState = false, isAdmin, filterValue } = props;

  const [open, setOpen] = useState(false); // maintenance
  const [activeNavItem, setActiveNavItem] = useState<string>(""); // root

  const [navList, setNavList] = useState([]);
  const [maintList, setMaintList] = useState([]);

  const [failedEdiImportsCount, setFailedEdiImportsCount] = useState(0);

  // const [badgeNum, setBadgeNum] = useState(0);

  const { wss } = React.useContext(WSSContext);

  useEffect(() => {
    applyFilters();

    setOpen(filterValue && filterValue.length > 0);

    // setBadgeNum(wss.notificationCount);
  }, [filterValue, wss.message, wss.notificationCount]);

  const applyFilters = () => {
    const filteredNavList = NavigationList.filter((navItem) => {
      if (navItem.name.toLowerCase() === "maintenance") {
        if (MaintenanceList.find((mItem) => mItem.name.toLowerCase().includes(filterValue) || mItem.section.toLowerCase().includes(filterValue))) {
          return navItem;
        } else if (navItem.name.toLowerCase().includes(filterValue)) {
          return navItem;
        }
      }

      const subNavItem = SubNavigationList.find((subNav) => subNav.name.toLowerCase().includes(filterValue) || subNav.section.toLowerCase().includes(filterValue));

      if (subNavItem && subNavItem.section.toLowerCase() === navItem.name.toLowerCase()) {
        return navItem;
      }

      return navItem.name.toLocaleLowerCase().includes(filterValue);
    });

    const filteredMaintList = MaintenanceList.filter((navItem) => {
      if (navItem.name.toLowerCase().includes(filterValue) || navItem.section.toLowerCase().includes(filterValue)) {
        return navItem;
      }
      return undefined;
    });

    setNavList([...filteredNavList]);
    setMaintList([...filteredMaintList]);
  };

  const handleClick = () => {
    setActiveNavItem("");
    if (drawerState) {
      setOpen(!open);
    } else {
      setOpen(true);
    }
  };

  const handleClickNavItem = (section: string) => {
    if (!section.includes("maint")) {
      // check if not maintenance tab then close maintenance
      setOpen(false);
    }
    if (activeNavItem == section && drawerState) {
      setActiveNavItem("");
    } else {
      setActiveNavItem(section);
    }
  };

  const toggleOpen = (section: string) => {
    if (filterValue && filterValue.length > 0) {
      return true;
    }
    if (activeNavItem == section) {
      return true;
    }
    return false;
  };

  const handleGetEdiFilesImported = async () => {
    const response = await getEdiFilesImported();
    setFailedEdiImportsCount((response.data || []).length);
  };

  useEffect(() => {
    handleGetEdiFilesImported();
  }, []);

  useEffect(() => {
    if (!drawerState) {
      setActiveNavItem("");
    }
  }, [drawerState]);

  return (
    <div className={classes.root}>
      {navList.map((navItem, index) => {
        if (navItem.name !== "Maintenance" && navItem.path === "") {
          return (
            <NavItem
              index={index}
              navItem={navItem}
              toggleOpen={toggleOpen}
              drawerState={drawerState}
              handleDrawerOpen={handleDrawerOpen}
              handleDrawerClose={handleDrawerClose}
              handleClickNavItem={handleClickNavItem}
              name={navItem.name.toString().toLowerCase()}
              extra={navItem.name === "EDI" ? failedEdiImportsCount : null}
            />
          );
        } else if (navItem.name === "Maintenance") {
          const openClient = toggleOpen("maintClient");
          const openCompanyDetails = toggleOpen("maintCompanyDetails");
          const openMaintCreditors = toggleOpen("maintCreditors");
          const openEstimatedCost = toggleOpen("maintEstimatedCost");
          const openSpec = toggleOpen("maintFruitSpec");
          const openMaster = toggleOpen("maintMasterListing");
          const openProducer = toggleOpen("maintProducers");
          const openQC = toggleOpen("maintQualityControl");
          const openVault = toggleOpen("maintVault");
          const openRoot = toggleOpen("maintRoot");

          return (
            <div key={`layout${index}`} style={{ backgroundColor: open ? "rgba(0, 0, 0, 0.04)" : "rgb(255, 255, 255)" }}>
              {navigationItem(navItem, handleDrawerClose, handleDrawerOpen, open && drawerState, handleClick)}
              <Collapse in={open && drawerState ? true : false} key={index} timeout="auto" unmountOnExit>
                {sectionListing("client", "Clients", () => handleClickNavItem("maintClient"), handleDrawerOpen, openClient, drawerState, maintList)}
                {sectionListing(
                  "company details",
                  "Company Details",
                  () => handleClickNavItem("maintCompanyDetails"),
                  handleDrawerOpen,
                  openCompanyDetails,
                  drawerState,
                  maintList,
                )}
                {sectionListing("creditors", "Creditors", () => handleClickNavItem("maintCreditors"), handleDrawerOpen, openMaintCreditors, drawerState, maintList)}
                {sectionListing("estimated cost", "Estimated Cost", () => handleClickNavItem("maintEstimatedCost"), handleDrawerOpen, openEstimatedCost, drawerState, maintList)}
                {sectionListing("fruit spec", "Fruit Spec", () => handleClickNavItem("maintFruitSpec"), handleDrawerOpen, openSpec, drawerState, maintList)}
                {sectionListing("master listing", "Master Listing", () => handleClickNavItem("maintMasterListing"), handleDrawerOpen, openMaster, drawerState, maintList)}
                {sectionListing("producer", "Producers", () => handleClickNavItem("maintProducers"), handleDrawerOpen, openProducer, drawerState, maintList)}
                {sectionListing("quality control", "Quality Control", () => handleClickNavItem("maintQualityControl"), handleDrawerOpen, openQC, drawerState, maintList)}
                {sectionListing("vault", "Vault", () => handleClickNavItem("maintVault"), handleDrawerOpen, openVault, drawerState, maintList)}
                {sectionListing("root", undefined, () => handleClickNavItem("maintRoot"), handleDrawerOpen, openRoot, drawerState, maintList)}
              </Collapse>
            </div>
          );
        } else {
          if (navItem.name === "User Management" && !isAdmin) {
            return undefined;
          }
          return navigationItem(navItem, handleDrawerClose, handleDrawerOpen, undefined, undefined);
        }
      })}
    </div>
  );
};

type NavItem = { name: string; path: string; icon: any; closeDrawer: boolean; forceOpen: boolean };

type NavItemProps = {
  extra?: any;
  name: string;
  index: number;
  navItem: NavItem;
  drawerState: boolean;
  handleDrawerOpen(): void;
  handleDrawerClose(): void;
  toggleOpen(name: string): boolean;
  handleClickNavItem(name: string): void;
};

const NavItem: FC<NavItemProps> = ({ index, name, navItem, drawerState, handleDrawerOpen, handleClickNavItem, toggleOpen, handleDrawerClose, extra }) => {
  const openNav = toggleOpen(name);
  return (
    <div key={`layout${index}`} style={{ backgroundColor: openNav ? "rgba(0, 0, 0, 0.04)" : "rgb(255, 255, 255)" }}>
      {navigationItem(navItem, handleDrawerClose, handleDrawerOpen, openNav && drawerState, () => handleClickNavItem(name), extra)}
      <Collapse in={openNav && drawerState ? true : false} key={index} timeout="auto" unmountOnExit>
        {subNavigationItem(SubNavigationList, name, handleDrawerClose)}
      </Collapse>
    </div>
  );
};

export const MainListItems = withStyles(styles)(MainListItemsUnstyled);

const onClick = (nav) => {
  history.push(nav);
};

const MAINTENANCE = "/maintenance";
const getPath = (item: string) => {
  return `${MAINTENANCE}/${item}`;
};

// const checkFiltered = (filter: string, el: string) => {
//   if (isNullOrUndef(filter)) {
//     return false;
//   } else {
//     const result = MaintenanceList.filter((maintItem) => {
//       if (maintItem.name.toLowerCase().includes(filter)) {
//         return maintItem;
//       }
//     });

//     if (result && result.length > 0) {
//       if (el == "edi") {
//         const ediResult = result.find((filterItem) => filterItem.section == "edi");
//         if (ediResult) {
//           return true;
//         }
//       } else {
//         return true;
//       }
//     } else {
//       return false;
//     }
//   }
// };

type navItemType = {
  name: string;
  path: string;
  icon: any;
  closeDrawer: boolean;
  forceOpen: boolean;
};

const navigationItem = (item: navItemType, drawerClose, drawerOpen, collapse, sectionClick, badgeCount?) => {
  return (
    <div key={`divnavitem${item.name}`}>
      <Tooltip title={item.name} placement="right">
        <ListItem
          key={`litem${item.name}`}
          button
          onClick={() => {
            if (item.closeDrawer) {
              drawerClose();
            } else if (item.forceOpen) {
              drawerOpen();
            }
            if (sectionClick) {
              sectionClick();
            } else {
              onClick(`/${item.path}`);
            }
          }}
        >
          <IconListItem>
            <Badge badgeContent={badgeCount} color="secondary">
              <item.icon />
            </Badge>
          </IconListItem>
          <ListItemText primaryTypographyProps={{ style: { fontWeight: sectionClick ? "bold" : "normal" } }} primary={item.name} />
          {sectionClick ? collapse ? <IconExpandLess /> : <IconExpandMore /> : <></>}
        </ListItem>
      </Tooltip>
    </div>
  );
};

const subNavigationItem = (SubNavigationList, section, drawerClose) => {
  return (
    <List style={{ paddingTop: 0, paddingBottom: 0 }}>
      {SubNavigationList.map((item) => {
        if (item.section === section) {
          return (
            <ListItem
              button
              onClick={() => {
                if (item.closeDrawer) {
                  drawerClose();
                }
                onClick(`/${item.path}`);
              }}
              key={item.name}
            >
              <IconListItem> {/* <IconStarBorder /> */}</IconListItem>
              <ListItemText disableTypography primary={SubListItem(str.PrettyTitle(item.name))} />
            </ListItem>
          );
        }
        return undefined;
      })}
    </List>
  );
};

const sectionListing = (section, title, handleClick, handleDrawerOpen, open, drawerState, MaintenanceList) => {
  const filtered = MaintenanceList.find((maintItem) => maintItem.section === section);

  if (filtered) {
    if (title) {
      return (
        <List style={{ backgroundColor: open ? "rgba(0, 0, 0, 0.05)" : "rgba(0, 0, 0, 0)", padding: 0 }}>
          <ListItem
            key={title}
            button
            onClick={() => {
              handleClick();
              handleDrawerOpen();
            }}
          >
            <IconListItem> {/* <IconMaintenance /> */}</IconListItem>
            <ListItemText disableTypography primary={SubListItem(title, true)} />
            {open ? <IconExpandLess /> : <IconExpandMore />}
          </ListItem>
          <Collapse unmountOnExit timeout="auto" in={open && drawerState ? true : false} style={{ backgroundColor: "rgba(0, 0, 0, 0)" }}>
            {sectionListItems(MaintenanceList, section, false)}
          </Collapse>
        </List>
      );
    } else {
      return sectionListItems(MaintenanceList, section, true);
    }
  } else {
    return undefined;
  }
};

const sectionListItems = (MaintenanceList, section, isRoot) => {
  return (
    <List style={{ paddingTop: 0, paddingBottom: 0 }}>
      {MaintenanceList.sort((a, b) => a.name.localeCompare(b.name)).map((item) => {
        if (item.section === section) {
          return (
            <ListItem
              button
              onClick={() => {
                onClick(`${getPath(item.path)}`);
              }}
              key={item.path}
              style={{ paddingLeft: !isRoot ? "30px" : "16px" }}
            >
              <IconListItem> {/* <IconStarBorder /> */}</IconListItem>
              <ListItemText disableTypography primary={SubListItem(str.PrettyTitle(item.name))} />
            </ListItem>
          );
        }
        return undefined;
      })}
    </List>
  );
};

const SubListItem = (title: string, bold: boolean = false) => (
  <Typography>
    <li style={{ listStyle: "disc", fontWeight: bold ? "bold" : "normal" }}>{title}</li>
  </Typography>
);
