import React from "react";
import { withStyles, createStyles, WithStyles, Theme } from "@material-ui/core/styles";

import Tooltip from "@material-ui/core/Tooltip";
import Button from "@material-ui/core/Button";

import Close from "@material-ui/icons/Close";
import IconAdd from "@material-ui/icons/Add";

import Grid from "../../lib/components/grid";
import Confirmation from "../../lib/components/confirmation";

import { commodities } from "../../lib/api/commodity";
import { GridColumns } from "./commoditiesgridsetup";

import CommoditiesEditForm from "./commoditieseditform";
import { SnackContext } from "../../lib/context/SnackContext";
import { GenerateErrorMessage } from "../../lib/helpers/string_methods";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(1),
      width: "100%",
      height: "100%",
      position: "relative",
    },
    inline: {
      display: "inline",
      position: "absolute",
      marginTop: "7px",
      marginLeft: "7px",
    },
    closeIcon: {
      display: "flex",
      justifyContent: "flex-end",
      margin: "10px 0px -5px",
      position: "fixed",
      top: "10px",
      right: "15px",
    },
  });

type CommoditiesProps = { handleOnClose?: any } & WithStyles<typeof styles>;

export class CommoditiesUnstyled extends React.Component<CommoditiesProps, any> {
  state = {
    classes: undefined,
    data: [],
    confirmRemoveID: undefined,
    confirmEditID: undefined,
    loading: true,
    handleOnClose: undefined,
  };

  constructor(props) {
    super(props);
    this.state.classes = props.classes;
    this.state.handleOnClose = props.handleOnClose;
  }

  static contextType = SnackContext;
  context!: React.ContextType<typeof SnackContext>;

  componentDidMount() {
    this.loadData();
  }

  loadData = () => {
    commodities.all().then((data) => {
      const dataSorted = data.sort((a, b) => {
        if (a.code > b.code) return 1;
        if (a.code < b.code) return -1;
        return 1;
      });
      this.setState({ loading: false, data: dataSorted });
    });
  };

  handleCreate = () => {
    this.setState({ confirmEditID: "0" });
  };

  handleEdit = (id) => {
    this.setState({ confirmEditID: id });
  };

  handleEditClose = () => {
    this.setState({ confirmEditID: undefined });
  };

  handleEditConfirm = async (item) => {
    try {
      const id = item["id"];
      delete item["id"];

      if (id == "0") {
        await commodities.create({ data: { ...item } });
      } else {
        await commodities.update(id, { data: { ...item } });
      }
    } catch (error) {
      const err = GenerateErrorMessage(error, "Error processing Commodity.");
      this.context.updateSnack({ show: true, color: "red", message: err });
    }

    this.loadData();
    this.handleEditClose();
  };

  handleRemove = async (id) => {
    this.setState({ confirmRemoveID: id });
  };

  handleRemoveClose = () => {
    this.setState({ confirmRemoveID: undefined });
  };

  handleRemoveConfirm = async () => {
    await commodities.remove(this.state.confirmRemoveID).then(() => {
      this.handleRemoveClose();
      this.loadData();
    });
  };

  GridColumnsOverride = (column, row, arrangement, columnsWidth) => {
    return GridColumns(column, row, arrangement, columnsWidth, this.handleRemove, this.handleEdit);
  };

  render() {
    const { classes } = this.state;

    return (
      <div className={classes.root}>
        <div className={classes.inline}>
          {this.state.handleOnClose && (
            <div className={classes.closeIcon}>
              <Button style={{ marginRight: "10px", backgroundColor: "white" }} color="secondary" variant="outlined" onClick={this.state.handleOnClose}>
                <Close />
              </Button>
            </div>
          )}
          <Tooltip title="Create new Commodity">
            <div className={classes.inline}>
              <Button variant="contained" color="primary" onClick={this.handleCreate} style={{ marginBottom: "10px" }}>
                <IconAdd />
              </Button>
            </div>
          </Tooltip>
        </div>
        <Grid loading={this.state.loading} data={this.state.data} GridColumns={this.GridColumnsOverride} clearFilters={"commoditiesgridsetup"} />
        {this.state.confirmRemoveID && (
          <Confirmation
            isOpen={this.state.confirmRemoveID ? true : false}
            handleClose={this.handleRemoveClose}
            handleConfirm={this.handleRemoveConfirm}
            title="Remove selected Commodity?"
            body="Are you sure you want to REMOVE the selected Commodity Record?"
          ></Confirmation>
        )}
        {this.state.confirmEditID && (
          <Confirmation
            isOpen={this.state.confirmEditID ? true : false}
            handleClose={this.handleEditClose}
            handleConfirm={() => {}}
            title={this.state.confirmEditID == "0" ? "Creating Commodity" : "Editing Commodity"}
            body={undefined}
          >
            <CommoditiesEditForm onSubmit={this.handleEditConfirm} onClose={this.handleEditClose} id={this.state.confirmEditID} />
          </Confirmation>
        )}
      </div>
    );
  }
}

export default withStyles(styles)(CommoditiesUnstyled);
