import { apiHeaders, client, request } from "./_base";

export interface Pack {
  id: number;
  active: 0 | 1;
  "Commodity ID": string;
  code: string;
  "No Cartons": number;
  Description: string;
  "Size Table": string;
  "Gross Weight": number;
  "Nett Weight": number;
  "Standard Carton": number;
  Height: number;
  Width: number;
  Depth: number;
  packagingweight: number;
}

export interface PackCommodity extends Pack {
  commodity_id: number;
}

const endpoint = "pack";

export const pack = client(endpoint, (item) => ({
  ...item,
}));

export const createPack = async (item) => {
  const content = JSON.stringify(item);
  const result = await request(`${endpoint}/ext/createPack`, {
    method: "POST",
    headers: apiHeaders(content),
    body: content,
  });
  const resp = await result.json();
  if (result.status == 500) {
    throw resp;
  }
  return resp.data;
};

export const getDistinctPackCodes = async () => {
  const result = await request(`${endpoint}/ext/getDistinctPackCodes`, {
    headers: apiHeaders(),
  });
  const resp = await result.json();
  if (result.status == 500) {
    throw resp;
  }
  return resp.data;
};

export const getPacksByCommodityCode = async (commodityCode: string) => {
  const result = await request(`${endpoint}/ext/packsByCommodityCode/${commodityCode}`, {
    headers: apiHeaders(),
  });
  const resp = await result.json();
  if (result.status == 500) {
    throw resp;
  }
  return resp.data;
};

export const getPacksByCommodityCodeReadyForCombo = async (commodityCode: string) => {
  const packs = await getPacksByCommodityCode(commodityCode);
  return (packs || []).map((item) => ({ id: item.id, value: item.id, display: item.code }));
};

type PalletSpecPack = {
  id: number;
  code: string;
  carton: string;
  name: string;
};

export const getPalletSpecPackByVariety = async (varietyId: number): Promise<PalletSpecPack[]> => {
  const result = await request(`${endpoint}/ext/getPalletSpecPackByVariety/${varietyId}`, {
    headers: apiHeaders(),
  });
  const resp = await result.json();
  if (result.status == 500) {
    throw resp;
  }
  return resp.data;
};

export const getPackWithCommodity = async (): Promise<PackCommodity[]> => {
  const result = await request(`${endpoint}/ext/getPackWithCommodity`, {
    headers: apiHeaders(),
  });
  const resp = await result.json();
  if (result.status == 500) {
    throw resp;
  }
  return resp.data;
};

export const getPacksByCommodityCodeFull = async (commodityCode: string): Promise<Pack[]> => {
  const result = await request(`${endpoint}/ext/getPacksByCommodityCodeFull/${commodityCode}`, {
    headers: apiHeaders(),
  });
  const resp = await result.json();
  if (result.status == 500) {
    throw resp;
  }
  return resp.data;
};

export const getPacksByCommodityCodeFullCombo = async (commodityCode) => {
  return (await getPacksByCommodityCodeFull(commodityCode))
    .sort((a, b) => a["No Cartons"] - b["No Cartons"])
    .map((pack) => ({ id: pack.id, value: pack.id, display: `${pack["No Cartons"]}-${pack.code}` }));
};
