import React from "react";
import { withStyles, createStyles, WithStyles, Theme } from "@material-ui/core/styles";

import Grid from "../../lib/components/grid";
import Confirmation from "../../lib/components/confirmation";
import { counts, countFull } from "../../lib/api/counts";

import Tooltip from "@material-ui/core/Tooltip";
import Button from "@material-ui/core/Button";

import IconAdd from "@material-ui/icons/Add";

import { GridColumns } from "./countsgridsetup";
import CountsEditForm from "./countseditform";
import { SnackContext } from "../../lib/context/SnackContext";
import { GenerateErrorMessage } from "../../lib/helpers/string_methods";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(1),
      width: "100%",
      height: "100%",
      position: "relative",
    },
    inline: {
      display: "inline",
      position: "absolute",
      marginTop: "7px",
      marginLeft: "7px",
    },
  });

type CountsProps = {} & WithStyles<typeof styles>;

export class CountsUnstyled extends React.Component<CountsProps, any> {
  state = {
    classes: undefined,
    data: [],
    confirmRemoveID: undefined,
    confirmEditID: undefined,
    palletspecs: undefined,
    loading: true,
  };

  constructor(props) {
    super(props);
    this.state.classes = props.classes;
    this.state.palletspecs = props.palletspecs;
  }

  static contextType = SnackContext;
  context!: React.ContextType<typeof SnackContext>;

  componentDidMount() {
    this.loadData();
  }

  loadData = async () => {
    const data = await countFull();
    const dataSorted = data.data.sort((a, b) => a.count.localeCompare(b.count));
    this.setState({ loading: false, data: dataSorted });
  };

  handleCreate = () => {
    this.setState({ confirmEditID: "0" });
  };

  handleEdit = (id) => {
    this.setState({ confirmEditID: id });
  };

  handleEditClose = () => {
    this.setState({ confirmEditID: undefined });
  };

  handleEditConfirm = async (item) => {
    try {
      const id = item["id"];
      const data = {
        active: item.active,
        code: item.count,
        pack_id: item.pack_id,
        commodity_id: item.commodity_id,
      };

      if (!id || id == "0") {
        await counts.create({ data });
      } else {
        await counts.update(id, { data });
      }

      this.loadData();
      this.handleEditClose();
    } catch (error) {
      const err = GenerateErrorMessage(error, "Error creating/updating Count");
      this.context.updateSnack({ show: true, color: "red", message: err });
    }
  };

  handleRemove = async (id) => {
    this.setState({ confirmRemoveID: id });
  };

  handleRemoveClose = () => {
    this.setState({ confirmRemoveID: undefined });
  };

  handleRemoveConfirm = async () => {
    await counts.remove(this.state.confirmRemoveID);
    this.handleRemoveClose();
    this.loadData();
  };

  GridColumnsOverride = (column, row, arrangement, columnsWidth) => GridColumns(column, row, arrangement, columnsWidth, this.handleRemove, this.handleEdit);

  render() {
    const { classes } = this.state;

    return (
      <div className={classes.root}>
        <div className={classes.inline}>
          <Tooltip title="Create new Count">
            <div className={classes.inline}>
              <Button variant="contained" color="primary" onClick={this.handleCreate} style={{ marginBottom: "10px" }}>
                <IconAdd />
              </Button>
            </div>
          </Tooltip>
        </div>
        <Grid loading={this.state.loading} data={this.state.data} GridColumns={this.GridColumnsOverride} clearFilters={"countsgridsetup"} />
        {this.state.confirmRemoveID && (
          <Confirmation
            isOpen={this.state.confirmRemoveID ? true : false}
            handleClose={this.handleRemoveClose}
            handleConfirm={this.handleRemoveConfirm}
            title="Remove selected Count?"
            body="Are you sure you want to REMOVE the selected Count Record?"
          ></Confirmation>
        )}
        {this.state.confirmEditID && (
          <Confirmation
            isOpen={this.state.confirmEditID ? true : false}
            handleClose={this.handleEditClose}
            handleConfirm={() => {}}
            title={this.state.confirmEditID == "0" ? "Creating Count" : "Editing Count"}
            body={undefined}
          >
            <CountsEditForm onSubmit={this.handleEditConfirm} onClose={this.handleEditClose} id={this.state.confirmEditID} />
          </Confirmation>
        )}
      </div>
    );
  }
}

export default withStyles(styles)(CountsUnstyled);
