import React from "react";

import { withStyles, createStyles, WithStyles, Theme } from "@material-ui/core/styles";

import CircularProgress from "@material-ui/core/CircularProgress";

import { estimatedcosts, estimatedcostsFull } from "../../../lib/api/estimatedcost";

import Grid from "../../../lib/components/grid";
import { GridColumns } from "./estimatedcostgridsetup";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      paddingTop: "65px",
      // width: "100%",
      // height: "100%",
    },
  });

type EstimatedCostGridProps = {
  handleCopy: any;
  handleEdit: any;
  handleRemove: any;
  data: any;
  dataLoading: any;
  loadData(): void;
} & WithStyles<typeof styles>;

class EstimatedCostGridUnstyled extends React.Component<EstimatedCostGridProps, {}> {
  state = {
    classes: undefined,
    handleCopy: undefined,
    handleEdit: undefined,
    handleRemove: undefined,
    hideCompleted: undefined,
  };

  constructor(props) {
    super(props);
    this.state.classes = props.classes;
    this.state.handleCopy = props.handleCopy;
    this.state.handleEdit = props.handleEdit;
    this.state.handleRemove = props.handleRemove;
  }

  GridColumnsOverride = (column: any, row: any, columnArrangement?: any, columnsWidth?: any) => {
    return GridColumns(column, row, columnArrangement, columnsWidth, this.state.handleCopy, this.state.handleEdit, this.state.handleRemove, this.handleChangeStatus);
  };

  handleChangeStatus = async (ec) => {
    const newStatus = ec.status == 1 ? 0 : 1;
    const updateData = {
      data: {
        status: newStatus,
      },
    };
    await estimatedcosts.update(ec.id, updateData);
    this.props.loadData();
  };

  render() {
    const { classes } = this.state;
    return (
      <div className={classes.root}>
        <div style={{ marginBottom: "-75px", width: "300px", marginLeft: "17px" }}>{this.props.dataLoading && <CircularProgress size={24} />}</div>
        <Grid
          loading={this.props.dataLoading}
          data={this.props.data}
          GridColumns={this.GridColumnsOverride}
          clearFilters={"estimatedcostgridsetup"}
          handleRefresh={() => this.props.loadData()}
        />
      </div>
    );
  }
}

export const EstimatedCostGrid = withStyles(styles, { withTheme: true })(EstimatedCostGridUnstyled);
