import { client, request } from "./_base";

export interface ShippingLine {
  active: number;
  id: number;
  code: string;
  description: string;
}

const endpoint = "shippingline";

export const shippingline = client<ShippingLine>(endpoint, (item) => ({
  ...item,
}));

export const getActiveShippingLine = async (): Promise<ShippingLine[]> => {
  const result = await request(`${endpoint}/ext/fullActive`);
  const resp = await result.json();
  if (result.status == 500) {
    throw resp;
  }
  return resp.data;
};

export const getShippingLineReadyForCombo = async () => {
  const result = await getActiveShippingLine();
  return result.sort((a, b) => a.description.localeCompare(b.description)).map((ship) => ({ value: ship.id, display: ship.description + " (" + ship.code + ")", data: ship }));
};

export const getShippingLineReadyForComboIdValue = async () => {
  const result = await getActiveShippingLine();
  return result
    .sort((a, b) => a.description.localeCompare(b.description))
    .map((ship) => ({ id: ship.id, value: ship.description + " (" + ship.code + ")", code: ship.code, data: ship }));
};
