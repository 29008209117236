import React, { FC } from "react";
import { withStyles, createStyles, WithStyles, Theme } from "@material-ui/core/styles";

import Input from "@material-ui/core/Input";

import Check from "@material-ui/icons/Check";
import Close from "@material-ui/icons/Close";

import Button from "@material-ui/core/Button";

import { inventories } from "../../lib/api/inventory";
import { Field } from "react-final-form";
import { MaterialCheckBoxComponent } from "../../lib/helpers/materialcomponents";
import Checkbox from "@material-ui/core/Checkbox";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(1),
    },
    tableRow: {
      paddingTop: theme.spacing(1),
      paddingRight: theme.spacing(1) * 2,
      marginTop: theme.spacing(1),
      textAlign: "right",
    },
    codeInput: {
      width: "100%",
      float: "left",
    },
    tableRowCombo: {
      paddingTop: theme.spacing(1),
      paddingRight: "10.9em",
      marginTop: theme.spacing(1),
      textAlign: "left",
    },
  });

type InventoryEditFormProps = {
  onSubmit: any;
  onClose: any;
  id: any;
} & WithStyles<typeof styles>;

class InventoryEditFormUnstyled extends React.Component<InventoryEditFormProps, any> {
  state = {
    classes: undefined,
    item: { id: "0", code: "", description: "", active: 1 },
    onSubmit: undefined,
    onClose: undefined,
    id: 0,
  };

  constructor(props) {
    super(props);
    this.state.classes = props.classes;
    this.state.onSubmit = props.onSubmit;
    this.state.onClose = props.onClose;
    this.state.id = props.id;
  }

  componentDidMount() {
    if (this.state.id != 0) {
      this.getItem(this.state.id);
    }
  }

  getItem = (id) => {
    inventories.single(id).then((data) => {
      this.setState({ item: data });
    });
  };

  handleFocus = (event) => {
    event.target.select();
  };

  handleSubmit = (e) => {
    this.state.onSubmit(e, this.state.item);
  };

  render() {
    const { classes } = this.state;

    return (
      <div className={classes.root}>
        <form
          id={this.state.item.id}
          onSubmit={(e) => {
            this.handleSubmit(e);
          }}
        >
          <table style={{ marginLeft: "15px" }}>
            <tbody>
              <tr className={classes.tableRow}>
                <td className={classes.tableRow}>
                  <span>Active:</span>
                </td>
                <td className={classes.tableRowCombo}>
                  <Checkbox
                    key={"body_value_active"}
                    checked={this.state.item["active"]}
                    value={this.state.item["active"]}
                    name="active"
                    onChange={(e) => {
                      this.setState({
                        item: {
                          ...this.state.item,
                          active: e.target.checked,
                        },
                      });
                    }}
                  />
                </td>
              </tr>
              <tr className={classes.tableRow}>
                <td className={classes.tableRow}>
                  <span>Code:</span>
                </td>
                <td className={classes.tableRow}>
                  <Input
                    className={classes.codeInput}
                    onFocus={this.handleFocus}
                    key={"body_value_code"}
                    name={"code"}
                    value={this.state.item.code}
                    onChange={(e) => {
                      this.setState({
                        item: { ...this.state.item, code: e.target.value },
                      });
                    }}
                  />
                </td>
              </tr>
              <tr className={classes.tableRow}>
                <td className={classes.tableRow}>
                  <span>Description:</span>
                </td>
                <td className={classes.tableRow}>
                  <Input
                    className={classes.codeInput}
                    onFocus={this.handleFocus}
                    key={"body_value_description"}
                    name={"description"}
                    value={this.state.item.description}
                    onChange={(e) => {
                      this.setState({
                        item: { ...this.state.item, description: e.target.value },
                      });
                    }}
                  />
                </td>
              </tr>
            </tbody>
          </table>
          <div style={{ display: "flex", justifyContent: "flex-end", marginTop: "18px", marginRight: "15px" }}>
            <Button style={{ marginRight: "10px" }} color="secondary" variant="outlined" onClick={this.state.onClose}>
              <Close />
            </Button>
            <Button type="submit" color="primary" variant="outlined" onClick={this.handleSubmit}>
              <Check />
            </Button>
          </div>
        </form>
      </div>
    );
  }
}

export default withStyles(styles)(InventoryEditFormUnstyled);
