import { apiHeaders, client, request } from "./_base";

interface Commodity {
  active: number;
  id: number;
  code: string;
  name: string;
}

const endpoint = "commodity";

export const commodities = client<Commodity>(endpoint, (item) => ({
  ...item,
}));

export const getAllActiveCommodity = async (): Promise<Commodity[]> => {
  const result = await request(`${endpoint}/ext/getAllActiveCommodity`, {
    headers: apiHeaders(),
  });
  const resp = await result.json();
  if (result.status == 500) {
    throw resp;
  }
  return resp.data;
};

export const commoditiesAllSortedMappedforCombo = async (): Promise<{ value: string; display: string; id: number }[]> => {
  const data = await getAllActiveCommodity();
  return data.sort((a, b) => a.name.localeCompare(b.name)).map((c) => ({ value: c.code, display: `${c.name} (${c.code})`, id: c.id }));
};

export const commoditiesAllSortedMappedforComboById = async () => {
  const data = await commodities.all();
  return data.sort((a, b) => a.name.localeCompare(b.name)).map((c) => ({ value: c.id, display: `${c.name} (${c.code})` }));
};
